// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { IndexedType } from './indexed-type';

export function evaluate(valueOrFunc: ((params: any) => any) | string | number | symbol | boolean | undefined | IndexedType | Array<any>, params: any) {
    if (typeof valueOrFunc === 'function') {
        const func = valueOrFunc as ((params: any) => any);
        return func(params);
    } else if (typeof valueOrFunc === 'string') {
        return valueOrFunc as string;
    } else if (typeof valueOrFunc === 'boolean') {
        return valueOrFunc as boolean;
    } else if (typeof valueOrFunc === 'number') {
        return valueOrFunc as number;
    } else if (typeof valueOrFunc === 'symbol') {
        return valueOrFunc as symbol;
    } else if (Array.isArray(valueOrFunc)) {
        return valueOrFunc as Array<any>;
    } else if (typeof valueOrFunc === 'undefined' || valueOrFunc == null) {
        return null;
    } else {
        throw new Error(`value should be either a string, boolean, number, symbol, Array or a function: (${valueOrFunc})`);
    }
}
