// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Component, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { ErrorDetails } from '../_models/error-details';
import { NgxAlertService } from '../_services/index';

/* eslint-disable @angular-eslint/component-selector */
@Component({
    selector: 'ngx-alert',
    templateUrl: './ngx-alert.component.html',
    styleUrls: ['./ngx-alert.component.css']
})
export class NgxAlertComponent implements OnDestroy {

    @Input() type: string;
    @Input() message: string;
    @Input() alertVisible: boolean;
    @Input() dismissOnTimeout = 4000;
    @Input() filterByType: null | string | string[];

    public errorDetails: ErrorDetails;

    private destroy$ = new Subject();

    constructor(alertService: NgxAlertService) {
        alertService.getMessage().pipe(
            takeUntil(this.destroy$),
            filter(x => x != null && this.isAllowedType(x.type)), // skip the first null message or not allowed types
            tap(x => {
                this.type = x.type;
                this.alertVisible = true;
                if (typeof x.message === 'string') {
                    this.message = x.message;
                    this.errorDetails = null;
                    this.dismissOnTimeout = 4000;
                } else { // ErrorDetails
                    this.message = null;
                    this.errorDetails = x.message;
                    this.dismissOnTimeout = undefined;
                }
            })
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    private isAllowedType(type: string) {
        // no filtration
        return !this.filterByType
            // single value filtration
            || this.filterByType === type
            // array of possible values
            || Array.isArray(this.filterByType) && this.filterByType.some(x => x === type);
    }
}
