//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { Component, ViewChild, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, tap } from 'rxjs/operators';

import { ModalDialogComponent, ModalBehavior, ModalAction } from '../modal-dialog/modal-dialog.component';
import { SimpleUserVm } from './user-picker-vm';

@Component({
    selector: 'app-user-picker-dialog',
    templateUrl: './user-picker-dialog.component.html',
    styles: [`
        button:focus {
            outline: 0;
        }
        .list-group-item:not(.active) {
            background-color: #f0f3f5;
        }
        .max-width-85 {
            max-width: 85%;
        }
        .list-group-item.active:disabled, .list-group-item:disabled {
            opacity: 0.6;
        }
    `]
})
export class UserPickerDialogComponent implements ModalBehavior, OnChanges, OnInit, OnDestroy {
    public readonly rowHeight = 80;

    public readonly actions: ModalAction[] = [
        {
            text: 'Apply', cssClass: 'btn-primary btn-apply',
            isDisabled: () => this.disabled,
            callback: () => this.pick.emit(Array.from(this.chosenUsers))
        },
        { text: 'Close', cssClass: 'btn-secondary btn-close' }
    ];

    @ViewChild('dialog') private dialog: ModalDialogComponent;
    /***
     * The users collection
     * @type {SimpleUserVm[]}
     */
    @Input() public users: SimpleUserVm[] = [];
    /***
     * If the picker should be read only
     * @type {boolean}
     */
    @Input() public disabled = false;
    /***
     * A pre-selected collection of user ids. IF the user is not found in @see {@link users} collection, the user won't be selected.
     * @type {number[]}
     */
    @Input() public selected: number[] = [];
    /***
     * Amount of rows displayed before a scroll appears.
     * @type {number}
     */
    @Input() public rows = 5;
    /***
     * Dialog title
     * @type {string}
     */
    @Input() public title = 'User picker';

    /***
     * Emits collection of picked users.
     * @type {EventEmitter<SimpleUserVm[]>}
     */
    @Output() public pick = new EventEmitter<SimpleUserVm[]>();

    public rowContainerHeight = calcContainerHeight(this.rows, this.rowHeight);
    public filteredUsers: SimpleUserVm[] = [];

    public searchControl = new UntypedFormControl();

    private chosenUsers = new Set<SimpleUserVm>();

    private destroy$ = new Subject();

    public ngOnInit(): void {
        this.searchControl.valueChanges.pipe(
            debounceTime(300),
            tap((x: string) => this.filteredUsers = this.users.filter(y => filterCriterion(y, x))),
            takeUntil(this.destroy$),
        ).subscribe();
    }

    public ngOnDestroy(): void {
        this.destroy$.next(true);
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['rows'] && !isNaN(this.rows)) {
            this.rowContainerHeight = calcContainerHeight(this.rows, this.rowHeight);
        }

        if (changes['users']) {
            if (Array.isArray(this.users) && this.users.length) {
                this.filteredUsers = this.users;
                this.searchControl.enable({ emitEvent: false });
            } else {
                this.searchControl.disable({ emitEvent: false });
            }
        }

        // update the selection if one of those is updated
        if ((changes['selected'] || changes['users']) && Array.isArray(this.selected) && Array.isArray(this.users)) {
           this.updateChoice();
        }
    }

    public hide(): void {
        this.dialog.hide();
    }

    public show(): void {
        if (Array.isArray(this.selected) && Array.isArray(this.users)) {
            this.updateChoice();
        }

        this.dialog.show();
    }

    public onItemToggled(user: SimpleUserVm) {
        if (this.chosenUsers.has(user)) {
            this.chosenUsers.delete(user);
        } else {
            this.chosenUsers.add(user);
        }
    }

    private updateChoice() {
        this.chosenUsers.clear();

        this.selected.forEach(x => {
            const user = this.users.find(y => y.id === x);

            if (user) {
                this.chosenUsers.add(user);
            }
        });
    }
}

function calcContainerHeight(rows: number, rowHeight: number) {
    return rows * rowHeight;
}

function filterCriterion(user: SimpleUserVm, matchingStr: string) {
    if (!user || !matchingStr) {
        return true;
    }

    const regexPattern = new RegExp(matchingStr, 'i');

    return regexPattern.test(`${user.firstName} ${user.lastName}`)
        || regexPattern.test(user.email) || regexPattern.test(user.roleNames.join());
}
