// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

export class TargetScore {
    public targetId: number;
    public targetShortName: string;
    public score: number;
}

export class DrqKpiScoreTreeItem {
    public dimension: string;
    public categoryKey: string;
    public scores: TargetScore[];
    public children: DrqKpiScoreTreeItem[];
}

export class DrqKpiScore {
    public sectionId: number;
    public tree: DrqKpiScoreTreeItem;
}

export class DrqKpiScoreTrendRequest {
    public sectionId: number;
    public targetId: number;
    public categoryKeys: string[];
    public from: string;
    public to: string;
}

export class KpiScoreTrendPoint {
    public eventDate: Date;
    public score: number;
}

export class KpiScoreTrend {
    public category: string;
    public trend: KpiScoreTrendPoint[];
}

export class KpiScoreTrendCollection {
    public sectionid: number;
    public trends: KpiScoreTrend[];
    public timestamp: Date;
}