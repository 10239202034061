<app-modal-dialog #dialog="bs-modal"
                  headerText="Issue details"
                  class="manage-monitors-modal"
                  modalClass="modal-dialog"
                  [actions]="dialogActions"
                  (close)="onModelClose()"
>
  <div [class.invisible]="issues?.length" class="d-flex align-items-center justify-content-center h-75 fixed-top position-absolute">
    No issues available
  </div>

  <div [class.invisible]="!issues?.length" class="col-12 form-horizontal p-0">
    <div class="form-row">
      <label class="col-4 col-form-label text-muted">Subject type</label>
      <label class="col pl-0 form-control-plaintext"
             id="subject-type"><b>{{currentIssue?.ruleInstance?.subjectType}}</b></label>
    </div>
    <div class="form-row">
      <label class="col-4 col-form-label text-muted">Subject name</label>
      <label class="col pl-0 form-control-plaintext" id="subject-name">
        <b class="white-space-preserve">{{currentIssue?.ruleInstance?.subjectName}}</b>
      </label>
    </div>
    <div class="form-row">
      <label class="col-4 col-form-label text-muted">Rule name</label>
      <label class="col pl-0 form-control-plaintext"
             id="rule"><b>{{currentIssue?.ruleInstance?.monitor}}</b></label>
    </div>
    <div class="form-row">
      <label class="col-4 col-form-label text-muted">Status</label>
      <label class="col pl-0 form-control-plaintext font-weight-bold" id="status">{{getIndicatorStatusText(currentIssue?.status)}}</label>
    </div>
    <div class="form-row">
      <label class="col-4 col-form-label text-muted">Reason code</label>
      <label class="col pl-0 form-control-plaintext font-weight-bold"
             id="reason-code">{{currentIssue?.reasonCodeDescription}}</label>
    </div>
    <div class="form-row">
      <label class="col-4 col-form-label text-muted">Problem time</label>
      <label class="col pl-0 form-control-plaintext" id="problem-time"><b>{{currentIssue?.problemTime ? 'Yes' : 'No'}}</b></label>
    </div>
    <div class="form-row">
      <label class="col-4 col-form-label text-muted">Duration</label>
      <label class="col pl-0 form-control-plaintext" id="duration">{{currentIssue?.duration}}</label>
    </div>
    <div class="form-row">
      <label class="col-4 col-form-label text-muted">Start time</label>
      <label class="col pl-0 form-control-plaintext"
             id="start-time">{{currentIssue?.startTime | date: 'medium'}}</label>
    </div>
    <div class="form-row">
      <label class="col-4 col-form-label text-muted">End time</label>
      <label class="col pl-0 form-control-plaintext"
             id="end-time">{{currentIssue?.endTime | date: 'medium'}}</label>
    </div>
    <div class="form-row">
      <label class="col-4 col-form-label text-muted">Start time index</label>
      <label class="col pl-0 form-control-plaintext"
             id="start-time-index">{{currentIssue?.startTimeIndex | date: 'medium'}}</label>
    </div>
    <div class="form-row">
      <label class="col-4 col-form-label text-muted">End time index</label>
      <label class="col pl-0 form-control-plaintext"
             id="end-time-index">{{currentIssue?.endTimeIndex | date: 'medium'}}</label>
    </div>
    <div class="form-row">
      <label class="col-4 col-form-label text-muted">Start depth index</label>
      <label class="col pl-0 form-control-plaintext"
             id="start-depth-index">{{currentIssue?.startDepthIndex}} {{currentIssue?.startDepthIndexUom}}</label>
    </div>
    <div class="form-row">
      <label class="col-4 col-form-label text-muted">End depth index</label>
      <label class="col pl-0 form-control-plaintext"
             id="end-depth-index">{{currentIssue?.endDepthIndex}} {{currentIssue?.endDepthIndexUom}}</label>
    </div>
    <div *ngIf="isAlertLinkShown" class="form-row align-items-center" id="alert-details">
      <label class="col-4 col-form-label text-muted">Associated with</label>
      <a id="alert-link"
         title="Open alert details in a new tab"
         target="_blank"
         [routerLink]="['/alerts']"
         [queryParams]="alertQueryParams"
         queryParamsHandling="merge"
      >Alert details</a>
    </div>
    <div class="form-row d-flex align-items-center">
      <label class="col-4 col-form-label text-muted">Comment</label>
      <label class="col pl-0 col pl-0 form-control-plaintext" id="comment">{{currentIssue?.comment}}</label>
    </div>
  </div>

  <div *ngIf="issues.length > 1" class="col d-flex justify-content-center mt-3">
    <pagination
        [totalItems]="issues?.length"
        [itemsPerPage]="1"
        [boundaryLinks]="true"
        [maxSize]="5"
        (pageChanged)="onPageChanged($event)"
        [(ngModel)]="currentPage"
        previousText="&lsaquo;"
        nextText="&rsaquo;"
        firstText="1"
        [lastText]="issues?.length || 1">
    </pagination>
  </div>
</app-modal-dialog>
