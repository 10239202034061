// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { NgxAlertService, getMessageFromError } from 'ngx-shared';
import { UserService } from '@/_services';
import {
    LoadUsersAction, FetchUsersAction, FetchFailedUsersAction, FetchOkUsersAction,
    UpdateFailedUserAction, UpdateOkUserAction, UpdateUserAction, UpdateUserOnServerAction,
    CreateFailedUserAction, CreateOkUserAction, CreateUserAction, CreateUserOnServerAction,
    DeleteFailedUserAction, DeleteOkUserAction, DeleteUserAction, DeleteUserOnServerAction,
    DeleteUserFromStateAction, CreateUserInStateAction, UpdateUserInStateAction,
    DisableUserAction, DisableUserOnServerAction, DisableOkUserAction, DisableFailedUserAction, DisableUserInStateAction,
    EnableUserAction, EnableUserOnServerAction, EnableUserInStateAction, EnableOkUserAction, EnableFailedUserAction,
    FetchOkSimpleUsersAction, FetchSimpleUsersAction, FetchFailedSimpleUsersAction, LoadSimpleUsersAction,
} from './user.actions';

@Injectable()
export class UserEffects {
    
    public onServerFetchUsers$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchUsersAction),
        switchMap(() => this.userService.getAll().pipe(
            switchMap(x => [new LoadUsersAction(x), new FetchOkUsersAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch Users', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedUsersAction(error));
            })
        ))
    ));

    
    public onServerFetchSimpleUsers$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchSimpleUsersAction),
        switchMap(() => this.userService.getAllSimple().pipe(
            switchMap(x => [new LoadSimpleUsersAction(x), new FetchOkSimpleUsersAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch Simple Users', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedSimpleUsersAction(error));
            })
        ))
    ));

    
    public onUpdate$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdateUserAction),
        map(x => new UpdateUserOnServerAction(x.user))
    ));

    
    public onServerUpdate$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdateUserOnServerAction),
        switchMap(({ user }) => this.userService.update(user).pipe(
            switchMap(x => [new UpdateUserInStateAction(x), new UpdateOkUserAction()]),
            catchError(error => {
                this.logger.error('Could not update user:', error);
                this.alertService.error(getMessageFromError(error));
                return of(new UpdateFailedUserAction(error));
            })
        ))
    ));

    
    public onCreate$ = createEffect(() => this.actions$.pipe(
        ofAction(CreateUserAction),
        map(x => new CreateUserOnServerAction(x.user))
    ));

    
    public onServerCreateUser$ = createEffect(() => this.actions$.pipe(
        ofAction(CreateUserOnServerAction),
        switchMap(({ user }) => this.userService.create(user).pipe(
            switchMap(x => [new CreateUserInStateAction(x), new CreateOkUserAction()]),
            catchError(error => {
                this.logger.error('Could not Create User', error);
                this.alertService.error(getMessageFromError(error));
                return of(new CreateFailedUserAction(error));
            })
        ))
    ));

    
    public onDelete$ = createEffect(() => this.actions$.pipe(
        ofAction(DeleteUserAction),
        map(x => new DeleteUserOnServerAction(x.userId))
    ));

    
    public onServerDeleteUser$ = createEffect(() => this.actions$.pipe(
        ofAction(DeleteUserOnServerAction),
        switchMap(({ userId }) => this.userService.delete(userId).pipe(
            map(() => userId),
            switchMap(x => [new DeleteUserFromStateAction(x), new DeleteOkUserAction()]),
            catchError(error => {
                this.logger.error('Could not Delete User', error);
                this.alertService.error(getMessageFromError(error));
                return of(new DeleteFailedUserAction(error));
            })
        ))
    ));

    
    public onDisable$ = createEffect(() => this.actions$.pipe(
        ofAction(DisableUserAction),
        map(x => new DisableUserOnServerAction(x.userId))
    ));

    
    public onServerDisableUser$ = createEffect(() => this.actions$.pipe(
        ofAction(DisableUserOnServerAction),
        switchMap(({ userId }) => this.userService.disable(userId).pipe(
            switchMap(x => [new DisableUserInStateAction(x), new DisableOkUserAction()]),
            catchError(error => {
                this.logger.error('Could not Delete User', error);
                this.alertService.error(getMessageFromError(error));
                return of(new DisableFailedUserAction(error));
            })
        ))
    ));

    
    public onEnable$ = createEffect(() => this.actions$.pipe(
        ofAction(EnableUserAction),
        map(x => new EnableUserOnServerAction(x.userId))
    ));

    
    public onServerEnableUser$ = createEffect(() => this.actions$.pipe(
        ofAction(EnableUserOnServerAction),
        switchMap(({ userId }) => this.userService.enable(userId).pipe(
            switchMap(x => [new EnableUserInStateAction(x), new EnableOkUserAction()]),
            catchError(error => {
                this.logger.error('Could not Delete User', error);
                this.alertService.error(getMessageFromError(error));
                return of(new EnableFailedUserAction(error));
            })
        ))
    ));

    
    public onServerOk$ = createEffect(() => this.actions$.pipe(
        ofAction(DeleteOkUserAction, CreateOkUserAction, UpdateOkUserAction),
        tap(() => this.alertService.success('Action completed.'))
    ), { dispatch: false });

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly userService: UserService
    ) { }
}
