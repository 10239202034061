// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { OrderStatus } from './order-status';
import { OrderTargets } from './order-targets';
import { OrderSection } from './order-section';
import { OrderAsset } from './order-asset';
import { NorthReference } from './north-reference';
import { WellDatumCode } from './well-datum-code';
import { OrderCreationType } from './order-creation-type';
import { WellLocationType } from './well-location-type';

export class Order {
    public id: number;
    public name: string;
    public namePattern: string;
    public rigPicklistId?: number;
    public checkRegion?: boolean;
    public regionPicklistId?: number;
    public checkCountry?: boolean;
    public countryPicklistId?: number;
    public checkField?: boolean;
    public fieldPicklistId?: number;
    public checkOperator?: boolean;
    public operatorPicklistId?: number;
    public checkWellName?: boolean;
    public wellName?: string;
    public wellUid?: string;
    public wellUwi?: string;
    public checkWellboreName?: boolean;
    public wellboreName?: string;
    public checkApiNumber?: boolean;
    public apiNumber?: string;
    public wellboreUid?: string;
    public startDateRig?: Date;
    public status: OrderStatus;
    public comments?: string;
    public timezone?: string;
    public isTightWell?: boolean;
    public tightWellUsers: number[];
    public trajectoryName: string;
    public trajectoryNameSpecified?: boolean;
    public northReference?: NorthReference;
    public northReferenceSpecified?: boolean;
    public checkMudlineStation: boolean;
    public checkTieInPoint: boolean;
    public standLength: number;
    public standLengthUom: string;
    public checkWellDatum?: boolean;
    public wellDatumCode?: WellDatumCode;
    public wellDatum?: number;
    public wellDatumUoM?: string;
    public checkWellLocationType?: boolean;
    public wellLocationType: WellLocationType;
    public checkWellLocation?: boolean;
    public wellLocationX?: number;
    public wellLocationY?: number;
    public checkWaterDepth?: boolean;
    public waterDepth?: number;
    public waterDepthUoM?: string;
    public dapDocumentId?: number;
    public dapComment?: string;
    public isTemplate?: boolean;
    public customName?: string;
    public creationType?: OrderCreationType;
    public creationDate?: Date;
    public createdById?: number;
    public createdBy?: string;
    public copiedFrom?: string;
    public curvesCopiedWithMergeConflicts?: boolean;
    /**
     * Flag whether the order configured with all required fields and their value are valid.
     * Once order is ready changes should not invalidate the order anymore
     */
    public isReady?: boolean;
    public targets?: OrderTargets;
    public assets?: OrderAsset[];
    public sections?: OrderSection[];

    /**
     * Last updated date time. Keep this in string format because the exact same value must be returned to the back end
     * If the value is event slightly changed due to parsing error you may receive error during save.
     */
    public lastUpdated: string;
}
