//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { Subject, Observable } from 'rxjs';
import { GridApi } from 'ag-grid-community';

import { PaginationResult } from '@/_models';

// SerializedDateFilter
// SerializedTextFilter
// SerializedNumberFilter
// SerializedDropdownFilter
export interface GetRowsParams {
    // amount of rows to skip
    skip: number;
    // amount of rows to get
    top: number;
    // the sorting of the collection i.e. "col1 asc,col2 asc,col3 desc'
    orderBy?: string;
    // the filtering of the collection
    filter?: string;
}

export interface ServerPaginationParams {
    /** Callback which the grid will call when the paging is changed */
    getRows: (params: GetRowsParams) => void;
    /** Observable which emits the data queried by {@link getRows} */
    data$: Observable<PaginationResult<any>>;
}

export interface PaginationGridParams {
    /** How the pagination is expected to get data */
    paginationType?: PaginationType;
    /** reference to the grid's api */
    gridApi?: GridApi;
    /** reference to a grid's observable emitting when the columns may need to be resized */
    columnResize$?: Subject<boolean>;
    /** should the auto resize logic be triggered */
    autoSize?: boolean;
}

export enum PaginationType {
    Client,
    Server
}
