//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

/***
 * Rounds to the most X digit, if necessary
 * @param {number} value
 * @param {number} precision Greater than 0
 * @returns {number}
 * @throws {Error} on missing value or invalid precision
 */
export function roundWithPrecision(value: number, precision: number): number | never {
    if (precision == null) {
        throw new Error('Precision is not provided');
    }

    if (precision <= 0) {
        throw new Error('Cannot have precision of less than 1');
    }

    if (value == null) {
        throw new Error('Value is not provided');
    }

    const roundPrecision = 10 ** precision;
    return Math.round((value + Number.EPSILON) * roundPrecision) / roundPrecision;
}
