// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Component, EventEmitter, Input, NgModule, Output, ViewChild } from '@angular/core';
import { ModalModule, ModalOptions, ModalDirective } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-delete-modal',
    template: `
<div class="modal fade delete-modal" tabindex="-1" role="dialog"
  bsModal [config]="deleteModalConfig" #deleteModal="bs-modal">
  <div class="modal-dialog modal-danger">
    <div class="modal-content">

      <!-- header -->
      <div class="modal-header flex-column align-items-center">
        <h4>{{headerText}}</h4>
      </div>

      <!-- body -->
      <div class="modal-body word-break">
        {{bodyText}}
        <ng-content></ng-content>
      </div>

      <!-- footer -->
      <div class="modal-footer">
        <button class="btn btn-md btn-danger btn-delete" type="submit" (click)="deleteModal.hide(); delete.emit()">
          Delete
        </button>
        <button class="btn btn-md btn-secondary btn-cancel" type="reset" (click)="deleteModal.hide()">
          Cancel
        </button>
      </div>

    </div>
  </div>
</div>`,
    exportAs: 'bs-modal'
})
export class DeleteModalComponent {
    @Input() public headerText = 'Delete Confirmation';
    @Input() public bodyText: string;
    @Output() public delete = new EventEmitter<void>();
    @ViewChild('deleteModal') deleteModal: ModalDirective;
    public deleteModalConfig = { ignoreBackdropClick: true } as ModalOptions;

    /** This is an context value that will be passed to the onClick callback and will be discarded automatically after that */
    public context: any;

    public show(context?: any) {
        this.deleteModal.show();
        this.context = context;
    }
    public hide() {
        this.deleteModal.hide();
        this.context = null;
    }
}

@NgModule({
    declarations: [DeleteModalComponent],
    exports: [DeleteModalComponent],
    imports: [ModalModule.forRoot()]
})
export class DeleteModalModule { }
