// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an 'AS IS' BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { formatScore } from '@/_helpers/dap';
import { DapDocumentBase, DapNextRevisionVersion, DapTemplateWithSubject, DapDocumentScoreData } from '@/_models/dap-document';
import { DapDocumentFilter } from '@/_models/dap-document/dap-document-filter';
import { DapDocumentFilterData } from '@/_models/dap-document/dap-document-filter-data';
import { PublicPart } from '@/_shared';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DapDocumentManagementService {

    constructor(private http: HttpClient) { }

    getDocumentFilterData(): Observable<DapDocumentFilterData> {
        return this.http.get<DapDocumentFilterData>(`${environment.dapApi}/dap/documents/filter/documentdata`);
    }

    getStatusFilterData(): Observable<DapDocumentFilterData> {
        return this.http.get<DapDocumentFilterData>(`${environment.dapApi}/dap/documents/filter/statusdata`);
    }

    getDocuments(filter: DapDocumentFilter): Observable<DapDocumentBase[]> {
        return this.http.post<DapDocumentBase[]>(`${environment.dapApi}/dap/documents/filter`, filter);
    }

    getScoreDiagrams(documents: DapDocumentBase[]): Observable<DapDocumentBase[]> {
        const ids = documents.map(d => d.id);
        return this.http.post<DapDocumentScoreData[]>(`${environment.dapApi}/dap/documents/scores`, ids).pipe(
            switchMap(scoreData => {
                const docs = documents.map(d => {
                        const data = scoreData.find(p => p.id === d.id);
                        const doc = ({...d,
                        scoreDiagram: data.svg,
                        score: formatScore(data?.score),
                        value: data?.value,
                        valueUom: data?.valueUom});
                        return doc;
                    });
                return of(docs);
            })
        );
    }

    toggleFavouriteStatus(dapHeaderId: number): Observable<boolean> {
        return this.http.post<boolean>(`${environment.dapApi}/dap/documents/${dapHeaderId}/favourite/toggle`, null);
    }

    createDocument(templateId: number, subject: string): Observable<number> {
        const data = {
            templateId: templateId,
            subject: subject
        };
        return this.http.post<number>(`${environment.dapApi}/dap/documents/create`, data);
    }

    copyDocument(documentId: number, subject: string): Observable<number> {
        return this.http.post<number>(`${environment.dapApi}/dap/documents/${documentId}/copy?subject=${subject}`, null);
    }

    createNewRevision(documentId: number): Observable<number> {
        return this.http.post<number>(`${environment.dapApi}/dap/documents/${documentId}/newRevision`, null);
    }

    createNewVersion(documentId: number): Observable<number> {
        return this.http.post<number>(`${environment.dapApi}/dap/documents/${documentId}/newVersion`, null);
    }

    deleteDocument(documentId: number): Observable<DapDocumentBase> {
        return this.http.post<DapDocumentBase>(`${environment.dapApi}/dap/documents/${documentId}/delete`, null);
    }

    getTemplatesWithScope(): Observable<DapTemplateWithSubject[]> {
        return this.http.get<DapTemplateWithSubject[]>(`${environment.dapApi}/dap/documents/templates`);
    }

    getNextRevisionVersion(documentId: number): Observable<DapNextRevisionVersion> {
        return this.http.get<DapNextRevisionVersion>(`${environment.dapApi}/dap/documents/${documentId}/nextRevisionVersion`);
    }
}

/* eslint-disable */
export class FakeDapDocumentManagementService implements PublicPart<DapDocumentManagementService> {
    getDocumentFilterData(): Observable<DapDocumentFilterData> {
        throw new Error('Method not implemented.');
    }
    getStatusFilterData(): Observable<DapDocumentFilterData> {
        throw new Error('Method not implemented.');
    }
    getDocuments(): Observable<DapDocumentBase[]> {
        throw new Error('Method not implemented.');
    }
    getScoreDiagrams(documents: DapDocumentBase[]): Observable<DapDocumentBase[]> {
        throw new Error('Method not implemented.');
    }
    toggleFavouriteStatus(dapHeaderId: number): Observable<boolean> {
        throw new Error('Method not implemented.');
    }
    createDocument(templateId: number, subject: string): Observable<number> {
        throw new Error('Method not implemented.');
    }
    copyDocument(documentId: number, subject: string): Observable<number> {
        throw new Error('Method not implemented.');
    }
    createNewRevision(documentId: number): Observable<number> {
        throw new Error('Method not implemented.');
    }
    createNewVersion(documentId: number): Observable<number> {
        throw new Error('Method not implemented.');
    }
    deleteDocument(documentId: number): Observable<DapDocumentBase> {
        throw new Error('Method not implemented.');
    }
    getTemplatesWithScope(): Observable<DapTemplateWithSubject[]> {
        throw new Error('Method not implemented.');
    }
    getNextRevisionVersion(documentId: number): Observable<DapNextRevisionVersion> {
        throw new Error('Method not implemented.');
    }
}
/* eslint-enable */

