<app-modal-dialog #dialog="bs-modal"
                  [headerText]="title"
                  class="manage-monitors-modal"
                  modalClass="modal-dialog"
                  [actions]="actions"
>
  <div class="search">
    <div class="input-group mb-3">
      <div class="input-group-prepend">
        <span class="input-group-text">
          <i class="fa fa-search fa-sm"></i>
        </span>
      </div>
      <input class="form-control ng-pristine ng-valid ng-touched"
             placeholder="Search..."
             type="search"
             [formControl]="searchControl">
    </div>
  </div>

  <div class="list-group overflow-auto" [ngStyle]="{'height.px': rowContainerHeight.toString()}">
    <div *ngIf="!filteredUsers?.length" class="d-flex align-items-center justify-content-center h-100 fixed-top position-relative">No users available.</div>
    <button *ngFor="let user of filteredUsers; last as isLast"
            class="list-group-item list-group-item-action p-0"
            [disabled]="disabled"
            [class.mb-1]="!isLast"
            [class.active]="chosenUsers.has(user)"
            (click)="onItemToggled(user)">
      <span class="d-flex flex-row p-2 pl-3 justify-content-between align-items-center">
        <span class="d-flex flex-column max-width-85">
          <b class="user-name text-truncate" [title]="user.firstName + ' ' + user.lastName">{{user.firstName}} {{user.lastName}}</b>
          <span class="user-email text-truncate" [title]="user.email">{{user.email}}</span>
          <small class="user-role text-truncate" [title]="user.roleNames.join(', ')">{{user.roleNames.join(', ')}}</small>
        </span>
        <label class="switch switch-pill switch-outline-primary-alt mt-2 mr-3">
          <input [checked]="chosenUsers.has(user)" class="switch-input" type="checkbox" (click)="onItemToggled(user)">
          <span class="switch-slider"></span>
        </label>
      </span>
    </button>
  </div>
</app-modal-dialog>
