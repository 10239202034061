// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

export enum Severity {
    info = 1,
    warning = 2,
    error = 3
}

export class ValidationMessage {
    public field?: string;
    public rowIndex?: number;
    public message: string;
    public severity: Severity;
    public originalValue?: any;
    public context?: any;
}
export class ValidationResult {
    public messages: ValidationMessage[];
    public isValid: boolean;
}
