// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

export class DapDocumentChange {
    public item: string;
    public isCritical: boolean;
    public scope: DapDocumentChangeScope;
    public itemType: DapDocumentChangeType;
    public action: DapDocumentChangeAction;
    public previousValue: string;
    public editedValue: string;
}

export class DapDocumentRecord extends DapDocumentChange {
    public changedById: number;
    public changedByName: string;
    public changeDate: Date;
    public isCritical: boolean;
    public changes: DapDocumentRecord[];
}

export enum DapDocumentChangeScope {
    Document,
    Section,
    Dap
}

export enum DapDocumentChangeType {
    Field,
    Section,
    Dap,
    Row,
    Cell
}

export enum DapDocumentChangeAction {
    Insert,
    Delete,
    Edit
}