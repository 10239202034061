// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Provider, Injectable } from '@angular/core';

import { Action, ActionReducerMap, MetaReducer, Store } from '@ngrx/store';

/**
 * storeFreeze prevents state from being mutated. When mutation occurs, an
 * exception will be thrown. This is useful during development mode to
 * ensure that none of the reducers accidentally mutates the state.
 */
import { storeFreeze } from 'ngrx-store-freeze';

import { environment } from '../../environments/environment';

import { ALERT_DETAILS_TOKEN } from './tokens';

import { AdminState } from './admin/admin.state';
import { AdminStore } from './admin/admin.store';
import { AdminEffects } from './admin/admin.effects';

import { UserState } from './user/user.state';
import { UserStore } from './user/user.store';
import { UserEffects } from './user/user.effects';

import { RoleState } from './role/role.state';
import { RoleStore } from './role/role.store';
import { RoleEffects } from './role/role.effects';

import { PickListState } from './pick-list/pick-list.state';
import { PickListStore } from './pick-list/pick-list.store';
import { PickListEffects } from './pick-list/pick-list.effects';

import { TooltipState } from './tooltip/tooltip.state';
import { TooltipStore } from './tooltip/tooltip.store';
import { TooltipEffects } from './tooltip/tooltip.effects';

import { ServerConnectionState } from './server-connection/server-connection.state';
import { ServerConnectionStore } from './server-connection/server-connection.store';
import { ServerConnectionEffects } from './server-connection/server-connection.effects';

import { MnemonicState } from './mnemonic/mnemonic.state';
import { MnemonicStore } from './mnemonic/mnemonic.store';
import { MnemonicEffects } from './mnemonic/mnemonic.effects';

import { InfraPanelState } from './infra-panel/infra-panel.state';
import { InfraPanelStore } from './infra-panel/infra-panel.store';
import { InfraPanelEffects } from './infra-panel/infra-panel.effects';

import { MonitorState } from './monitor/monitor.state';
import { MonitorStore } from './monitor/monitor.store';
import { MonitorEffects } from './monitor/monitor.effects';

import { AlertState } from './alert/alert.state';
import { AlertStore } from './alert/alert.store';
import { AlertEffects } from './alert/alert.effects';

import { OrderState } from './order/order.state';
import { OrderStore } from './order/order.store';
import { OrderEffects } from './order/order.effects';

import { RuleManagementState } from './rule-management/rule-management.state';
import { RuleManagementStore } from './rule-management/rule-management.store';
import { RuleManagementEffects } from './rule-management/rule-management.effects';

import { KpiState } from './kpi/kpi.state';
import { KpiStore } from './kpi/kpi.store';
import { KpiEffects } from './kpi/kpi.effects';

import { SystemParametersState } from './system-parameters/system-parameters.state';
import { SystemParametersStore } from './system-parameters/system-parameters.store';
import { SystemParametersEffects } from './system-parameters/system-parameters.effects';

import { ApplianceState } from './appliance/appliance.state';
import { ApplianceStore } from './appliance/appliance.store';
import { ApplianceEffects } from './appliance/appliance.effects';

import { TransferManagementEffects, TransferManagementState, TransferManagementStore } from './transfer-management';

import { MerlinManagementStore } from './merlin-management/merlin-management.store';
import { MerlinManagementState } from './merlin-management/merlin-management.state';
import { MerlinManagementEffects } from './merlin-management/merlin-management.effects';

import { DapTemplateManagementEffects, DapTemplateManagementState, DapTemplateManagementStore } from './dap-template-management';
import { DapDocumentDetailsEffects, DapDocumentDetailsState, DapDocumentDetailsStore } from './dap-document-details';
import { DapDocumentManagementEffects, DapDocumentManagementState, DapDocumentManagementStore } from './dap-document-management';
import { DapStatusManagementEffects, DapStatusManagementState, DapStatusManagementStore } from './dap-status-management';
import { EditingMonitoringEffects, EditingMonitoringState, EditingMonitoringStore } from './editing-monitoring';
import { DapDocumentStatusEffects, DapDocumentStatusState, DapDocumentStatusStore } from './dap-document-status';
import { LicenseEffects, LicenseInfoState, LicenseInfoStore, LicenseState, LicenseStore, ProfileState, ProfileStore } from './dap-license-management';
import { DapMnemonicEffects, DapMnemonicState, DapMnemonicStore } from './dap-mnemonic-catalog';

/** this is representing the app state(entities) as a typed interface for easier access of the individual parts of the state
 *
 * IMPORTANT!!! AppState property name MUST MATCH reducers(below) property name !!!
 *
 * Instructions to add state
 * 1. add state object to `AppState`
 * 2. attach **actions reducers**
 *     * (**using `ngrx-actions`**) by adding **store class** to `actionStoreMap`
 *     * (**using normal `@ngrx`**) by adding **action-reducer map** to `reducers`
 * 3. attach **selectors** using `selectorProviders`
 * 4. _(Optional)_ attach effects using `rootEffects`
 */
export class AppState {
    public adminState: AdminState;
    public userState: UserState;
    public roleState: RoleState;
    public pickListState: PickListState;
    public tooltipState: TooltipState;
    public serverConnectionState: ServerConnectionState;
    public mnemonicState: MnemonicState;
    public infraPanelState: InfraPanelState;
    public monitorState: MonitorState;
    public alertState: AlertState;
    public orderState: OrderState;
    public ruleManagementState: RuleManagementState;
    public kpiState: KpiState;
    public systemParametersState: SystemParametersState;
    public applianceState: ApplianceState;
    public transferManagementState: TransferManagementState;
    public merlinManagementState: MerlinManagementState;
    public dapTemplateManagementState: DapTemplateManagementState;
    public dapDocumentManagementState: DapDocumentManagementState;
    public dapStatusManagementState: DapStatusManagementState;
    public dapDocumentDetailsState: DapDocumentDetailsState;
    public editingMonitoringState: EditingMonitoringState;
    public dapDocumentStatusState: DapDocumentStatusState;
    public licenseState: LicenseState;
    public licenseInfoState: LicenseInfoState;
    public profileState: ProfileState;
    public dapMnemonicState: DapMnemonicState;
}

@Injectable()
export class AppStore extends Store<AppState> { }
/** The actual reducers that will do the work and accumulate state
 * Reducer map defined in ngrx style
 */
export const reducers = {} as ActionReducerMap<AppState, Action>;

declare type ActionStoreMap<T, V = any> = {
    [p in keyof T]: V;
};

/** Reducer map generated using ngrx-Actions from store class with decorator */
export const actionStoreMap: Partial<ActionStoreMap<AppState, any>> = {
    adminState: AdminStore,
    userState: UserStore,
    roleState: RoleStore,
    pickListState: PickListStore,
    tooltipState: TooltipStore,
    serverConnectionState: ServerConnectionStore,
    mnemonicState: MnemonicStore,
    infraPanelState: InfraPanelStore,
    monitorState: MonitorStore,
    alertState: AlertStore,
    orderState: OrderStore,
    ruleManagementState: RuleManagementStore,
    kpiState: KpiStore,
    systemParametersState: SystemParametersStore,
    applianceState: ApplianceStore,
    transferManagementState: TransferManagementStore,
    merlinManagementState: MerlinManagementStore,
    dapTemplateManagementState: DapTemplateManagementStore,
    dapDocumentManagementState: DapDocumentManagementStore,
    dapStatusManagementState: DapStatusManagementStore,
    dapDocumentDetailsState: DapDocumentDetailsStore,
    editingMonitoringState: EditingMonitoringStore,
    dapDocumentStatusState: DapDocumentStatusStore,
    licenseState: LicenseStore,
    licenseInfoState: LicenseInfoStore,
    profileState: ProfileStore,
    dapMnemonicState: DapMnemonicStore,

};


export const rootEffects = [
    AdminEffects,
    UserEffects,
    RoleEffects,
    PickListEffects,
    TooltipEffects,
    ServerConnectionEffects,
    MnemonicEffects,
    InfraPanelEffects,
    MonitorEffects,
    AlertEffects,
    OrderEffects,
    RuleManagementEffects,
    KpiEffects,
    SystemParametersEffects,
    ApplianceEffects,
    TransferManagementEffects,
    MerlinManagementEffects,
    DapTemplateManagementEffects,
    DapDocumentManagementEffects,
    DapDocumentDetailsEffects,
    DapStatusManagementEffects,
    EditingMonitoringEffects,
    DapDocumentStatusEffects,
    LicenseEffects,
    DapMnemonicEffects
];


// export function someSelectorFactory(x: Store<AppState>) { return new SomeFeatureSelectors(x.select(y => y.someStateState)); }
export const selectorProviders: Provider[] = [
    { provide: ALERT_DETAILS_TOKEN, useFactory: (x: AppStore) => x.select('alertState', 'alertDetails'), deps: [Store] },
];


/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<AppState>[] = environment.production ? [] : [storeFreeze];
