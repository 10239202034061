// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { NgxAlertService, getMessageFromError } from 'ngx-shared';
import { SystemConfigService } from '@/_services';
import {
    FetchFailedSystemConfigurationAction, FetchOkSystemConfigurationAction, FetchSystemConfigurationAction,
    LoadSystemConfigurationAction,
    PersistFailedSystemConfigurationAction, PersistOkSystemConfigurationAction, PersistSystemConfigurationAction,
    FetchPublicSystemConfigurationAction, FetchPublicOkSystemConfigurationAction, FetchPublicFailedSystemConfigurationAction,
} from './admin.actions';

@Injectable()
export class AdminEffects {

    
    public onFetchPublic$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchPublicSystemConfigurationAction),
        switchMap(() => this.sysConfigService.getPublic().pipe(
            switchMap(x => [new LoadSystemConfigurationAction(x), new FetchPublicOkSystemConfigurationAction()]),
            catchError(error => {
                this.logger.error('Error while fetch of system public configuration', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchPublicFailedSystemConfigurationAction(error));
            })
        )),
    ));

    
    public onFetch$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchSystemConfigurationAction),
        switchMap(() => this.sysConfigService.get().pipe(
            switchMap(x => [new LoadSystemConfigurationAction(x), new FetchOkSystemConfigurationAction()]),
            catchError(error => {
                this.logger.error('Error while fetch of system configuration', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedSystemConfigurationAction(error));
            })
        )),
    ));

    
    public onPersist$ = createEffect(() => this.actions$.pipe(
        ofAction(PersistSystemConfigurationAction),
        switchMap(a => this.sysConfigService.update(a.config).pipe(
            switchMap(x => {
                this.alertService.success('System configuration updated.');
                return [new LoadSystemConfigurationAction(x), new PersistOkSystemConfigurationAction()];
            }),
            catchError(error => {
                this.logger.error('Error while persist of system configuration', error);
                this.alertService.error(getMessageFromError(error));
                return of(new PersistFailedSystemConfigurationAction(error));
            })
        )),
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly sysConfigService: SystemConfigService) { }
}
