// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { Action } from '@/_models';
import { CurrentUserService } from '@/_services';
import { Routes } from '@/configuration';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NgxAuthorizationGuard {
    constructor(
        private readonly router: Router,
        private readonly currentUserService: CurrentUserService        
    ) { }

    public canActivate(route: ActivatedRouteSnapshot): boolean|Observable<boolean> {        
        const currentUser = this.currentUserService.user;
        if (currentUser) {
            if (this.isUnauthorized(route.data && route.data.actions, currentUser.actions)) {
                // user not authorized so redirect to home page OR maybe we should have a 403 page  
                this.router.navigate([Routes.home]);                
                return false;
            }

            // authorized so return true
            return true;
        }

        return new Observable<boolean>((observer) => {
            this.currentUserService.EnsureCurrentUser()?.subscribe( () => {
                if (!this.currentUserService.user) {
                    this.router.navigate([Routes.home]);
                    observer.next(false);
                    observer.complete();                    
                }
                else
                {
                    const allowed = !this.isUnauthorized(route.data && route.data.actions, this.currentUserService.user.actions);
                    observer.next(allowed);
                    observer.complete();
                }
            });
        });
    }

    private isUnauthorized(routeActions: string[], userActions: Action[]) {
        return Array.isArray(routeActions)
            && (!Array.isArray(userActions) || userActions.every(x => routeActions.every(y => y !== x.name)));
    }
}
