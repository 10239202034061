//  -----------------------------------------------------------------------
//  PDS DRQe
//
//  Copyright 2019 PDS Americas LLC
//
//  Licensed under the PDS Open Source WITSML Product License Agreement (the
//  "License"); you may not use this file except in compliance with the License.
//  You may obtain a copy of the License at
//
//      http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//  Unless required by applicable law or agreed to in writing, software
//  distributed under the License is distributed on an "AS IS" BASIS,
//  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//  See the License for the specific language governing permissions and
//  limitations under the License.
//  -----------------------------------------------------------------------

import { StateDetails, StateGroup, StateTableVisualisationType } from "@/_models";
import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";

@Component({
    selector: 'app-state-details',
    templateUrl: './state-details.component.html',
    styleUrls: ['./state-details.component.css']
})
export class StateDetailsComponent implements OnChanges {
    @Input() public stateDetails: StateDetails;
    @Input() public isOnScreen: boolean;

    public stateGroups: StateGroup[] = [];
    public enums = {
        tableType: StateTableVisualisationType,
    };
    
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.stateDetails) {
            this.setGroups();
        }
    }

    private setGroups() {
        if (!this.stateDetails?.values) {
            this.stateGroups = [];
            return;
        }

        const grouped = this.stateDetails.values.reduce((prev: StateGroup[], curr) => {
            const match = prev.find(x => x.name === curr.group);
            if (match) {
                match.stateValues.push(curr);
            } else {
                prev.push({ name: curr.group, stateValues: [curr] } as StateGroup);
            }
            return prev;
        }, []);
        this.stateGroups = grouped;
    }
}
