import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

class PeriodSelectorIntervalEntry {
  public seconds: number;
  public textSingle: string;
  public textPlural: string;
}

@Component({
  selector: 'app-period-selector',
  templateUrl: './period-selector.component.html',
})
export class PeriodSelectorComponent {
  @Input() 
  public get disabled(): boolean { return this._disabled; }
  public set disabled(disabled: boolean) {
    this._disabled = disabled;
    if (this._disabled) { this.calculateAndEmit(); }
  }
  private _disabled = false;
  @Output() public changed = new EventEmitter<number>();
  public numberChoices: number[] = [1, 2, 5, 10, 20, 30]; 
  public intervalChoices: PeriodSelectorIntervalEntry[] = [
    {seconds: 60, textSingle: 'minute', textPlural: 'minutes'},
    {seconds: 3600, textSingle: 'hour', textPlural: 'hours'},
    {seconds: 86400, textSingle: 'day', textPlural: 'days'}
  ];

  public numberChoice = this.numberChoices[0];
  public intervalChoice = this.intervalChoices[1];

  public onNumberChanged() {
    this.calculateAndEmit();
  }

  public onIntervalChanged() {
    this.calculateAndEmit();
  }

  private calculateAndEmit() {
    if (!this.numberChoice || !this.intervalChoice) { return; }
    this.changed.emit(this.numberChoice * this.intervalChoice.seconds);
  }
}

@NgModule({
    declarations: [PeriodSelectorComponent],
    exports: [PeriodSelectorComponent],
    imports: [CommonModule, FormsModule, ReactiveFormsModule, NgSelectModule]
})
export class PeriodSelectorModule {}
