// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { DapBreachType } from './dap-document-status-data';

export enum DapScoreType {
    Delivery,
    Quality
}

export enum DapScoreTargetType {
    Dap,
    Category
}

export class DapScoreChange {
    public id: number;
    public target: DapScoreTargetType;
    public type: DapScoreType;
    public score: number | null;
    public signedOff: boolean;
    public signedOffComment: string;
}

export class DapBreachChange {
    public id: number;
    public dapId: number;
    public type: DapBreachType;
    public value: number;
    public description: string;
    public deleted: boolean;
    public signedOff: boolean;
    public signedOffComment: string;
}

export class DapScoreChanges {
    public dapDocumentId: number;
    public scores: DapScoreChange[];
    public breaches: DapBreachChange[];
    public preview: boolean;
}
