//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { calculateRuleKey } from '@/_helpers/functions';
import { RuleManagementRuleInstance, RuleManagementRuleInstanceData } from '@/_models';
import { Store, Action } from '../ngrx-actions';
import {
    LoadInStateRuleManagementFilterListsAction, LoadInStateRuleInstanceDataAction,
    LoadInStateRuleInstanceParametersAction, LoadInStateCopyFromOrderListAction,
    UpdateInStateRuleInstanceParametersOverrideIndicatorsAction,
} from './rule-management.actions';
import { RuleManagementState } from './rule-management.state';

@Store({} as RuleManagementState)
export class RuleManagementStore {

    @Action(LoadInStateRuleManagementFilterListsAction)
    public loadInStateFilterLists(state: RuleManagementState, { filterLists }: LoadInStateRuleManagementFilterListsAction) {
        return { ...state, filterLists } as RuleManagementState;
    }

    @Action(LoadInStateRuleInstanceDataAction)
    public loadInStateRuleInstanceData(state: RuleManagementState, { ruleInstanceData }: LoadInStateRuleInstanceDataAction) {
        return { ...state, ruleInstanceData } as RuleManagementState;
    }

    @Action(LoadInStateRuleInstanceParametersAction)
    public loadInStateRuleInstanceParameters(
        state: RuleManagementState,
        { ruleInstanceParameters }: LoadInStateRuleInstanceParametersAction
    ) {
        return { ...state, ruleInstanceParameters } as RuleManagementState;
    }

    @Action(LoadInStateCopyFromOrderListAction)
    public loadInStateCopyFromOrderLists(state: RuleManagementState, { ordersToCopyFromList }: LoadInStateCopyFromOrderListAction) {
        return { ...state, ordersToCopyFromList } as RuleManagementState;
    }

    @Action(UpdateInStateRuleInstanceParametersOverrideIndicatorsAction)
    public updateInStateOverrideIndicators(
            state: RuleManagementState,
            { ruleInstances }: UpdateInStateRuleInstanceParametersOverrideIndicatorsAction) {
        const map = ruleInstances.reduce((r, i) => {
            r[calculateRuleKey(i)] = {overrides: i.overrides, overriddenTargets: i.overriddenTargets};
            return r;
        }, {});
        const updated = state.ruleInstanceData.items.map(r => {
            const n = { ...r} as RuleManagementRuleInstance;
            const key = calculateRuleKey(n);
            if (map[key]) {
                n.overrides = map[key].overrides;
                n.overriddenTargets = map[key].overriddenTargets;
            }
            return n;
        });
        const ruleInstanceData = new RuleManagementRuleInstanceData(updated, false, state.ruleInstanceData.maxCount);
        const st = { ...state, ruleInstanceData } as RuleManagementState;
        return st;
    }

}
