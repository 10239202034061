// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { Replace } from '../shared';
import { NgxBreadcrumbService } from './ngx-breadcrumb.service';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: 'ngx-breadcrumb',
  template: `
    <ng-template ngFor let-breadcrumb [ngForOf]="breadcrumbs | async" let-last = last>
      <li class="breadcrumb-item"
          *ngIf="breadcrumb.label.title && (breadcrumb.url.slice(-1) == '/' || last)"
          [ngClass]="{active: last}">
        <a *ngIf="!last" [routerLink]="breadcrumb.url">{{breadcrumb.label.title}}</a>
        <span *ngIf="last" [routerLink]="breadcrumb.url">{{breadcrumb.label.title}}</span>
      </li>
    </ng-template>
  `
})
export class NgxBreadcrumbComponent implements OnInit, OnDestroy {
  @Input() fixed: boolean;
  public breadcrumbs;

  constructor(public service: NgxBreadcrumbService, public el: ElementRef) { }

  public ngOnInit(): void {
    Replace(this.el);
    this.isFixed();
    this.breadcrumbs = this.service.breadcrumbs;
  }

  ngOnDestroy(): void {
    document.body.classList.remove('breadcrumb-fixed');
  }

  isFixed(): void {
    if (this.fixed) { document.querySelector('body').classList.add('breadcrumb-fixed'); }
  }
}
