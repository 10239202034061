// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { DapSectionStatus } from '@/_models/dap-common';
import { DapTemplateContentSectionType } from '@/_models/dap-document-details';
import { DapDapVM } from './dap-dap-vm';
import { DapDataContainerVM } from './dap-data-container-vm';
import { DapDocumentVM } from './dap-document-vm';

export class DapSectionVM extends DapDataContainerVM {
    public key: string;
    public name: string;
    public templateContent: DapTemplateContentSectionType;
    public dapVMs: DapDapVM[] = [];
    public status: DapSectionStatus;
    // backwards reference to the doc VM
    public docVM: DapDocumentVM;
}
