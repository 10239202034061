// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { TimeZoneGmtZero } from '@/_helpers';
import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class RigTimeService {
    public time$ = new Subject<Date>();

    private interval: NodeJS.Timeout;
    private _timeZone: string = TimeZoneGmtZero;

    public get timeZone(): string { return this._timeZone; }
    public applyTimeZone(timeZone: string) {
        this._timeZone = timeZone ?? TimeZoneGmtZero;
    }

    public get currentTime(): Date {
        return this.resolveDate();
    }

    public startTimer() {
        this.time$.next(this.resolveDate());
        this.interval = setInterval(() => {
            this.time$.next(this.resolveDate());
          },1000)
    }

    public stopTimer() {
        clearInterval(this.interval);
    }

    public toRigTime(date: Date): Date {
        return new Date(moment.tz(moment(date), this._timeZone).format('YYYY-MM-DDTHH:mm:ss'));
    }

    public toUtc(date: Date): Date {
        return new Date(moment.tz(moment(date, this._timeZone), TimeZoneGmtZero).format('YYYY-MM-DDTHH:mm:ss'));
    }

    private resolveDate(): Date {
        return new Date(moment.tz(moment(), this._timeZone).format('YYYY-MM-DDTHH:mm:ss'));
    }
}
