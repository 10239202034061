// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

export class DapSectionTypeScoringDefaults {
    public key: string;
    public includeInScoring: boolean;
    public weight: number;
}

export class DapTypeScoringDefaults
{
    public key: string;
    public includeInScoring: boolean;
    public weight: number;
    public deliveryWeight: number;
    public qualityWeight: number;
    public deliveryBreachLimit: number;
    public qualityBreachLimit: number;
    public serviceLevelAgreement: number;
}

export class DapScoringModelDefaults {
    public sectionTypeDefaults: DapSectionTypeScoringDefaults[];
    public dapTypeDefaults: DapTypeScoringDefaults[];
}
