//  -----------------------------------------------------------------------
//  PDS DRQe
//
//  Copyright 2019 PDS Americas LLC
//
//  Licensed under the PDS Open Source WITSML Product License Agreement (the
//  "License"); you may not use this file except in compliance with the License.
//  You may obtain a copy of the License at
//
//      http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//  Unless required by applicable law or agreed to in writing, software
//  distributed under the License is distributed on an "AS IS" BASIS,
//  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//  See the License for the specific language governing permissions and
//  limitations under the License.
//  -----------------------------------------------------------------------

import { Action } from '@ngrx/store';
import { InfraPanel } from '@/_models';

export class LoadInStateInfraPanelsAction implements Action {
    readonly type = '[InfraPanels] InState Load StateInfraPanels action';

    constructor(public readonly infraPanels: InfraPanel[]) { }
}

export class FetchInfraPanelsAction implements Action {
    public type = '[InfraPanels] Fetch InfraPanels action';
}
export class FetchOkInfraPanelsAction implements Action {
    public type = '[InfraPanels] Fetch OK InfraPanels action';
}
export class FetchFailedInfraPanelsAction implements Action {
    public type = '[InfraPanels] Fetch Cancel InfraPanels action';
    constructor(public readonly error: Error | string | any) { }
}
