// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS America LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//      http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------
import { AbstractControl, AsyncValidatorFn, UntypedFormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { of, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import * as libPhoneNumber from 'google-libphonenumber';

export class CustomValidators {
    static url(control: AbstractControl): ValidationErrors | null {
        // eslint-disable-next-line no-useless-escape
        const URL_REGEXP = /^((www\..+)|(http:\/\/.+)|(https:\/\/.+)|([\w\/]+))$/;

        if (!control.value) {
            return null;
        }

        if (URL_REGEXP.test(control.value)) {
            return null;
        }

        return {
            invalidUrl: true
        };
    }

    static absoluteUrl(control: AbstractControl): ValidationErrors | null {
        // eslint-disable-next-line no-useless-escape
        const URL_REGEXP = /^((www\..+)|(http:\/\/.+)|(https:\/\/.+))$/;

        if (!control.value) {
            return null;
        }

        if (URL_REGEXP.test(control.value)) {
            return null;
        }

        return {
            invalidUrl: true
        };
    }

    static websocketUrl(control: AbstractControl): ValidationErrors | null {
        const URL_REGEXP = /^(ws|wss):\/\/.+\..+$/;

        if (!control.value) {
            return null;
        }

        if (URL_REGEXP.test(control.value)) {
            return null;
        }

        return {
            invalidUrl: true
        };
    }

    /***
     * Validates asynchronous if a value is duplicating another in the provided array
     * @param {Observable<(string | number)[]>} values$
     * @param {boolean} isCaseInsensitive
     * @returns {AsyncValidatorFn}
     */
    static duplicateAsync(values$: Observable<(string | number)[]>, isCaseInsensitive = false): AsyncValidatorFn {
        return (control: AbstractControl): Observable<ValidationErrors | null> => {
            if (!values$ || !control.valueChanges) {
                return of(null);
            }

            /**
             * Async validators can sometimes cause form validity status to be stuck as 'PENDING'
             * https://github.com/angular/angular/issues/13200
             */
            return values$.pipe(
                take(1), // this was added as suggested in the thread
                map(values => Array.isArray(values)
                    && (isCaseInsensitive
                        && values.some(val => val.toString().localeCompare(control.value, undefined, { sensitivity: 'accent' }) === 0)
                        || values.some(val => control.value === val))
                ),
                map(valid => valid ? { duplicated: true } : null)
            );
        };
    }

    static invalidValues(...invalidValue: any[]): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!Array.isArray(invalidValue) || invalidValue.every(x => x !== control.value)) {
                return null;
            }

            return {
                invalidValue: true
            };
        };
    }

    static propertyMin(propertyName: string, minValue: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control.value || !control.value[propertyName] || control.value[propertyName] >= minValue) {
                return null;
            }

            return {
                propertyMin: { min: minValue, actual: control.value[propertyName] }
            };
        };
    }

    static propertyRequired(propertyName: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control.value && !this.isEmptyInputValue(control.value[propertyName])) {
                return null;
            }

            return {
                propertyRequired: { name: propertyName }
            };
        };
    }

    static isEmptyInputValue(value: any): boolean {
        return value == null || value.length === 0;
    }

    /**
     * Validates if there is a white space anywhere in a string
     * @param {AbstractControl} control
     * @returns {ValidationErrors | null}
     */
    static noWhiteSpace(control: AbstractControl): ValidationErrors | null {
        const REGEX = /\s/g;

        if (!control.value) {
            return null;
        }

        if (!REGEX.test(control.value)) {
            return null;
        }

        return {
            spaceBetweenWords: true
        };
    }

    static trim: ValidatorFn = (control: AbstractControl) => {
        if (/\S/.test(control.value)) { // value contains anything other than space
            if (control.value.startsWith(' ') && control.value.endsWith(' ')) {
                return {
                    'trimError': 'Input has leading and trailing whitespaces.'
                };
            }

            if (control.value.startsWith(' ')) {
                return {
                    'trimError': 'Input has leading whitespace.'
                };
            }
            if (control.value.endsWith(' ')) {
                return {
                    'trimError': 'Input has trailing whitespace.'
                };
            }
        }
        return null;
    }

    static whiteSpace: ValidatorFn = (control: AbstractControl) => {
        if (control.value !== '' && !/\S/.test(control.value)) {
            return {
                whiteSpaceError: 'Field cannot be empty.',
            };
        }
        return null;
    }

    static phoneNumber(control: AbstractControl): ValidationErrors | null {
        let parsedValue: libPhoneNumber.phoneNumber;
        let isValid = false;
        const phoneUtil = libPhoneNumber.PhoneNumberUtil.getInstance();

        // check isNaN(control.value) because 'tel type' input allows user to type symbols and numbers,
        // for example 'something+4780...', which is not valid phone number, but the parser would return valid state in this case
        if (!isNaN(control.value)) {
            try {
                parsedValue = phoneUtil.parse(control.value, '');
            } catch (error) {
                return {
                    message: 'Wrong Phone number format.'
                };
            }
        }
        if (parsedValue != null) {
            isValid = phoneUtil.isValidNumber(parsedValue);
        }

        if (!isValid) {
            return {
                message: 'Wrong Phone number format.'
            };
        }

        return null;
    }

    static separatedEmails(control: AbstractControl, separator = ';'): { [key: string]: any } | null {
        const val = control.value as string;

        if (!val) {
            return null;
        }

        const emails =  val.split(separator).map(e => e.trim());
        const forbidden = emails.some(email => Validators.email(new UntypedFormControl(email)) !== null);

        return forbidden ? { 'toAddress': { value: control.value } } : null;
    }
}
