// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Action } from '@ngrx/store';
import { ApplianceJobVM, ApplianceServiceVM } from '@/_models/appliance-common';
import { MerlinApplianceVm } from '@/_models/merlin-management/merlin-appliance-vm';
import { MerlinAuthSettingsConfigVm, MerlinReleaseInfoVM, MerlinRepoStatisticsVm, MerlinRoutingTableItemVm, MerlinUpdateAuthSettingsConfigVm } from '@/_models/merlin-management';
import { ApplianceFileVM } from '@/_models/appliance-common/appliance-file-vm';
import { ApplianceFileDetailsVM } from '@/_models/appliance-common/appliance-file-details-vm';

export class LoadAllMerlinAppliancesAction implements Action {
    readonly type = '[MerlinManagement] In State Load All Merlin appliances action';

    constructor(public readonly appliances: MerlinApplianceVm[]) { }
}

export class LoadApplianceJobsAction implements Action {
    readonly type = '[MerlinManagement] In State Load Appliance Jobs action';

    constructor(public readonly jobs?: ApplianceJobVM[]) { }
}

export class LoadApplianceServicesAction implements Action {
    readonly type = '[MerlinManagement] InS tate Load Appliance Services action';

    constructor(public readonly services?: ApplianceServiceVM[]) { }
}

export class LoadInstanceTransferFilesAction implements Action {
    readonly type = '[TransferManagement] InState Load Transfer Files action';

    constructor(public readonly files?: ApplianceFileVM[]) { }
}

export class LoadInstanceTransferFileDetailsAction implements Action {
    readonly type = '[TransferManagement] InState Load Transfer File Details action';

    constructor(public readonly fileDetails: ApplianceFileDetailsVM[]) { }
}

export class LoadApplianceRoutingTableAction implements Action {
    readonly type = '[MerlinManagement] In State Load Appliance Routing Table action';

    constructor(public readonly routingTable?: MerlinRoutingTableItemVm[]) { }
}

export class LoadApplianceAuthSettingsAction implements Action {
    readonly type = '[MerlinManagement] In State Load Appliance Auth Settings action';

    constructor(public readonly authSettings?: MerlinAuthSettingsConfigVm) { }
}

export class LoadApplianceRepoStatisticsAction implements Action {
    readonly type = '[MerlinManagement] In State Load Appliance Repo Statistics action';

    constructor(public readonly repoStatistics?: MerlinRepoStatisticsVm[]) { }
}

export class ClearMerlinManagementDataAction implements Action {
    public type = '[MerlinManagement] Clear Appliance Jobs and Services action';
}

//#region fetch

export class FetchAllMerlinAppliancesAction implements Action {
    public type = '[MerlinManagement] FetchAllMerlinAppliances action';
}
export class FetchAllMerlinAppliancesOkAction implements Action {
    public type = '[MerlinManagement] FetchAllMerlinAppliances Ok action';
}
export class FetchAllMerlinAppliancesFailedAction implements Action {
    public type = '[MerlinManagement] FetchAllMerlinAppliances Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class FetchApplianceJobsAction implements Action {
    public type = '[MerlinManagement] FetchApplianceJobs action';
    constructor(public readonly applianceId: number, public readonly instanceId: number) { }
}
export class FetchApplianceJobsOkAction implements Action {
    public type = '[MerlinManagement] FetchApplianceJobs Ok action';
}
export class FetchApplianceJobsFailedAction implements Action {
    public type = '[MerlinManagement] FetchApplianceJobs Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class FetchApplianceServicesAction implements Action {
    public type = '[MerlinManagement] FetchApplianceServices action';
    constructor(public readonly applianceId: number) { }
}
export class FetchApplianceServicesOkAction implements Action {
    public type = '[MerlinManagement] FetchApplianceServices Ok action';
}
export class FetchApplianceServicesFailedAction implements Action {
    public type = '[MerlinManagement] FetchApplianceServices Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class FetchInstanceTransferFilesAction implements Action {
    public type = '[TransferManagement] FetchInstanceTransferFiles action';
    constructor(public readonly applianceId: number, public readonly instanceId: number) { }
}
export class FetchInstanceTransferFilesOkAction implements Action {
    public type = '[TransferManagement] FetchInstanceTransferFiles Ok action';
}
export class FetchInstanceTransferFilesFailedAction implements Action {
    public type = '[TransferManagement] FetchInstanceTransferFiles Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class FetchInstanceTransferFileDetailsAction implements Action {
    public type = '[TransferManagement] FetchInstanceTransferFileDetails action';
    constructor(public readonly applianceId: number, public readonly instanceId: number, public readonly fileNames: string[]) { }
}
export class FetchInstanceTransferFileDetailsOkAction implements Action {
    public type = '[TransferManagement] FetchInstanceTransferFileDetails Ok action';
}
export class FetchInstanceTransferFileDetailsFailedAction implements Action {
    public type = '[TransferManagement] FetchInstanceTransferFileDetails Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class FetchApplianceRoutingTableAction implements Action {
    public type = '[MerlinManagement] FetchApplianceRoutingTable action';
    constructor(public readonly applianceId: number) { }
}
export class FetchApplianceRoutingTableOkAction implements Action {
    public type = '[MerlinManagement] FetchApplianceRoutingTable Ok action';
}
export class FetchApplianceRoutingTableFailedAction implements Action {
    public type = '[MerlinManagement] FetchApplianceRoutingTable Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class FetchApplianceAuthSettingsAction implements Action {
    public type = '[MerlinManagement] FetchApplianceAuthSettings action';
    constructor(public readonly applianceId: number, public readonly poolName: string) { }
}
export class FetchApplianceAuthSettingsOkAction implements Action {
    public type = '[MerlinManagement] FetchApplianceAuthSettings Ok action';
}
export class FetchApplianceAuthSettingsFailedAction implements Action {
    public type = '[MerlinManagement] FetchApplianceAuthSettings Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class UpdateApplianceAuthSettingsAction implements Action {
    public type = '[MerlinManagement] UpdateApplianceAuthSettings action';
    constructor(
        public readonly applianceId: number,
        public readonly poolName: string,
        public readonly updateAuthSettingsModel: MerlinUpdateAuthSettingsConfigVm) { }
}
export class UpdateApplianceAuthSettingsOkAction implements Action {
    public type = '[MerlinManagement] UpdateApplianceAuthSettings Ok action';
}
export class UpdateApplianceAuthSettingsFailedAction implements Action {
    public type = '[MerlinManagement] UpdateApplianceAuthSettings Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class FetchApplianceRepoStatisticsAction implements Action {
    public type = '[MerlinManagement] FetchApplianceRepoStatistics action';
    constructor(public readonly applianceId: number, public readonly instanceId: number) { }
}
export class FetchApplianceRepoStatisticsOkAction implements Action {
    public type = '[MerlinManagement] FetchApplianceRepoStatistics Ok action';
}
export class FetchApplianceRepoStatisticsFailedAction implements Action {
    public type = '[MerlinManagement] FetchApplianceRepoStatistics Failed action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion fetch

//#region Run service command

export class RunServiceCommandAction implements Action {
    public type = '[MerlinManagement] RunServiceCommand action';
    constructor(public readonly applianceId: number, public readonly serviceId: string, public readonly commandName: string) { }
}
export class RunServiceCommandOkAction implements Action {
    public type = '[MerlinManagement] RunServiceCommand Ok action';
}
export class RunServiceCommandFailedAction implements Action {
    public type = '[MerlinManagement] RunServiceCommand Failed action';
    constructor(public readonly error: Error | string | any) { }
}
export class UpdateInStateApplianceServiceAction implements Action {
    readonly type = '[MerlinManagement] InState Update Appliance Service action';

    constructor(public readonly service?: ApplianceServiceVM) { }
}

//#endregion Run service command

//#region Merlin release info
export class LoadMerlinReleaseInfoAction implements Action {
    readonly type = '[ApplianceManagement] InState Load Merlin Release Info action';
    constructor(public readonly merlinReleaseInfo: MerlinReleaseInfoVM) { }
}
export class FetchMerlinReleaseAction implements Action {
    public type = '[ApplianceManagement] FetchMerlinRelease action';
}
export class FetchMerlinReleaseOkAction implements Action {
    public type = '[ApplianceManagement] FetchMerlinRelease Ok action';
}
export class FetchMerlinReleaseFailedAction implements Action {
    public type = '[ApplianceManagement] FetchMerlinRelease Failed action';
    constructor(public readonly error: Error | string | any) { }
}
export class ApproveMerlinReleaseAction implements Action {
    public type = '[ApplianceManagement] ApproveMerlinRelease action';
    constructor(public readonly releaseId: number) { }
}
export class ApproveMerlinReleaseOkAction implements Action {
    public type = '[ApplianceManagement] ApproveMerlinRelease Ok action';
}
export class ApproveMerlinReleaseFailedAction implements Action {
    public type = '[ApplianceManagement] ApproveMerlinRelease Failed action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion Merlin release info