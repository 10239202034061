//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { AlertStateStatusName } from '../alert-state-status-name';
import { AlertStateStatus } from '@/_models';

export function getAlertStateName(state: AlertStateStatus): AlertStateStatusName {
    let text: AlertStateStatusName;

    switch (state) {
        case AlertStateStatus.Created: text = AlertStateStatusName.Created; break;
        case AlertStateStatus.Acknowledged:  text = AlertStateStatusName.Acknowledged; break;
        case AlertStateStatus.Resolved: text = AlertStateStatusName.Resolved; break;
        case AlertStateStatus.Closed:  text = AlertStateStatusName.Closed; break;
        default: {
            throw new Error('Unknown state: ' + state);
        }
    }

    return text;
}
