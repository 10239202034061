<div class="nav navbar-nav ml-auto">
  <div *ngIf="isLogged">
    <div class="app-title-centered text-nowrap">{{ user?.firstName }} {{ user?.lastName }}</div>
  </div>
  <div class="nav-item dropdown" dropdown placement="bottom right">

    <div class="nav-link dropdown-menu-button" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="(false)">
        <div *ngIf="isLogged; else notLogged">
            <i class="fas fa-user-circle fa-2x"></i>
        </div>
        <ng-template #notLogged>
          <i class="fas fa-info-circle fa-2x"></i>
        </ng-template>
    </div>

    <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
      <div *ngIf="isLogged; else notLogged">
        <div class="dropdown-header dropdown-header-logged-in" (click)="$event.stopPropagation()">{{getRoles()}}</div>
      </div>
      <ng-template #notLogged>
        <div class="dropdown-header dropdown-header-logged-out" (click)="$event.stopPropagation()">Information</div>
      </ng-template>

      <a class="dropdown-item pointer" href="{{releaseNotesLink}}" target="_blank"><i class="fas fa-sticky-note"></i>Release Notes</a>
      <a class="dropdown-item pointer" href="{{userGuide}}" target="_blank"><i class="fas fa-book"></i>User Guide</a>
      <a *ngIf="operatorDocumentationLink$ | async as opDoc" class="dropdown-item pointer" href="{{opDoc}}" target="_blank"><i class="fas fa-book"></i>Operator Documentation</a>
      <a class="dropdown-item pointer" href="{{helpDesk}}" target="_blank"><i class="fas fa-envelope"></i>Help Desk</a>

      <div *ngIf="isLogged">
        <div id="dropdown-logout" class="dropdown-item pointer" (click)="onLogout()"><i class="fas fa-sign-out-alt"></i>Logout</div>
      </div>
    </div>
  </div>
</div>