//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { Action } from '@ngrx/store';

import {
    SimpleSection, KpiOverride, KpiIssue, IssueReasonCode, KpiProblemTimeAggregationPoint,
    SectionWithRuleInstances, KpiRange, KpiSummary, KPIExportDetails, SimpleTarget,
} from '@/_models';


//#region section list
export class LoadInStateSectionListAction implements Action {
    public type = '[KPI] Load in state section list action';
    constructor(public readonly sectionList?: SimpleSection[]) { }
}
export class ClearSectionListAction implements Action {
    public type = '[KPI] Clear in state section list action';
}
//#region fetch section list

export class FetchSectionListAction implements Action {
    public type = '[KPI] Fetch section list action';
    constructor(public readonly moreThanThirtyDays?: boolean) { }
}
export class FetchOkSectionListAction implements Action {
    public type = '[KPI] Fetch OK section list action';
}
export class FetchFailedSectionListAction implements Action {
    public type = '[KPI] Fetch Failed section list action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion fetch section list


//#region fetch section targets

export class LoadInStateSectionTargetsAction implements Action {
    public type = '[KPI] Load in state section target action';
    constructor(public readonly sectionTargets?: SimpleTarget[]) { }
}
export class FetchSectionTargetsAction implements Action {
    public type = '[KPI] Fetch section targets action';
    constructor(public readonly sectionId: number) { }
}
export class FetchOkSectionTargetsAction implements Action {
    public type = '[KPI] Fetch OK section targets action';
}
export class FetchFailedSectionTargetsAction implements Action {
    public type = '[KPI] Fetch Failed section targets action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion fetch section list

export class LoadInStateSectionExportListAction implements Action {
    public type = '[KPI] Load in state Export Section list action';
    constructor(public readonly sectionList?: KPIExportDetails[]) { }
}

//#region async Fetch ExportSection

export class FetchExportSectionAction implements Action {
    public type = '[KPI] Fetch ExportSection action';
}
export class FetchOkExportSectionAction implements Action {
    public type = '[KPI] Fetch OK ExportSection action';
}
export class FetchFailedExportSectionAction implements Action {
    public type = '[KPI] Fetch Fail ExportSection action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async Fetch ExportSection

//#endregion section list

//#region overrides
export class LoadInStateOverridesAction implements Action {
    public type = '[KPI] Load in state overrides action';
    constructor(public readonly overrides?: KpiOverride[]) { }
}
export class ClearOverridesAction implements Action {
    public type = '[KPI] Clear in state overrides action';
}
//#region overrides fetch
export class FetchOverridesAction implements Action {
    public type = '[KPI] Fetch overrides action';
    constructor(public readonly sectionId: number, public readonly targetId: number) { }
}
export class FetchOkOverridesAction implements Action {
    public type = '[KPI] Fetch OK overrides action';
}
export class FetchFailedOverridesAction implements Action {
    public type = '[KPI] Fetch Failed overrides action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion overrides fetch
//#region overrides create
export class AddInStateOverrideAction implements Action {
    public type = '[KPI] Add in state override action';
    constructor(public readonly override: KpiOverride) { }
}
export class CreateOverrideAction implements Action {
    public type = '[KPI] Create override action';
    constructor(public readonly override: KpiOverride) { }
}
export class CreateOkOverrideAction implements Action {
    public type = '[KPI] Create OK override action';
}
export class CreateFailedOverrideAction implements Action {
    public type = '[KPI] Create Failed override action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion overrides create
//#region overrides update
export class UpdateInStateOverrideAction implements Action {
    public type = '[KPI] Update in state override action';
    constructor(public readonly override: KpiOverride) { }
}
export class UpdateOverrideAction implements Action {
    public type = '[KPI] Update override action';
    constructor(public readonly override: KpiOverride) { }
}
export class UpdateOkOverrideAction implements Action {
    public type = '[KPI] Update OK override action';
}
export class UpdateFailedOverrideAction implements Action {
    public type = '[KPI] Update Failed override action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion overrides update
//#region overrides delete
export class DeleteInStateOverrideAction implements Action {
    public type = '[KPI] Delete in state override action';
    constructor(public readonly id: number) { }
}
export class DeleteOverrideAction implements Action {
    public type = '[KPI] Delete override action';
    constructor(public readonly id: number) { }
}
export class DeleteOkOverrideAction implements Action {
    public type = '[KPI] Delete OK override action';
}
export class DeleteFailedOverrideAction implements Action {
    public type = '[KPI] Delete Failed override action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion overrides delete
//#endregion overrides
//#region issues
//#region issue problem time flag
export class UpdateInStateIssuesProblemTimeFlagAction implements Action {
    public type = '[KPI] Update in state pagedIssues action';
    constructor(public readonly issueIds: number[], public readonly isProblemTime: boolean) { }
}
export class UpdateIssuesProblemTimeFlagAction implements Action {
    public type = '[KPI] Update issue problem time flag action';
    constructor(public readonly issueIds: number[], public readonly isProblemTime: boolean) { }
}
export class UpdateIssuesProblemTimeFlagOkAction implements Action {
    public type = '[KPI] Update issue problem time flag OK action';
}
export class UpdateIssuesProblemTimeFlagFailedAction implements Action {
    public type = '[KPI] Update issue problem time flag Failed action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion issue problem time flag
//#region issue problem time flag ALL
export class UpdateInStateAllIssuesProblemTimeFlagAction implements Action {
    public type = '[KPI] Update in state All issues action';
    constructor(public readonly isProblemTime: boolean) { }
}
export class UpdateAllIssuesProblemTimeFlagAction implements Action {
    public type = '[KPI] Update all issue problem time flag action';
    constructor(
        public readonly sectionId: number,
        public readonly targetId: number,
        public readonly isProblemTime: boolean
    ) { }
}
export class UpdateAllIssuesProblemTimeFlagOkAction implements Action {
    public type = '[KPI] Update all issue problem time flag OK action';
}
export class UpdateAllIssuesProblemTimeFlagFailedAction implements Action {
    public type = '[KPI] Update all issue problem time flag Failed action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion issue problem time flag ALL
//#region issue batch
export class InsertInStateKpiIssueBatchAction implements Action {
    public type = '[KPI] Insert in state kpi issue batch action';
    constructor(public readonly batch?: KpiIssue[]) { }
}

export class ClearIssueBatchAction implements Action {
    public type = '[KPI] Clear issue batch action';
}

export class ClearAllIssuesAction implements Action {
    public type = '[KPI] Clear all issue action';
}

export class FetchKpiIssueBatchAction implements Action {
    public type = '[KPI] Fetch kpi issue batch action';
    constructor(public readonly sectionId: number, public readonly targetId: number, public readonly batchSize = 200) { }
}
export class StartFetchKpiIssueBatchAction implements Action {
    public type = '[KPI] Start Fetch kpi issue batch action';
}

export class FinishFetchKpiIssueBatchAction implements Action {
    public type = '[KPI] Finish Fetch kpi issue batch action';
}

export class CancelFetchKpiIssueBatchAction implements Action {
    public type = '[KPI] Cancel Fetch kpi issue batch action';
}

export class ContinueFetchKpiIssueBatchAction implements Action {
    public type = '[KPI] Continue Fetch kpi issue batch action';
    constructor(
        public readonly sectionId: number,
        public readonly targetId: number,
        public readonly skip,
        public readonly batchSize
    ) { }
}

export class FetchKpiIssueBatchFailAction implements Action {
    public type = '[KPI] Fetch kpi issue batch fail action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion issue batch
//#endregion issues

//#region reason code
export class LoadInStateIssueReasonCodesAction implements Action {
    public type = '[KPI] Load in state issue reason codes action';
    constructor(public readonly reasonCodes: IssueReasonCode[]) { }
}

export class FetchIssueReasonCodesAction implements Action {
    public type = '[KPI] Fetch issue reason codes action';
}

export class FetchOkIssueReasonCodesAction implements Action {
    public type = '[KPI] Fetch OK issue reason codes action';
}

export class FetchFailedIssueReasonCodesAction implements Action {
    public type = '[KPI] Fetch Failed issue reason codes action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion reason code

//#region chart
export class ClearInStateKpiChartDataAction implements Action {
    public type = '[KPI] Clear in state kpi chart data action';
}

export class LoadInStateKpiChartDataAction implements Action {
    public type = '[KPI] Load in state kpi chart data action';
    constructor(public readonly chartData?: KpiProblemTimeAggregationPoint[]) { }
}

export class FetchKpiChartDataAction implements Action {
    public type = '[KPI] Fetch kpi chart data action';
    constructor(public readonly sectionId: number) { }
}

export class FetchOkKpiChartDataAction implements Action {
    public type = '[KPI] Fetch OK kpi chart data action';
}

export class FetchFailedKpiChartDataAction implements Action {
    public type = '[KPI] Fetch Failed kpi chart data action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion chart

//#region section data
//#region get section data
export class LoadInStateSectionWithRuleInstancesAction implements Action {
    public type = '[KPI] Load in state section with rule instances action';
    constructor(public readonly section?: SectionWithRuleInstances) { }
}

export class ClearSectionWithRuleInstancesAction implements Action {
    public type = '[KPI] Clear in state section with rule instances action';
}

export class FetchSectionWithRuleInstancesAction implements Action {
    public type = '[KPI] Fetch section with rule instances action';
    constructor(public readonly sectionId: number, public readonly targetId: number) { }
}

export class FetchOkSectionWithRuleInstancesAction implements Action {
    public type = '[KPI] Fetch OK section with rule instances action';
}

export class FetchFailedSectionWithRuleInstancesAction implements Action {
    public type = '[KPI] Fetch Failed section with rule instances action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion get section data

//#region update time range
export class UpdateInStateSectionTimeRangeAction implements Action {
    public type = '[KPI] Update in state section time ranges action';
    constructor(public readonly range: KpiRange) { }
}

export class UpdateSectionTimeRangeAction implements Action {
    public type = '[KPI] Update section time range action';
    constructor(public readonly range: KpiRange) { }
}

export class UpdateOkSectionTimeRangeAction implements Action {
    public type = '[KPI] Update OK section time range action';
}

export class UpdateFailedSectionTimeRangeAction implements Action {
    public type = '[KPI] Update Failed section time range action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion update time range

//#region create time range
export class InsertInStateSectionTimeRangeAction implements Action {
    public type = '[KPI] Insert in state SectionTimeRange action';
    constructor(public readonly range: KpiRange) { }
}

export class CreateSectionTimeRangeAction implements Action {
    public type = '[KPI] Create SectionTimeRange action';
    constructor(public readonly sectionId: number, public readonly targetId: number, public readonly range: KpiRange) { }
}

export class CreateOkSectionTimeRangeAction implements Action {
    public type = '[KPI] Create OK SectionTimeRange action';
}

export class CreateFailedSectionTimeRangeAction implements Action {
    public type = '[KPI] Create Failed SectionTimeRange action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion create time range

//#region delete time range
export class DeleteInStateSectionTimeRangeAction implements Action {
    public type = '[KPI] Delete in state SectionTimeRange action';
    constructor(public readonly range: KpiRange) { }
}

export class DeleteSectionTimeRangeAction implements Action {
    public type = '[KPI] Delete SectionTimeRange action';
    constructor(public readonly range: KpiRange) { }
}

export class DeleteOkSectionTimeRangeAction implements Action {
    public type = '[KPI] Delete OK SectionTimeRange action';
}

export class DeleteFailedSectionTimeRangeAction implements Action {
    public type = '[KPI] Delete Failed SectionTimeRange action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion delete time range

//#region get exportSummary
export class ClearInStateKpiSummaryAction implements Action {
    public type = '[KPI] Clear in state kpi exportSummary action';
}

export class LoadInStateKpiExportSummaryAction implements Action {
    public type = '[KPI] Load in state kpi exportSummary action';
    constructor(public readonly data?: KpiSummary[]) { }
}

export class FetchKpiSummaryAction implements Action {
    public type = '[KPI] Fetch kpi exportSummary action';
    constructor(public readonly sectionId: number, public readonly targetId: number) { }
}

export class FetchOkKpiSummaryAction implements Action {
    public type = '[KPI] Fetch OK kpi exportSummary action';
}

export class FetchFailedKpiSummaryAction implements Action {
    public type = '[KPI] Fetch Failed kpi exportSummary action';
    constructor(public readonly error: Error | string | any) { }
}
//#endregion get exportSummary

//#region async KpiExport

export class UpdateInStateKpiExportFlagAction implements Action {
    public type = '[KPI] Update in state KpiExport flag action';
    constructor(public readonly sectionId: number, public readonly targetId: number, public readonly hasExport = true) { }
}

export class UpdateInStateKpiFinalizeAction implements Action {
    public type = '[KPI] Update in state KpiExport finalize action';
    constructor(public readonly sectionId: number, public readonly targetId: number, public readonly finalized = true) { }
}

export class StartKpiExportAction implements Action {
    public type = '[KPI] Start KpiExport action';
    constructor(public readonly sectionId: number, public readonly targetId: number, public readonly generatePartialResults = false) { }
}

export class FinalizeKpiExportAction implements Action {
    public type = '[KPI] Finalize KpiExport action';
    constructor(public readonly sectionId: number, public readonly targetId: number) { }
}

export class StartOkKpiExportAction implements Action {
    public type = '[KPI] Start OK KpiExport action';
}

export class FinalizeOkKpiExportAction implements Action {
    public type = '[KPI] Finalize OK KpiExport action';
}

export class StartFailedKpiExportAction implements Action {
    public type = '[KPI] Start Fail KpiExport action';
    constructor(public readonly error: Error | string | any) { }
}

export class FinalizeFailedKpiExportAction implements Action {
    public type = '[KPI] Finalize Fail KpiExport action';
    constructor(public readonly error: Error | string | any) { }
}

export class CheckKpiExportStatusAction implements Action {
    public type = '[KPI] Check Kpi export status action';
    constructor(public readonly sectionId: number, public readonly targetId: number) { }
}

export class UpdateKpiExportStatusAction implements Action {
    public type = '[KPI] Update Kpi export status action';
    constructor(public readonly status: string, public readonly reason: string, public readonly progress: number) { }
}

export class CheckOkKpiExportStatusAction implements Action {
    public type = '[KPI] Check OK KpiExport status action';
}

export class CheckFailedKpiExportStatusAction implements Action {
    public type = '[KPI] Check Fail KpiExport status action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion async KpiExport

//#endregion section data
