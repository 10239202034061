import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserDropdownComponent } from './user-dropdown.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';

@NgModule({
  declarations: [UserDropdownComponent],
  exports: [UserDropdownComponent],
  imports: [
    CommonModule, BsDropdownModule
  ]
})
export class UserDropdownModule { }
