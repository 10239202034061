// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Component } from '@angular/core';
import { AppStore } from '@state';

@Component({
    selector: 'app-post-logout',
    template: `
    <div class="image-container">
      <img *ngIf="logo$ | async" class="logo-image" [src]="logo$ | async"/>
    </div>
    <div id="panel" class="card p-4">
    <div class="card-body">
      <h1>Logout</h1>
      <p class="text-muted">You have been logged out successfully. Follow this <a href="/">link</a> to log in back again.</p>
    </div>
  </div>
  `,
    styles: [`
  :host {
      display: block;
      position: relative;
      margin: -16px;
      overflow: hidden;
      height: calc(100vh - 55px - 50px);
  }
  #panel {
    display: inline-block;
    width: 425px;
    height: 250px;
    position: absolute;
    left: calc((100vw - 450px) / 2);
    top: calc((100vh - 250px) / 2 - 50px);
  }
  .image-container {
    display: flex;
    position: absolute;
    width: 100%;
    height: 100%;
    justify-content: center;
  }
  .logo-image {
    width: calc(100vw);
    height: 100%;
    align-self: center;
    object-fit: cover;
  }
  `]
})
export class PostLogoutComponent {

  public logo$ = this.store.select('adminState', 'logoImage');

  constructor(readonly store: AppStore) { }

}
