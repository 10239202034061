// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { PublicPart } from 'ngx-shared';
import { User, Actions } from '@/_models';
import { Routes, routeAuthorization } from '@/configuration';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from './user.service';
import { MsalBroadcastService } from '@azure/msal-angular';
import { filter, map } from 'rxjs/operators';
import { EventMessage, EventType, InteractionType } from '@azure/msal-browser';

@Injectable({ providedIn: 'root' })
export class CurrentUserService {
    private _currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private userService: UserService,
        private msalBroadcastService: MsalBroadcastService) {
            this._currentUserSubject = new BehaviorSubject<User>(null);
            this.currentUser = this._currentUserSubject.asObservable();

            this.msalBroadcastService.msalSubject$
            .pipe(
                filter((msg: EventMessage) => (msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS)
                        && msg.interactionType != InteractionType.Silent),
            )
            .subscribe(() => {
                this.EnsureCurrentUser()?.subscribe();
            });
     }

    public get user() {
       return this._currentUserSubject.value;
    }

    public EnsureCurrentUser() {
        if (this.user == null) {
            return this.userService.getCurrent().pipe(map(b => {
                this._currentUserSubject.next(b);
            }));
        }
    }

    public get isProcessOrderSectionsAllowed() {
        return isRouteAllowed(this.user, Routes.monitor) &&
               this.user.actions.some(x => x.name === Actions.ProcessOrderSection);
    }
    public get isAlertsRouteAllowed() {
        return isRouteAllowed(this.user, Routes.alerts);
    }
    public get isOrdersRouteAllowed() {
        return isRouteAllowed(this.user, Routes.orderManagement);
    }
    public get isRulesRouteAllowed() {
        return isRouteAllowed(this.user, Routes.rules);
    }
    public get isChangeRuleInstanceStateAllowed() {
        if (!isValidUserWithActions(this.user)) {
            return false;
        }

        return this.user.actions.some(x => x.name === Actions.ChangeRuleInstanceState);
    }
    public get hasAnyUpdateOrderRights() {
        if (!isValidUserWithActions(this.user)) {
            return false;
        }

        return this.hasUpdateOwnSectionRights ||
            this.user.actions.some(x => x.name === Actions.OrderManagement);
    }
    public get hasUpdateOwnSectionRights() {
        if (!isValidUserWithActions(this.user)) {
            return false;
        }

        return this.user.actions.some(x => x.name === Actions.UpdateOwnSectionCurves);
    }
    public get hasEditDapDocumentPermissions() {
        return isValidUserWithActions(this.user) && this.user.actions.some(x => x.name === Actions.DapDocumentEdit);
    }
}

function isRouteAllowed(user: User, route: Routes) {
    if (!isValidUserWithActions(user)) {
        return false;
    }

    const actionsForRoute = routeAuthorization.get(route);
    return Array.isArray(actionsForRoute)
        ? user.actions.some(x => actionsForRoute.some(y => y === x.name))
        : true;
}

function isValidUserWithActions(user: User): boolean {
    return user && Array.isArray(user.actions);
}

export class FakeCurrentUserService implements PublicPart<CurrentUserService> {
    public currentUser: Observable<User> = new BehaviorSubject<User>(null);
    public user: User;
    public isProcessOrderSectionsAllowed: boolean;
    public isAlertsRouteAllowed: boolean;
    public isOrdersRouteAllowed: boolean;
    public isRulesRouteAllowed: boolean;
    public isChangeRuleInstanceStateAllowed: boolean;
    public hasAnyUpdateOrderRights: boolean;
    public hasUpdateOwnSectionRights: boolean;
    public hasEditDapDocumentPermissions: boolean;

    public EnsureCurrentUser(): Observable<void> {
        throw new Error('Method not implemented.');
    }
}

export const fakeCurrentUserServiceProvider = { provide: CurrentUserService, useClass: FakeCurrentUserService };
