// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Measure } from '@/_models';
import { vsprintf } from 'sprintf-js';
import { DapDocumentVM } from '../vm';
import { DapTemplateContentColumn } from '@/_models/dap-document-details';
import { ValidationErrors } from '@angular/forms';

export class TemplateFunctions {

    constructor(private readonly docVm: DapDocumentVM,
        private readonly sectionIndex: number,
        private readonly dapIndex: number) {}

    // The params should be the following:
    // 0 - the formatted string
    // 1 - default string to return if any of the param values (after this one in the array) are null
    // 2+ - the param values to use in the formatted string
    public sprintf(params: any[]) {
        try {
            const formatValues = params.slice(2);
            if (formatValues.some(x => x == null)) {
                return params[1];
            }

            return vsprintf(params[0], formatValues);
        } catch (err) {
            return `Error: ${err.message}`;
        }
    }

    public depthRange(params: Measure[]) {
        const start = params[0];
        const end = params[1];
        if (start == null || end == null || start.value == null || end.value == null) { return null; }

        return new Measure(end.value - start.value, start.uom);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public dapId(params: any[]) {
        return this.docVm.sectionVMs[this.sectionIndex]?.dapVMs[this.dapIndex]?.dapRefNum;
    }

    public toHundred(params: any[]) {
        if (!params[0] && params[0] !== 0) { return null; }
        const num = Number(params[0]);
        if (isNaN(num)) { return null; }
        return 100 - Math.min(100, Math.max(0, num));
    }

    // column validators: (col: DapTemplateContentColumn, allValues: any[], params: any[]) => ValidationErrors | null

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public checkSumIsHundred(col: DapTemplateContentColumn, allValues: any[], params: any[]): ValidationErrors | null {
        const values = allValues.filter(v => !!v);
        const sum = values.reduce((s, a) => s + a, 0);
        if (Math.abs(sum - 100) <= 0.01) {
            return null;
        }
        const error = {};
        error[`${col.name}`] = `The sum of all values in the column is ${sum}. It should be 100.`;
        return error;
    }
}
