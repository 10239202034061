//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { AlertStateStatusName } from '../alert-state-status-name';
import { getAlertState } from './get-alert-state';

export function stateNameComparator(stateName1: AlertStateStatusName, stateName2: AlertStateStatusName): number {
    const state1 = getAlertState(stateName1);
    const state2 = getAlertState(stateName2);

    if (state1 === null && state2 === null) {
        return 0;
    }
    if (state1 === null) {
        return -1;
    }
    if (state2 === null) {
        return 1;
    }
    return state1 - state2;
}
