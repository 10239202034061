//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

export class RuleManagementRuleInstance {
    constructor(
        public ruleInstanceId: number,
        public ruleImplementationId: number,
        public subjectType: string,
        public subjectName: string,
        public ruleName: string,
        public serviceProvider: string,
        public service: string,
        public tool: string,
        public indexType: string,
        public isMemory: boolean,
        public lagType: string,
        public uom: string,
        public orderId: number,
        public target: string,
        public overrides: number,
        public overriddenTargets: number[],
    ) {}
}

export class RuleManagementRuleInstanceData {
    constructor(
        public items: RuleManagementRuleInstance[],
        public areTooMany: boolean,
        public maxCount: number,
    ) {}
}

export enum CopyOverridesMode {
    ActiveSection = 1,
    Order = 2,
    Global = 3
}
