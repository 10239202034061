// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Action, Store } from '../ngrx-actions';
import {
    LoadAllMerlinAppliancesAction, LoadApplianceAuthSettingsAction, LoadApplianceJobsAction, LoadApplianceRepoStatisticsAction, LoadApplianceRoutingTableAction, LoadApplianceServicesAction, LoadInstanceTransferFileDetailsAction, LoadInstanceTransferFilesAction, LoadMerlinReleaseInfoAction, UpdateInStateApplianceServiceAction
} from './merlin-management.actions';
import { MerlinManagementState } from './merlin-management.state';

@Store({} as MerlinManagementState)
export class MerlinManagementStore {

    @Action(LoadAllMerlinAppliancesAction)
    public loadAppliances(state: MerlinManagementState, { appliances }: LoadAllMerlinAppliancesAction) {
        return { ...state, appliances } as MerlinManagementState;
    }

    @Action(LoadApplianceJobsAction)
    public loadJobs(state: MerlinManagementState, { jobs }: LoadApplianceJobsAction) {
        return { ...state, jobs } as MerlinManagementState;
    }

    @Action(LoadApplianceServicesAction)
    public loadServices(state: MerlinManagementState, { services }: LoadApplianceServicesAction) {
        return { ...state, services } as MerlinManagementState;
    }
    
    @Action(LoadInstanceTransferFilesAction)
    public loadInstanceTransferFiles(state: MerlinManagementState, { files }: LoadInstanceTransferFilesAction) {
        return { ...state, files } as MerlinManagementState;
    }
    
    @Action(LoadInstanceTransferFileDetailsAction)
    public loadInstanceTransferFileDetails(state: MerlinManagementState, { fileDetails }: LoadInstanceTransferFileDetailsAction) {
        return { ...state, fileDetails } as MerlinManagementState;
    }

    @Action(LoadApplianceRoutingTableAction)
    public loadRoutingTable(state: MerlinManagementState, { routingTable }: LoadApplianceRoutingTableAction) {
        return { ...state, routingTable } as MerlinManagementState;
    }
    
    @Action(LoadApplianceAuthSettingsAction)
    public loadAuthSettings(state: MerlinManagementState, { authSettings }: LoadApplianceAuthSettingsAction) {
        return { ...state, authSettings } as MerlinManagementState;
    }

    @Action(LoadApplianceRepoStatisticsAction)
    public loadRepoStatistics(state: MerlinManagementState, { repoStatistics }: LoadApplianceRepoStatisticsAction) {
        return { ...state, repoStatistics } as MerlinManagementState;
    }

    @Action(UpdateInStateApplianceServiceAction)
    public updateService(state: MerlinManagementState, { service }: UpdateInStateApplianceServiceAction) {
        if (state.services != null) {
            const index = state.services.findIndex(x => x.id === service.id);
            if (index >= 0) {
                const newServices = [...state.services];
                newServices[index] = { ...service };
                return { ...state, services: newServices } as MerlinManagementState;
            }
        }
    }
    
    @Action(LoadMerlinReleaseInfoAction)
    public loadMerlinReleaseInfo(state: MerlinManagementState, { merlinReleaseInfo }: LoadMerlinReleaseInfoAction) {
        return { ...state, merlinReleaseInfo } as MerlinManagementState;
    }
}
