import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';


import { environment } from '../../environments/environment';

import * as AppState from './app-state';
import { NgrxActionsModule } from './ngrx-actions/ngrx-actions.module';

@NgModule({
    imports: [
        /**
         * StoreModule.forRoot is imported once in the root module, accepting a reducer
         * function or object map of reducer functions. If passed an object of
         * reducers, combineReducers will be run creating your application
         * meta-reducer. This returns all providers for an @ngrx/store
         * based application.
         */
        // add regular ngrx reducers
        StoreModule.forRoot(AppState.reducers, { metaReducers: AppState.metaReducers }),

        // add reducers using ngrx-actions decorations
        NgrxActionsModule.forRoot(AppState.actionStoreMap),

        /**
         * Store devtools instrument the store retaining past versions of state
         * and recalculating new states. This enables powerful time-travel
         * debugging.
         *
         * To use the debugger, install the Redux Devtools extension for either
         * Chrome or Firefox
         *
         * See: https://github.com/zalmoxisus/redux-devtools-extension
         */
        !environment.production ? StoreDevtoolsModule.instrument({
            name: 'NgRx Store DevTools',
            maxAge: 50, // Retains last 50 states
            logOnly: environment.production // Restrict extension to log-only mode
        }) : [],

        EffectsModule.forRoot(AppState.rootEffects)
    ],
    exports: [StoreModule],
    providers: [
        AppState.AppStore,
        ...AppState.selectorProviders,
    ]
})
export class AppStateModule { }
