//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { Injectable, Inject } from '@angular/core';
import { createEffect, Actions } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { of, Observable } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { NgxAlertService, getMessageFromError } from 'ngx-shared';
import { NGXLogger } from 'ngx-logger';
import { AlertService } from '@/_services';
import { AlertDetails, AlertStateStatus } from '@/_models';

import {
    FetchAlertOverviewsAction,
    FetchOkAlertOverviewsAction,
    LoadInStateAlertOverviewsAction,
    FetchFailedAlertOverviewsAction,
    FetchAlertDetailsAction,
    FetchOkAlertDetailsAction,
    LoadInStateAlertDetailsAction,
    FetchFailedAlertDetailsAction,
    ClearInStateAlertDetailsAction,
    UpdateAlertIssuesProblemTimeAction,
    UpdateOkAlertIssuesProblemTimeAction,
    UpdateFailedAlertIssuesProblemTimeAction,
    UpdateInStateAlertIssuesProblemTimeAction,
    FetchAlertFilterListsAction,
    LoadInStateAlertFilterListsAction,
    FetchOkAlertFilterListsAction,
    FetchFailedAlertFilterListsAction,
    UpdateAlertStateStatusAction,
    UpdateInStateAlertStateStatusAction,
    UpdateOkAlertStateStatusAction,
    UpdateFailedAlertStateStatusAction,
    AlertStateTransitionValidationFailAction,
} from './alert.actions';
import { ALERT_DETAILS_TOKEN } from '../tokens';

@Injectable()
export class AlertEffects {
    
    public onServerFetchAlertOverviews$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchAlertOverviewsAction),
        switchMap(x => this.alertService.getAlertOverviews(x.sectionId, x.targetId).pipe(
            switchMap(y => [new LoadInStateAlertOverviewsAction(y), new FetchOkAlertOverviewsAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch AlertOverviews', error);
                this.ngxAlertService.error(getMessageFromError(error));
                return of(new FetchFailedAlertOverviewsAction(error));
            })
        ))
    ));

    
    public onServerFetchAlertDetails$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchAlertDetailsAction),
        switchMap(x => this.alertService.getAlertDetails(x.alertId).pipe(
            switchMap(y => [new LoadInStateAlertDetailsAction(y), new FetchOkAlertDetailsAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch AlertDetails', error);
                this.ngxAlertService.error(getMessageFromError(error));
                return of(new FetchFailedAlertDetailsAction(error));
            })
        ))
    ));

    
    public onServerUpdateAlertIssuesProblemTime$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdateAlertIssuesProblemTimeAction),
        switchMap(x => this.alertService.updateIssuesProblemTime(x.issueIds, x.problemTime).pipe(
            switchMap(() => [
                new UpdateInStateAlertIssuesProblemTimeAction(x.alertId, x.issueIds, x.problemTime),
                new UpdateOkAlertIssuesProblemTimeAction()
            ]),
            catchError(error => {
                this.logger.error('Error while Update of alert issues', error);
                this.ngxAlertService.error(getMessageFromError(error));
                return of(new UpdateFailedAlertIssuesProblemTimeAction(error));
            })
        ))
    ));

    
    public onServerFetchAlertFilterLists$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchAlertFilterListsAction),
        switchMap(() => this.alertService.getFilterLists().pipe(
            switchMap(y => [new LoadInStateAlertFilterListsAction(y), new FetchOkAlertFilterListsAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch AlertFilterLists', error);
                this.ngxAlertService.error(getMessageFromError(error));
                return of(new FetchFailedAlertFilterListsAction(error));
            })
        ))
    ));

    
    public onServerUpdateAlertState$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdateAlertStateStatusAction),
        withLatestFrom(this.alertDetails$),
        switchMap(([x, alertDetails]) => {
            alertDetails = alertDetails == null ? new AlertDetails() : alertDetails;
            let result: Actions;

            if (isTransitionAllowed(alertDetails.state, x.state)) {
                const verifyResolved = alertDetails.state === AlertStateStatus.Resolved || alertDetails.state === AlertStateStatus.Closed;
                const newDetails = { ...alertDetails, state: x.state };
                result = this.alertService.updateState(x.id, x.state, x.comment, verifyResolved).pipe(
                    switchMap(() =>
                        [new UpdateInStateAlertStateStatusAction(newDetails), new UpdateOkAlertStateStatusAction()]),
                    catchError(error => {
                        this.logger.error('Could not Update Alert state', error);
                        this.ngxAlertService.error(getMessageFromError(error));
                        return of(new UpdateFailedAlertStateStatusAction(error));
                    }),
                );
            } else {
                const msg = `Alert state transition between (old: ${alertDetails.state}) and (new: ${x.state}) not allowed.`;
                this.logger.error(msg);
                this.ngxAlertService.error(msg);
                result = of(new AlertStateTransitionValidationFailAction(msg));
            }

            return result;
        })
    ));

    
    public onStateClearAlertDetails$ = createEffect(() => this.actions$.pipe(
        ofAction(ClearInStateAlertDetailsAction),
        switchMap(() => [new LoadInStateAlertDetailsAction(undefined)])
    ));

    
    public onServerOk$ = createEffect(() => this.actions$.pipe(
        ofAction(...[UpdateOkAlertIssuesProblemTimeAction, UpdateOkAlertStateStatusAction]),
        tap(() => this.ngxAlertService.success('Update completed.'))
    ), { dispatch: false });

    constructor(
        @Inject(ALERT_DETAILS_TOKEN) private readonly alertDetails$: Observable<AlertDetails>,
        private readonly actions$: Actions,
        private readonly ngxAlertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly alertService: AlertService,
    ) { }
}

function isTransitionAllowed(oldState: AlertStateStatus, newState: AlertStateStatus) {
    // reverse allowed transitions
    return !(
        (oldState === AlertStateStatus.Created && newState !== AlertStateStatus.Acknowledged)
        || (oldState === AlertStateStatus.Acknowledged && newState !== AlertStateStatus.Resolved)
        || (
            oldState === AlertStateStatus.Resolved
            && newState !== AlertStateStatus.Acknowledged && newState !== AlertStateStatus.Closed
        )
        || (oldState === AlertStateStatus.Closed && newState !== AlertStateStatus.Acknowledged)
    );
}
