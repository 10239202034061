// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS America LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//      http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { UntypedFormGroup } from '@angular/forms';

export class ConfirmPasswordValidator {
    static check(passwordKey: string, confirmPasswordKey: string) {
        return (group: UntypedFormGroup) => {
            const passwordInput = group.controls[passwordKey];
            const confirmPasswordInput = group.controls[confirmPasswordKey];
            if (this.isPasswordFieldDifferent(passwordInput.value, confirmPasswordInput.value)) {
                return confirmPasswordInput.setErrors({ notEquivalent: true });
            } else {
                return confirmPasswordInput.setErrors(null);
            }
        };
    }

    private static isPasswordFieldDifferent(password: string, confirmPassword: string) {
        // Note: this first check is required as a IE fix.
        // On user click in the field the IE automatically changes the null value to empty string.
        // This will fails the 2nd check.
        return !(!password && !confirmPassword)
            && password !== confirmPassword;
    }
}
