// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Action, Store } from '../ngrx-actions';
import {
    LoadDapBaseTemplatesAction, AddOrReplaceDapTemplateAction,
} from './dap-template-management.actions';
import { DapTemplateManagementState } from './dap-template-management.state';

@Store({} as DapTemplateManagementState)
export class DapTemplateManagementStore {

    @Action(LoadDapBaseTemplatesAction)
    public loadBaseTemplates(state: DapTemplateManagementState, { baseTemplates }: LoadDapBaseTemplatesAction) {
        return { ...state, baseTemplates } as DapTemplateManagementState;
    }

    @Action(AddOrReplaceDapTemplateAction)
    public addOrReplaceTemplate(state: DapTemplateManagementState, { template }: AddOrReplaceDapTemplateAction) {
        if (state.baseTemplates != null) {
            const newTemplates = [...state.baseTemplates];

            const index = state.baseTemplates.findIndex(x => x.name === template.name);
            if (index >= 0) {
                if (state.baseTemplates[index].version <= template.version) {
                    newTemplates[index] = { ...template };
                }
            } else {
                newTemplates.push(template);
            }

            return { ...state, baseTemplates: newTemplates } as DapTemplateManagementState;
        }
    }
}
