// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { DapTemplateContentGroup } from '@/_models/dap-document-details';
import { DapDispositionItem } from './dap-disposition-item';
import { DapDocumentVM } from './dap-document-vm';
import { DapFieldVM } from './dap-field-vm';
import { DapTableVM } from './dap-table-vm';

export class DapGroupVM extends DapDispositionItem {
    public templateContent: DapTemplateContentGroup;
    public controls: DapControlVM[];
    // backwards reference to the doc VM
    public docVM: DapDocumentVM;

    public getFieldVMs(): DapFieldVM[] {
        return this.controls
            .filter(x => x.field != null)
            .map(x => x.field);
    }

    public getTableVMs(): DapTableVM[] {
        return this.controls
            .filter(x => x.table != null)
            .map(x => x.table);
    }
}

export class DapControlVM {
    public ordinal: number;
    public field: DapFieldVM;
    public table: DapTableVM;
}
