// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { AppStore, FetchTooltipsAction } from '@state';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.css']
})
export class TooltipComponent implements OnInit {
    @Input() tooltipKey;
    @Input() fetchTooltipFromDb = false;
    @Input() placement = 'top';
    @Input() position = 'tooltip-icon-inside';
    /**
     * When you have some styles on a parent element that interfere with a tooltip, you’ll want to specify a container="body"
     * so that the tooltip’s HTML will be appended to body. This will help to avoid rendering problems
     * in more complex components (like our input groups, button groups, etc) or inside elements with overflow: hidden
     * @type {string}
     */
    @Input() container = 'body';

    public tooltipContentStyle = 'tooltip-custom-content';

    public tooltip$ = new Observable<string>();

    constructor(private el: ElementRef, private renderer: Renderer2, private readonly store: AppStore) {
        this.renderer.addClass(this.el.nativeElement, 'tooltip-component');
    }

    ngOnInit() {
        if (this.fetchTooltipFromDb) {
            this.store.dispatch(new FetchTooltipsAction(this.tooltipKey));
        }
        this.tooltip$ = this.store.select('tooltipState', 'tooltips').pipe(
            map(x => {
                const tooltipInfo  = Array.isArray(x) ? x.find(y => y.key === this.tooltipKey) : null;
                return tooltipInfo && tooltipInfo.text;
            })
        );
    }
}
