// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { DapDocumentStatus } from '@/_models/dap-common';
import { DapDocumentContact, DapTemplateContent } from '@/_models/dap-document-details';
import { DapDataContainerVM } from './dap-data-container-vm';
import { DapFieldVM } from './dap-field-vm';
import { DapSectionVM } from './dap-section-vm';
import { DapTableVM } from './dap-table-vm';

export class DapDocumentContextKeys {
    public static document = 'doc';
    public static section = 'sec';
    public static dap = 'dap';

    public static mapContextKey(key: string): string {
        switch(key) {
            case DapDocumentContextKeys.document:
                return 'document';
            case DapDocumentContextKeys.section:
                return 'section';
            case DapDocumentContextKeys.dap:
                return 'dap';
            default:
                return undefined;
        }
    }
}

export class DapDocumentVM extends DapDataContainerVM {
    public title: string;
    public status: DapDocumentStatus;
    public templateContent: DapTemplateContent;
    public sectionVMs: DapSectionVM[] = [];
    public contacts: DapDocumentContact[] = [];
    public businessState: string;
    public adGroups: string[] = [];

    public getTableVM(context: string, key: string, sectionIndex: number = null, dapIndex: number = null): DapTableVM {
        let foundVMs: DapTableVM[] = null;
        switch (context) {
            case DapDocumentContextKeys.document:
                foundVMs = this.getTableVMs();
                break;
            case DapDocumentContextKeys.section:
                foundVMs = this.sectionVMs[sectionIndex]?.getTableVMs();
                break;
            case DapDocumentContextKeys.dap:
                foundVMs = this.sectionVMs[sectionIndex]?.dapVMs[dapIndex]?.getTableVMs();
                break;
            default:
                foundVMs = null;
        }

        return foundVMs?.find(x => x.templateContent.key === key);
    }

    public getFieldVM(context: string, key: string, sectionIndex: number, dapIndex: number): DapFieldVM {
        let foundVMs: DapFieldVM[] = null;
        switch (context) {
            case DapDocumentContextKeys.document:
                foundVMs = this.getFieldVMs();
                break;
            case DapDocumentContextKeys.section:
                foundVMs = this.sectionVMs[sectionIndex]?.getFieldVMs();
                break;
            case DapDocumentContextKeys.dap:
                foundVMs = this.sectionVMs[sectionIndex]?.dapVMs[dapIndex]?.getFieldVMs();
                break;
            default:
                foundVMs = null;
        }

        return foundVMs?.find(x => x.templateContent.key === key);
    }
}
