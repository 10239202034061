//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------d
import { RefScalar } from '@/dap/document-details/eval/ref-scalar';
import { DapDocumentVM, DapTableRowValue } from '@/dap/document-details/vm';
import { PickListItem, PickListType } from '@/_models';

export function resolveContext(contexts: string[], docVM: DapDocumentVM, sectionIndex: number, dapIndex: number,
    rowValue: DapTableRowValue, picklistMap: Map<PickListType, PickListItem[]>): Map<string, any> {
    const dapContextMap: Map<string, any> = new Map<string, any>();

    if (!contexts) { return dapContextMap; }

    contexts.forEach(context => {
        context = context.trim();
        dapContextMap.set(context, RefScalar.getValue(context, docVM, sectionIndex, dapIndex, rowValue, picklistMap));
    });
    return dapContextMap;
}
