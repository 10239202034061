//  -----------------------------------------------------------------------
//  PDS DRQe
//
//  Copyright 2019 PDS Americas LLC
//
//  Licensed under the PDS Open Source WITSML Product License Agreement (the
//  "License"); you may not use this file except in compliance with the License.
//  You may obtain a copy of the License at
//
//      http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//  Unless required by applicable law or agreed to in writing, software
//  distributed under the License is distributed on an "AS IS" BASIS,
//  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//  See the License for the specific language governing permissions and
//  limitations under the License.
//  -----------------------------------------------------------------------

export enum AutoStartCondition {
    None = 0,
    Time = 1,
    Depth = 2,
    TimeOrDepth = 3,
}

export const autoStartConditionList: { id: number, name: string }[] = [
    { id: AutoStartCondition.None, name: 'None' },
    { id: AutoStartCondition.Time, name: 'Time' },
    { id: AutoStartCondition.Depth, name: 'Depth' },
    { id: AutoStartCondition.TimeOrDepth, name: 'Time or Depth' },
];

export enum AutoCompleteCondition {
    None = 0,
    Time = 1,
    Depth = 2,
    TimeOrDepth = 3,
    TimeAndDepth = 4,
}

export const autoCompleteConditionList: { id: number, name: string }[] = [
    { id: AutoCompleteCondition.None, name: 'None' },
    { id: AutoCompleteCondition.Time, name: 'Time' },
    { id: AutoCompleteCondition.Depth, name: 'Depth' },
    { id: AutoCompleteCondition.TimeOrDepth, name: 'Time or Depth' },
    { id: AutoCompleteCondition.TimeAndDepth, name: 'Both Time and Depth' },
];
