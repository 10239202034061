// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { IndicatorStatus } from '../indicator-status';

export class ConnectionStatuses {
    public targetId: number;
    public status: IndicatorStatus;
    public total: number;
    public valid: number;
}

export class SectionMonitorStatusItem {
    public id: string;

    public categoryService?: string;
    public categoryType?: string;
    public categorySubject?: string;
    public subjectType?: string;
    public subjectId?: string;
    public monitorId?: string;

    public text: string;
    public curveLabel: string;
    public hasChildren: boolean;
    public targetStatuses: ConnectionStatuses[];
    public children: SectionMonitorStatusItem[];
    public isTime: boolean;
    public isDepth: boolean;
    public isMonitor: boolean;
    public isCurve: boolean;
}

export class SectionMonitorStatus {
    public sectionId: number;
    public items: SectionMonitorStatusItem[];
}
