<div class="widget-wrapper">
    <!-- header widget -->
    <div *ngIf="headerWidgetData" class="widget-header">
        <div class="row ml-0 mr-0">
            <div [ngClass]="getWidgetSizeClass(headerWidgetData)">
                <ng-container *ngComponentOutlet="headerWidgetData.componentClass; injector: headerWidgetData.injector; "></ng-container>
            </div>
        </div>
    </div>
    <div class="widget-content" [ngClass]="hasMaximizedWidget ? 'maximized-content' : ''">
        <div class="row ml-0 mr-0" *ngFor="let row of rowData" [ngClass]="hasMaximizedWidgetInRow(row) ? 'maximized' : ''">
            <!-- rows -->
            <ng-container *ngFor="let widget of row">
                <!-- columns -->
                <div [ngClass]="getWidgetSizeClass(widget)">
                    <!-- widgets -->
                    <ng-container *ngComponentOutlet="widget.componentClass; injector: widget.injector; "></ng-container>
                </div>
            </ng-container>
        </div>
    </div>
</div>
