// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { EditingMonitoringSession } from '@/_models/editing-monitoring-session';
import { Action } from '@ngrx/store';

export class LoadEditingMonitoringSessionAction implements Action {
    readonly type = '[EditingMonitoringSession] InState Get Dap Document Session action';
    constructor(public readonly currentMonitoringSession: EditingMonitoringSession) { }
}

export class LoadEditingMonitoringAction implements Action {
    readonly type = '[EditingMonitoringSession] InState Get Dap Document Sessions action';
    constructor(public readonly monitoringSessions: EditingMonitoringSession[]) { }
}

//#region fetch

export class FetchEditingMonitoringAction implements Action {
    public type = '[EditingMonitoringSession] Fetch EditingMonitoring action';
    constructor(public readonly monitoringSession: EditingMonitoringSession) { }
}

export class FetchEditingMonitoringSessionAction implements Action {
    public type = '[EditingMonitoringSession] Fetch EditingMonitoringSession action';
    constructor(public readonly monitoringKey: string) { }
}

export class FetchEditingMonitoringStartViewAction implements Action {
    public type = '[EditingMonitoringSession] Fetch EditingMonitoringStartView action';
    constructor(public readonly monitoringSession: EditingMonitoringSession) { }
}

export class FetchEditingMonitoringStartEditAction implements Action {
    public type = '[EditingMonitoringSession] Fetch EditingMonitoringStartEdit action';
    constructor(public readonly monitoringSession: EditingMonitoringSession) { }
}

export class FetchEditingMonitoringEndSessionAction implements Action {
    public type = '[EditingMonitoringSession] Fetch EditingMonitoringEndSession action';
    constructor(public readonly sessionId: string) { }
}

export class FetchEditingMonitoringUpdateSessionAction implements Action {
    public type = '[EditingMonitoringSession] Fetch EditingMonitoringUpdateSession action';
    constructor(public readonly monitoringSession: EditingMonitoringSession) { }
}

export class FetchEditingMonitoringOkAction implements Action {
    public type = '[EditingMonitoringSession] Fetch EditingMonitoring Ok action';
}

export class FetchEditingMonitoringFailedAction implements Action {
    public type = '[EditingMonitoringSession] Fetch EditingMonitoring Failed action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion fetch
