// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

export function toMap<TData>(grouped: { [key: number]: TData[] }): Map<number, TData[]>;
export function toMap<TData, TOut>(grouped: { [key: number]: TData[] }, resultSelectorFn: (x: TData) => TOut): Map<number, TOut[]>;
export function toMap<TData>(grouped: { [key: string]: TData[] }): Map<string, TData[]>;
export function toMap<TData, TOut>(grouped: { [key: string]: TData[] }, resultSelectorFn: (x: TData) => TOut): Map<string, TOut[]>;
export function toMap<TData, TOut>(grouped: { [key: string]: TData[] }, resultSelectorFn: (x: TData) => TOut = x => x as any
): Map<any, TOut[]> {
    const map = new Map<number | string, TOut[]>();
    Object.keys(grouped).forEach(key => {
        const numberKey = Number(key);
        // tslint:disable-next-line: triple-equals
        const mapKey = (numberKey as any) != key ? key : numberKey;
        const mapValue = grouped[key] && grouped[key].map(x => resultSelectorFn(x));
        map.set(mapKey, mapValue);
    });
    return map;
}
