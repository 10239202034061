//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PublicPart } from 'ngx-shared';
import {
    RuleManagementFilterLists, RuleManagementFilters, ListItem, RuleManagementRuleInstanceData, RuleManagementRuleInstance,
    RuleInstanceParameterWithOverrides, CopyOverridesMode,
} from '@/_models';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RuleManagementService {

    constructor(private http: HttpClient) { }

    getFilterLists(): Observable<RuleManagementFilterLists> {
        return this.http.get<RuleManagementFilterLists>(`${environment.portalWebApi}/RuleManagement/lists`).pipe(
            map(x => x ?
                new RuleManagementFilterLists(
                x.order, x.rule,
                // make first char capital
                Array.isArray(x.subjectType)
                    ? x.subjectType.map(y => ({
                        ...y,
                        name: y.name ? y.name.substring(0, 1).toUpperCase().concat(y.name.substring(1)) : null
                    }))
                    : [],
                x.serviceProvider, x.service, x.tool, x.indexType,
                [ new ListItem('false', 'No'), new ListItem('true', 'Yes') ]
            ) : new RuleManagementFilterLists([], [], [], [], [], [], [], []))
        );
    }

    getRuleInstanceData(filters: RuleManagementFilters): Observable<RuleManagementRuleInstanceData> {
        return this.http.post<RuleManagementRuleInstanceData>(`${environment.portalWebApi}/RuleManagement/ruleInstances`, filters);
    }

    getRuleInstanceParameters(ruleInstances: RuleManagementRuleInstance[]): Observable<RuleInstanceParameterWithOverrides[]> {
        return this.http.post<RuleInstanceParameterWithOverrides[]>(`${environment.portalWebApi}/RuleManagement/ruleParameters`,
        ruleInstances );
    }

    getRuleInstanceParametersOverrideIndicators(ruleInstances: RuleManagementRuleInstance[]): Observable<RuleManagementRuleInstance[]> {
        return this.http.post<RuleManagementRuleInstance[]>(`${environment.portalWebApi}/RuleManagement/overrideIndicators`,
        ruleInstances );
    }

    updateRuleInstanceParameters(parameters: RuleInstanceParameterWithOverrides[]): Observable<object> {
        return this.http.post<object>(`${environment.portalWebApi}/RuleManagement/updateRuleParameters`,
        parameters );
    }

    getCopyFromOrderList(ruleInstances: {subjectName: string, ruleImplementationId: number}[]): Observable<ListItem[]> {
        return this.http.post<ListItem[]>(`${environment.portalWebApi}/RuleManagement/getAvailableOrderNames`,
        ruleInstances );
    }

    copyParameterOverrides(
        copyOverridesMode: CopyOverridesMode,
        copyFromOrderId: number,
        copyToOrderId: number,
        ruleInstances: {subjectName: string, ruleImplementationId: number}[]
    ): Observable<object> {
        return this.http.post<object>(`${environment.portalWebApi}/RuleManagement/copyOverrides`, {
            copyOverridesMode, copyFromOrderId, copyToOrderId, ruleInstances
        });
    }
}

/* eslint-disable */
export class FakeRuleManagementService implements PublicPart<RuleManagementService> {
    getFilterLists(): Observable<RuleManagementFilterLists> {
        throw new Error('Method not implemented.');
    }

    getRuleInstanceData(filters: RuleManagementFilters): Observable<RuleManagementRuleInstanceData> {
        throw new Error('Method not implemented.');
    }

    getRuleInstanceParameters(): Observable<RuleInstanceParameterWithOverrides[]> {
        throw new Error('Method not implemented.');
    }

    getRuleInstanceParametersOverrideIndicators(ruleInstances: RuleManagementRuleInstance[]): Observable<RuleManagementRuleInstance[]> {
        throw new Error('Method not implemented.');
    }

    updateRuleInstanceParameters(parameters: RuleInstanceParameterWithOverrides[]): Observable<object> {
        throw new Error('Method not implemented.');
    }

    getCopyFromOrderList(): Observable<ListItem[]> {
        throw new Error('Method not implemented.');
    }

    copyParameterOverrides(
        copyOverridesMode: CopyOverridesMode,
        copyFromOrderId: number,
        copyToOrderId: number,
        ruleInstances: {subjectName: string, ruleImplementationId: number}[]
    ): Observable<object> {
        throw new Error('Method not implemented.');
    }
}

export const fakeRuleManagementServiceProvider = { provide: RuleManagementService, useClass: FakeRuleManagementService };
/* eslint-enable */
