// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

export enum InfraPanelState {
    NotMonitored = 'Not Monitored',
    Active = 'Active',
    Down = 'Down',
    Up = 'Up',
}

export class InfraPanel {
    name: string;
    shortName: string;
    /** Informally <i>isMonitored</i>, represents if the Target is currently being monitored*/
    isActive: boolean;
    /** The result of the monitoring: if the Target is Up or Down */
    isAvailable: boolean;
    /** If Target has an active section */
    inActiveSection: boolean;
    /** UTC Date on which the state (isAvailable) was changed */
    isAvailableLastChangeTimestamp: Date;
    /** UTC Date on which the monitor service has checked the target */
    lastCheckedTimestamp: Date;
}
