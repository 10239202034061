<div class="row">
    <div *ngFor="let stateGroup of stateGroups" class="col-6">
        <div class="title">{{stateGroup.name}}</div>
        <app-state-details-group [stateGroup]="stateGroup"></app-state-details-group>
    </div>
    <div *ngFor="let stateTable of stateDetails?.tables" class="col-12">
        <div class="title">{{stateTable.name}}</div>
        <app-state-details-chart *ngIf="stateTable.visualise.visualisationType === enums.tableType.Chart" [stateTable]="stateTable" [isOnScreen]="isOnScreen">
        </app-state-details-chart>
        <app-state-details-table *ngIf="stateTable.visualise.visualisationType === enums.tableType.Table" [stateTable]="stateTable" [isOnScreen]="isOnScreen">
        </app-state-details-table>
    </div>
</div>