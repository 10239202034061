// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { AllValidationErrors } from '@/_helpers';
import { DapDocumentContentFieldValue, DapTemplateContentTable } from '@/_models/dap-document-details';
import { DapDispositionItem, DispositionResult } from './dap-disposition-item';
import { DapDocumentVM } from './dap-document-vm';

export declare type DapTableRowValue = { validationErrors: AllValidationErrors[] } & { [key: string]: DapDocumentContentFieldValue; };
export class DapTableColumnErrors {
    public validationErrors: AllValidationErrors[] ;
}

export class DapTableVM extends DapDispositionItem {
    public templateContent: DapTemplateContentTable;
    public rowData: DapTableRowValue[];
    public columnDispositionResults = new Map<string, DispositionResult>();
    public columnErrors: DapTableColumnErrors[];
    public tableEditorsDispositionResults: DispositionResult[] = [];
    // backwards reference to the doc VM
    public docVM: DapDocumentVM;
}
