// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { NgxAlertService, getMessageFromError } from 'ngx-shared';
import { PicklistService } from '@/_services';
import {
    LoadInStatePickListsAction, FetchPickListsAction, FetchOkPickListsAction, FetchFailedPickListsAction,
    LoadInStatePickListItemsAction, FetchPickListItemsAction, FetchOkPickListItemsAction, FetchFailedPickListItemsAction,
    CreateInStatePickListItemAction, CreatePickListItemAction, CreateOkPickListItemAction, CreateFailedPickListItemAction,
    UpdateInStatePickListItemAction, UpdatePickListItemAction, UpdateOkPickListItemAction, UpdateFailedPickListItemAction,
    DeleteInStatePickListItemAction, DeletePickListItemAction, DeleteOkPickListItemAction, DeleteFailedPickListItemAction,
    AddPickListItemAction, CancelAddPickListItemAction, UpdatePickListItemsOrderAction, UpdateFailedPickListItemsOrderAction,
    UpdateOkPickListItemsOrderAction, UpdateInStatePickListItemsOrderAction,
    DeprecatePickListItemAction,
    DeprecateInStatePickListItemAction,
    DeprecateFailedPickListItemAction,
    DeprecateOkPickListItemAction
} from './pick-list.actions';

@Injectable()
export class PickListEffects {

    
    public onServerFetchPickLists$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchPickListsAction),
        switchMap(() => this.picklistService.getPicklists().pipe(
            switchMap(x => [new LoadInStatePickListsAction(x), new FetchOkPickListsAction()]),
            catchError(error => {
                this.logger.error('Error while Fetch of PickLists', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedPickListsAction(error));
            })
        ))
    ));

    
    public onServerFetchPickListItems$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchPickListItemsAction),
        switchMap(({ pickListIds }) => this.picklistService.getPicklistItems(pickListIds).pipe(
            switchMap(x => [new LoadInStatePickListItemsAction(x), new FetchOkPickListItemsAction()]),
            catchError(error => {
                this.logger.error('Error while Fetch of PickListItems', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedPickListItemsAction(error));
            })
        ))
    ));

    
    public onAddPickListItem$ = createEffect(() => this.actions$.pipe(
        ofAction(AddPickListItemAction),
        map(x => new CreateInStatePickListItemAction(x.pickListItem))
    ));

    
    public onCancelAddPickListItem$ = createEffect(() => this.actions$.pipe(
        ofAction(CancelAddPickListItemAction),
        map(x => new DeleteInStatePickListItemAction(x.pickListItemId))
    ));

    
    public onServerCreatePickListItem$ = createEffect(() => this.actions$.pipe(
        ofAction(CreatePickListItemAction),
        switchMap(({ pickListItem }) => this.picklistService.createPicklistItem(pickListItem).pipe(
            switchMap(x => [new CreateInStatePickListItemAction(x), new CreateOkPickListItemAction()]),
            catchError(error => {
                this.logger.error('Error while Create of PickListItem', error);
                this.alertService.error(getMessageFromError(error));
                return of(new CreateFailedPickListItemAction(error));
            })
        ))
    ));

    
    public onServerUpdatePickListItem$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdatePickListItemAction),
        switchMap(({ pickListItem }) => this.picklistService.updatePicklistItem(pickListItem).pipe(
            switchMap(x => [new UpdateInStatePickListItemAction(x), new UpdateOkPickListItemAction()]),
            catchError(error => {
                this.logger.error('Error while Update of PickListItem', error);
                this.alertService.error(getMessageFromError(error));
                return of(new UpdateFailedPickListItemAction(error));
            })
        ))
    ));

    
    public onServerUpdatePickListItemOrder$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdatePickListItemsOrderAction),
        switchMap(({ pickListItemsIds, picklistId }) => this.picklistService.updatePickListItemsOrder(pickListItemsIds, picklistId)
        .pipe(
            switchMap(x => [new UpdateInStatePickListItemsOrderAction(x), new UpdateOkPickListItemsOrderAction()]),
            catchError(error => {
                this.logger.error('Error while Update order of PickListItems', error);
                this.alertService.error(getMessageFromError(error));
                return of(new UpdateFailedPickListItemsOrderAction(error));
            })
        ))
    ));

    public onServerDeletePickListItem$ = createEffect(() => this.actions$.pipe(
        ofAction(DeletePickListItemAction),
        switchMap(({ pickListItemId }) => this.picklistService.deletePicklistItem(pickListItemId).pipe(
            map(() => pickListItemId),
            switchMap(x => [new DeleteInStatePickListItemAction(x), new DeleteOkPickListItemAction()]),
            catchError(error => {
                this.logger.error('Error while Delete of PickListItem', error);
                this.alertService.error(getMessageFromError(error));
                return of(new DeleteFailedPickListItemAction(error));
            })
        ))
    ));

    public onServerDeprecatePickListItem$ = createEffect(() => this.actions$.pipe(
        ofAction(DeprecatePickListItemAction),
        switchMap(({ pickListItemId, pickListItemName }) => this.picklistService.deprecatePicklistItem(pickListItemId, pickListItemName).pipe(
            map(() => pickListItemId),
            switchMap(x => [new DeprecateInStatePickListItemAction(x), new DeprecateOkPickListItemAction()]),
            catchError(error => {
                this.logger.error('Error while Deprecate of PickListItem', error);
                this.alertService.error(getMessageFromError(error));
                return of(new DeprecateFailedPickListItemAction(error));
            })
        ))
    ));
    
    public onServerOk$ = createEffect(() => this.actions$.pipe(
        ofAction(DeleteOkPickListItemAction, CreateOkPickListItemAction, UpdateOkPickListItemAction),
        tap(() => this.alertService.success('Action completed.'))
    ), { dispatch: false });

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly picklistService: PicklistService
    ) { }
}
