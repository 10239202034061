//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { DapScoreSignedOff } from '@/_models/dap-document-status';

export function getSignedOffIcon(status: DapScoreSignedOff): string {
    if (status === DapScoreSignedOff.FullySignedOff) { return 'far fa-check-square'; }
    if (status === DapScoreSignedOff.PartiallySignedOff) { return 'far fa-minus-square'; }
    if (status === DapScoreSignedOff.SignOffNotRequired) { return 'fas fa-times'; }
    return 'far fa-square';
}

export function getSignedOffDescription(status: DapScoreSignedOff): string {
    if (status === DapScoreSignedOff.FullySignedOff) { return 'Signed-off'; }
    if (status === DapScoreSignedOff.PartiallySignedOff) { return 'Partially signed-off'; }
    if (status === DapScoreSignedOff.SignOffNotRequired) { return 'Sign-off not required'; }
    return 'Awaiting first sign-off';
}
