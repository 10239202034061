// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { NgxAlertService, getMessageFromError } from 'ngx-shared';
import { DapLicenseService } from '@/_services/dap-license-management.service';
import {
    LoadLicensesAction, FetchLicensesAction, FetchFailedLicensesAction, FetchOkLicensesAction,
    UpdateFailedLicenseAction, UpdateOkLicenseAction, UpdateLicenseAction, UpdateLicenseOnServerAction,
    CreateFailedLicenseAction, CreateOkLicenseAction, CreateLicenseAction, CreateLicenseOnServerAction,
    DeleteFailedLicenseAction, DeleteOkLicenseAction, DeleteLicenseAction, DeleteLicenseOnServerAction,
    DeleteLicenseFromStateAction, CreateLicenseInStateAction, UpdateLicenseInStateAction,
    LoadProfilesAction, FetchProfilesAction, FetchFailedProfilesAction, FetchOkProfilesAction,
    LoadLicenseInfoAction, FetchLicenseInfoAction, FetchFailedLicenseInfoAction, FetchOkLicenseInfoAction
} from './dap-license-management.actions';

@Injectable()
export class LicenseEffects {
    
    public onServerFetchLicenses$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchLicensesAction),
        switchMap(() => this.licenseService.getAll().pipe(
            switchMap(x => [new LoadLicensesAction(x), new FetchOkLicensesAction()]),
            catchError(error => {
                this.logger.error('Could not fetch licenses', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedLicensesAction(error));
            })
        ))
    ));

    
    public onUpdate$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdateLicenseAction),
        map(x => new UpdateLicenseOnServerAction(x.license))
    ));

    
    public onServerUpdate$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdateLicenseOnServerAction),
        switchMap(({ license }) => this.licenseService.update(license).pipe(
            switchMap(x => [new UpdateLicenseInStateAction(x), new UpdateOkLicenseAction()]),
            catchError(error => {
                this.logger.error('Could not update license:', error);
                this.alertService.error(getMessageFromError(error));
                return of(new UpdateFailedLicenseAction(error));
            })
        ))
    ));

    
    public onCreate$ = createEffect(() => this.actions$.pipe(
        ofAction(CreateLicenseAction),
        map(x => new CreateLicenseOnServerAction(x.license))
    ));

    
    public onServerCreateLicense$ = createEffect(() => this.actions$.pipe(
        ofAction(CreateLicenseOnServerAction),
        switchMap(({ license }) => this.licenseService.create(license).pipe(
            switchMap(x => [new CreateLicenseInStateAction(x), new CreateOkLicenseAction()]),
            catchError(error => {
                this.logger.error('Could not create license', error);
                this.alertService.error(getMessageFromError(error));
                return of(new CreateFailedLicenseAction(error));
            })
        ))
    ));

    
    public onDelete$ = createEffect(() => this.actions$.pipe(
        ofAction(DeleteLicenseAction),
        map(x => new DeleteLicenseOnServerAction(x.licenseId))
    ));

    
    public onServerDeleteLicense$ = createEffect(() => this.actions$.pipe(
        ofAction(DeleteLicenseOnServerAction),
        switchMap(({ licenseId }) => this.licenseService.delete(licenseId).pipe(
            map(() => licenseId),
            switchMap(x => [
                new DeleteLicenseFromStateAction(x),
                new DeleteOkLicenseAction(),
                new FetchLicensesAction()
            ]),
            catchError(error => {
                this.logger.error('Could not delete license', error);
                this.alertService.error(getMessageFromError(error));
                return of(new DeleteFailedLicenseAction(error));
            })
        ))
    ));

    
    public onServerOk$ = createEffect(() => this.actions$.pipe(
        ofAction(DeleteOkLicenseAction, CreateOkLicenseAction, UpdateOkLicenseAction),
        tap(() => this.alertService.success('Action completed.'))
    ), { dispatch: false });

    
    public onFetch$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchProfilesAction),
        switchMap(() => this.licenseService.getProfiles().pipe(
            switchMap(x => [new LoadProfilesAction(x), new FetchOkProfilesAction()]),
            catchError(error => {
                this.logger.error('Error while fetch of roles', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedProfilesAction(error));
            })
        ))
    ));

    
    public onLicenseKeyFetch$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchLicenseInfoAction),
        switchMap(({licenseKey}) => this.licenseService.getLicenseInfo(licenseKey).pipe(
            switchMap(x => [new LoadLicenseInfoAction(x), new FetchOkLicenseInfoAction()]),
            catchError(error => {
                this.logger.error('Error while fetch of roles', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedLicenseInfoAction(error));
            })
        ))
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly licenseService: DapLicenseService
    ) { }
}
