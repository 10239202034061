// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { DapTemplateContentFunction } from '@/_models/dap-document-details';
import { DapDocumentVM, DapTableRowValue } from '../vm';
import { RefScalar } from './ref-scalar';
import { TemplateFunctions } from './template-functions';
import { PickListItem, PickListType } from '@/_models';

export class RefFunction {
    public static getValue(templateFunction: DapTemplateContentFunction, docVM: DapDocumentVM, sectionIndex: number, dapIndex: number,
        rowValue: DapTableRowValue, picklistMap: Map<PickListType, PickListItem[]>) {
        const paramValues = RefFunction.resolveParams(templateFunction, docVM, sectionIndex, dapIndex, rowValue, picklistMap);
        return new TemplateFunctions(docVM, sectionIndex, dapIndex)[templateFunction.name](paramValues);
    }

    public static resolveParams(templateFunction: DapTemplateContentFunction, docVM: DapDocumentVM, sectionIndex: number, dapIndex: number,
        rowValue: DapTableRowValue, picklistMap: Map<PickListType, PickListItem[]>) {
        const paramValues: any[] = [];
        templateFunction.params.forEach(param => {
            let paramValue = null;
            if (RefScalar.isParamRefScalar(param)) {
                paramValue = RefScalar.getValue(param, docVM, sectionIndex, dapIndex, rowValue, picklistMap);
            } else {
                paramValue = param;
            }

            paramValues.push(paramValue);
        });
        return paramValues;
    }
}
