// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an 'AS IS' BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { DecimatedLog, MonitorTag } from "@/_models";
import { IndexedLogMetadata } from "@/_models/live-kpi";
import { PublicPart } from "@/_shared";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { Observable, map } from "rxjs";

@Injectable({ providedIn: 'root' })
export class IndexedDataService {
    constructor(private http: HttpClient) { }

    getPlottableLogs(sectionId: number): Observable<IndexedLogMetadata[]> {
        return this.http.get<IndexedLogMetadata[]>(`${environment.liveKpiApi}/IndexedData/${sectionId}/list`, {
            headers: {
                SectionId: sectionId.toString()
            }
        });
    }

    getDecimatedLog(sectionId: number, tag: MonitorTag | string, periodInSeconds: number, maxPoints: number, timeZone: string): Observable<DecimatedLog> {
        return this.http.get<DecimatedLog>(`${environment.liveKpiApi}/IndexedData/${sectionId}/${encodeURIComponent(tag)}/decimation/${periodInSeconds}/${maxPoints}`, {
            params: timeZone ? { timeZone: timeZone } : {},
            headers: {
                SectionId: sectionId.toString()
            }
        }).pipe(
            map(x => {
                if (x) {
                    x.from = new Date(x.from);
                    x.to = new Date(x.to);
                    x.points.forEach(l => {
                        l.from = new Date(l.from);
                        l.to = new Date(l.to);
                    });
                    x.timestamp = new Date();
                }
                return x;
            })
        );
    }

    getDecimatedLogs(sectionId: number, tags: string[], periodInSeconds: number, maxPoints: number, timeZone: string): Observable<DecimatedLog[]> {
        return this.http.get<DecimatedLog[]>(`${environment.liveKpiApi}/IndexedData/${sectionId}/${encodeURIComponent(tags.join(','))}/decimations/${periodInSeconds}/${maxPoints}`, {
            params: timeZone ? { timeZone: timeZone } : {},
            headers: {
                SectionId: sectionId.toString()
            }
        }).pipe(
            map(logs => {
                if (logs) {
                    logs.forEach(x => {
                        if (x) {
                            x.from = new Date(x.from);
                            x.to = new Date(x.to);
                            x.points.forEach(l => {
                                l.from = new Date(l.from);
                                l.to = new Date(l.to);
                            });
                            x.timestamp = new Date();
                        }
                    });
                }
                return logs;
            })
        );
    }
}

/* eslint-disable */
export class FakeIndexedDataService implements PublicPart<IndexedDataService> {
    getPlottableLogs(sectionId: number): Observable<IndexedLogMetadata[]> {
        throw new Error("Method not implemented.");
    }
    getDecimatedLog(sectionId: number, tag: MonitorTag | string, periodInSeconds: number, maxPoints: number, timeZone: string): Observable<DecimatedLog> {
        throw new Error('Method not implemented.');
    }
    getDecimatedLogs(sectionId: number, tags: string[], periodInSeconds: number, maxPoints: number, timeZone: string): Observable<DecimatedLog[]> {
        throw new Error('Method not implemented.');
    }
}
