//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { KpiRange } from './kpi-range';
import { IdNamePair } from '../id-name-pair';
import { RuleCategoryType } from './rule-category-type';

export class SectionWithRuleInstances {
    public id: number;
    public sectionKpiRanges: KpiRange[];
    public ruleInstances: RuleInstanceSimple[];
    public rules: IdNamePair[];
}

export class RuleInstanceSimple {
    public id: number;
    public categoryType: RuleCategoryType;
    public categoryTypeName: string;
    public categoryService: string;
    public categorySubject: string;
    public ruleId: number;
    public ruleName: string;
    public subjectId: number;
    public subjectName: string;
    public logName: string;
}
