// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { AbstractControl } from '@angular/forms';

export function enableControl(control: AbstractControl): void;
export function enableControl(controls: { [key: string]: AbstractControl; }, field: string): void;
export function enableControl(controlOrListOfControls: AbstractControl | { [key: string]: AbstractControl; }, fieldName?: string): void {
    if (controlOrListOfControls instanceof AbstractControl) {
        if (controlOrListOfControls.disabled) {
            controlOrListOfControls.enable({ emitEvent: false });
        }
    } else if (fieldName != null && controlOrListOfControls && controlOrListOfControls[fieldName]) {
        if (controlOrListOfControls[fieldName].disabled) {
            controlOrListOfControls[fieldName].enable({ emitEvent: false });
        }
    }
}
export function disableControl(control: AbstractControl): void;
export function disableControl(controls: { [key: string]: AbstractControl; }, field: string): void;
export function disableControl(controlOrListOfControls: AbstractControl | { [key: string]: AbstractControl; }, fieldName?: string): void {
    if (controlOrListOfControls instanceof AbstractControl) {
        if (controlOrListOfControls.enabled) {
            controlOrListOfControls.disable({ emitEvent: false });
        }
    } else if (fieldName != null && controlOrListOfControls && controlOrListOfControls[fieldName]) {
        if (controlOrListOfControls[fieldName].enabled) {
            controlOrListOfControls[fieldName].disable({ emitEvent: false });
        }
    }
}
