//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import {
    MnemonicTool, Order, OrderSectionCurve, OrderSimple, OrderListItem, CopyOrderResult, OrderValidation,
    CopySectionResult, OrderWithExtendedData, IdNamePair, WitsmlObject,
} from '@/_models';
import { DapOrder } from '@/_models/dap-order-sync/dap-order';

export class OrderState {
    public simpleOrders?: OrderSimple[];
    public orderExtendedList?: OrderWithExtendedData[];
    public orderDetails?: Order;
    public orderValidation?: OrderValidation;
    public tools?: MnemonicTool[];
    public catalog?: OrderSectionCurve[];
    public orderList: OrderListItem[];
    public copyOrderResult?: CopyOrderResult;
    public copySectionResult?: CopySectionResult;
    public dapDocuments?: IdNamePair[];
    public syncOrder?: DapOrder;
    public targetWitsmlObjects?: WitsmlObject[];
    public activeWellWellbores?: WitsmlObject[];
    public myRigs?: number[];
}
