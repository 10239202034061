// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS America LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//      http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Measure } from '@/_models';
import { DapDocumentContentFieldValue } from '@/_models/dap-document-details';

export function setFieldValue(fieldFormat: string, fieldValue: DapDocumentContentFieldValue, valueToSet: any) {
    if (!fieldValue) { return; }

    switch (fieldFormat) {
        case 'string': fieldValue.str = valueToSet; break;
        case 'text': fieldValue.text = valueToSet; break;
        case 'integer': fieldValue.int = valueToSet; break;
        case 'float': fieldValue.float = valueToSet; break;
        case 'datetime': fieldValue.datetime = valueToSet; break;
        case 'enum': fieldValue.str = valueToSet; break;
        case 'measure': fieldValue.measure = valueToSet; break;
        case 'boolean': fieldValue.bool = valueToSet; break;
        default: fieldValue.str = valueToSet; break;
    }
}

export function getFieldValue(fieldFormat: string, fieldValue: DapDocumentContentFieldValue) {
    if (!fieldValue) { return null; }

    switch (fieldFormat) {
        case 'string': return fieldValue.str;
        case 'text': return fieldValue.text;
        case 'integer': return fieldValue.int;
        case 'float': return fieldValue.float;
        case 'datetime': return fieldValue.datetime;
        case 'enum': return fieldValue.str;
        case 'measure': return fieldValue.measure;
        case 'boolean': return fieldValue.bool;
        default: return fieldValue.str;
    }
}

export function areFieldValuesEqual(format: string, fieldValue1: DapDocumentContentFieldValue,
    fieldValue2: DapDocumentContentFieldValue): boolean {

        const value1 = getFieldValue(format, fieldValue1);
        const value2 = getFieldValue(format, fieldValue2);
        if (value1 == null && value2 == null) { return true; }
        switch (format) {
            case 'measure':
                return  (value1 as Measure)?.value === (value2 as Measure)?.value && (value1 as Measure)?.uom === (value2 as Measure)?.uom;
            default:
                return value1 === value2;
        }
}
