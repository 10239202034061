//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { PublicPart } from 'ngx-shared';
import { environment } from 'environments/environment';

import {
    SimpleSection, KpiOverride, KpiIssue, PaginationResult, IssueReasonCode, KpiProblemTimeAggregationPoint,
    KpiRange, SectionWithRuleInstances, KpiSummary, KPIExportDetails, KPIExportStatus, SimpleTarget,
} from '@/_models';
import { formatDateInNonIsoFormat } from '@/_helpers/non-iso-date-formatter';

@Injectable({ providedIn: 'root' })
export class KpiService {
    constructor(private http: HttpClient) { }

    getSectionList(moreThanThirtyDays = false): Observable<SimpleSection[]> {
        const params = new HttpParams()
            .set('moreThanThirtyDays', String(moreThanThirtyDays));
        return this.http.get<SimpleSection[]>(`${environment.portalWebApi}/Kpi/sections`, { params });
    }

    getSectionTargets(sectionId: number): Observable<SimpleTarget[]> {
        return this.http.get<SimpleTarget[]>(`${environment.portalWebApi}/Kpi/sectionTargets/${sectionId}`);
    }

    getExportSectionList(): Observable<number[]> {
        return this.http.get<number[]>(`${environment.liveKpiApi}/Kpi/historical/sections`);
    }

    getExportedSectionList(): Observable<KPIExportDetails[]> {
        return this.http.get<KPIExportDetails[]>(`${environment.liveKpiApi}/Kpi/historical/exportedSections`);
    }

    //#region overrides
    getOverrides(sectionId: number, witsmlServerConnectionId: number): Observable<KpiOverride[]> {
        return this.http.get<KpiOverride[]>(`${environment.portalWebApi}/Kpi/overrides/${sectionId}/${witsmlServerConnectionId}`).pipe(
            map(x => x.map(mapOverrideDateTime))
        );
    }

    createOverride(override: KpiOverride): Observable<KpiOverride> {
        const overrideToCreate = {
            ...override,
            startDateTimeUtc: formatDateInNonIsoFormat(override.startDateTimeUtc),
            endDateTimeUtc: formatDateInNonIsoFormat(override.endDateTimeUtc)
        } as KpiOverride;
        return this.http.post<KpiOverride>(`${environment.portalWebApi}/Kpi/overrides/create`, overrideToCreate).pipe(
            map(mapOverrideDateTime)
        );
    }

    updateOverride(override: KpiOverride): Observable<KpiOverride> {
        const overrideToUpdate = {
            ...override,
            startDateTimeUtc: formatDateInNonIsoFormat(override.startDateTimeUtc),
            endDateTimeUtc: formatDateInNonIsoFormat(override.endDateTimeUtc)
        } as KpiOverride;

        return this.http.post<KpiOverride>(`${environment.portalWebApi}/Kpi/overrides/update`, overrideToUpdate).pipe(
            map(mapOverrideDateTime)
        );
    }

    deleteOverride(overrideId: number): Observable<any> {
        return this.http.delete<any>(`${environment.portalWebApi}/Kpi/overrides/${overrideId}`);
    }
    //#endregion overrides

    //#region issues
    getIssueBatch(sectionId: number, witsmlServerConnectionId: number, skip: number, top: number): Observable<PaginationResult<KpiIssue>> {
        const params = new HttpParams()
            .set('skip', String(skip))
            .set('top', String(top));

        return this.http.get<PaginationResult<KpiIssue>>(`${environment.portalWebApi}/Kpi/issues/${sectionId}/${witsmlServerConnectionId}`,
            { params }).pipe(
                map(x => {
                    if (x && Array.isArray(x.value)) {
                        x.value.forEach(y => {
                            if (y.startTimeUtc) {
                                y.startTimeUtc = new Date(y.startTimeUtc);
                            }

                            if (y.endTimeUtc) {
                                y.endTimeUtc = new Date(y.endTimeUtc);
                            }

                            if (!y.isDepthIssue && !y.isTimeIssue) { // fix broken old data
                                y.isDepthIssue = y.categoryTypeName === 'Depth';
                                y.isTimeIssue = y.categoryTypeName !== 'Depth';
                            }
                        });
                    }

                    return x;
                })
            );
    }

    /***
     * Updates issues if they should count in KPI export
     * @param issueIds Collection of issue ids
     * @param isProblemTime Ignore or include the issues
     * @returns {Observable<KpiIssue[]>}
     */
    updateProblemTimeFlag(issueIds: number[], isProblemTime: boolean): Observable<KpiIssue[]> {
        return this.http.post<KpiIssue[]>(`${environment.portalWebApi}/Kpi/issues/problemtime`, { issueIds, isProblemTime: isProblemTime });
    }

    updateAllProblemTimeFlag(sectionId: number, witsmlServerConnectionId: number, isProblemTime: boolean): Observable<any> {
        return this.http.post<any>(`${environment.portalWebApi}/Kpi/issues/problemtimeAll`,
            { sectionId: sectionId, witsmlServerConnectionId: witsmlServerConnectionId, isProblemTime: isProblemTime });
    }
    //#endregion issues

    getIssueReasonCodes(): Observable<IssueReasonCode[]> {
        return this.http.get<IssueReasonCode[]>(`${environment.portalWebApi}/Kpi/issues/reasons`);
    }

    getKpiChartData(sectionId: number): Observable<KpiProblemTimeAggregationPoint[]> {
        return this.http.get<KpiProblemTimeAggregationPoint[]>(`${environment.portalWebApi}/Kpi/issues/trend/${sectionId}`).pipe(
            tap(x => x.forEach(y => y.timestamp = new Date(y.timestamp)))
        );
    }

    getSectionWithRuleInstanceData(sectionId: number, witsmlServerConnectionId: number): Observable<SectionWithRuleInstances> {
        return this.http.get<SectionWithRuleInstances>(`${environment.portalWebApi}/Kpi/section/${sectionId}/${witsmlServerConnectionId}`)
            .pipe(
                map(x => ({
                    ...x,
                    sectionKpiRanges: x.sectionKpiRanges.map(y => ({
                        ...y,
                        startUtc: new Date(y.startUtc),
                        endUtc: new Date(y.endUtc)
                    }) as KpiRange),
                }) as SectionWithRuleInstances)
            );
    }

    createSectionDateRange(sectionId: number, witsmlServerConnectionId: number, range: KpiRange): Observable<KpiRange> {
        range = {
            ...range,
            startUtc: formatDateInNonIsoFormat(range.startUtc),
            endUtc: formatDateInNonIsoFormat(range.endUtc),
        };
        return this.http.post<KpiRange>(`${environment.portalWebApi}/Kpi/section`,
            { ...range, sectionId: sectionId, witsmlServerConnectionId: witsmlServerConnectionId }).pipe(
                map(mapKpiRangeDateTime)
            );
    }

    updateSectionDateRange(range: KpiRange): Observable<KpiRange> {
        range = {
            ...range,
            startUtc: formatDateInNonIsoFormat(range.startUtc),
            endUtc: formatDateInNonIsoFormat(range.endUtc),
        };
        return this.http.patch<KpiRange>(`${environment.portalWebApi}/Kpi/section`, range).pipe(
            map(mapKpiRangeDateTime)
        );
    }

    deleteSectionDateRange(id: number): Observable<any> {
        return this.http.delete<any>(`${environment.portalWebApi}/Kpi/section/${id}`);
    }

    getSummary(sectionId: number, witsmlServerConnectionId: number): Observable<KpiSummary[]> {
        return this.http.get<KpiSummary[]>(`${environment.liveKpiApi}/Kpi/historical/summary/${sectionId}/${witsmlServerConnectionId}`);
    }

    startKpiExport(sectionId: number, witsmlServerConnectionId: number, partial: boolean): Observable<object> {
        return this.http.post(`${environment.liveKpiApi}/Kpi/historical/export/${sectionId}/${witsmlServerConnectionId}/${partial}`, {});
    }

    finalizeKpiExport(sectionId: number, witsmlServerConnectionId: number): Observable<object> {
        return this.http.post(`${environment.liveKpiApi}/Kpi/historical/finalize/${sectionId}/${witsmlServerConnectionId}`, {});
    }

    getKpiExportStatus(sectionId: number, witsmlServerConnectionId: number): Observable<KPIExportStatus> {
        return this.http.get<any>(`${environment.liveKpiApi}/Kpi/historical/status/${sectionId}/${witsmlServerConnectionId}`);
    }
}

/* eslint-disable */
export class FakeKpiService implements PublicPart<KpiService> {
    getSectionList(): Observable<SimpleSection[]> { throw new Error('Not implemented'); }
    getSectionTargets(): Observable<SimpleTarget[]> { throw new Error('Not implemented'); }
    getExportedSectionList(): Observable<KPIExportDetails[]> { throw new Error('Not implemented'); }
    getExportSectionList(): Observable<number[]> { throw new Error('Not implemented'); }
    getOverrides(): Observable<KpiOverride[]> { throw new Error('Not implemented'); }
    createOverride(override: KpiOverride): Observable<KpiOverride> { throw new Error('Not implemented'); }
    updateOverride(override: KpiOverride): Observable<KpiOverride> { throw new Error('Not implemented'); }
    deleteOverride(overrideId: number): Observable<any> { throw new Error('Not implemented'); }
    getIssues(): Observable<PaginationResult<KpiIssue>> { throw new Error('Not implemented'); }
    updateProblemTimeFlag(issueIds: number[], shouldIgnore: boolean): Observable<KpiIssue[]> { throw new Error('Not implemented'); }
    updateAllProblemTimeFlag(sectionId: number, witsmlServerConnectionId: number, shouldIgnore: boolean): Observable<any> { throw new Error('Not implemented'); }
    getIssueReasonCodes(): Observable<IssueReasonCode[]> { throw new Error('Not implemented'); }
    getKpiChartData(): Observable<KpiProblemTimeAggregationPoint[]> { throw new Error('Not implemented'); }
    getIssueBatch(sectionId: number, skip: number, top: number): Observable<PaginationResult<KpiIssue>> {
        throw new Error('Not implemented');
    }
    getSectionWithRuleInstanceData(sectionId: number): Observable<SectionWithRuleInstances> { throw new Error('Not implemented'); }
    createSectionDateRange(sectionId: number, witsmlServerConnectionId: number, range: KpiRange): Observable<any> { throw new Error('Not implemented'); }
    updateSectionDateRange(range: KpiRange): Observable<any> { throw new Error('Not implemented'); }
    deleteSectionDateRange(id: number): Observable<any> { throw new Error('Not implemented'); }
    getSummary(sectionId: number, witsmlServerConnectionId: number): Observable<KpiSummary[]> { throw new Error('Not implemented'); }
    startKpiExport(sectionId: number, witsmlServerConnectionId: number, partial: boolean): Observable<object> { throw new Error('Not implemented'); }
    finalizeKpiExport(sectionId: number, witsmlServerConnectionId: number): Observable<object> { throw new Error('Not implemented'); }
    getKpiExportStatus(sectionId: number, witsmlServerConnectionId: number): Observable<KPIExportStatus> {
        throw new Error('Not implemented.');
    }
}
/* eslint-enable */

function mapOverrideDateTime(override: KpiOverride): KpiOverride {
    return {
        ...override,
        startDateTimeUtc: override.startDateTimeUtc ? new Date(override.startDateTimeUtc) : override.startDateTimeUtc,
        endDateTimeUtc: override.endDateTimeUtc ? new Date(override.endDateTimeUtc) : override.endDateTimeUtc,
    };
}

function mapKpiRangeDateTime(range: KpiRange): KpiRange {
    return {
        ...range,
        startUtc: new Date(range.startUtc),
        endUtc: new Date(range.endUtc)
    };
}
