// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import {Injectable} from '@angular/core';
import {HttpClient, HttpBackend} from '@angular/common/http';
import { PublicPart } from 'ngx-shared';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ConfigurationService {

  private readonly CONFIG_URL = 'assets/config.json';

  private settings: any;
  private http: HttpClient;
  constructor(private readonly httpHandler: HttpBackend) {
    this.http = new HttpClient(httpHandler);
  }

  init(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.http.get<any>(this.CONFIG_URL).pipe(map(res => res))
        .subscribe(value => {
          this.settings = value;
          resolve(true);
        },
        (error) => {
          reject(error);
        });
    });
  }

  getSettings(key?: string | Array<string>): any {
    if (!key || (Array.isArray(key) && !key[0])) {
      return this.settings;
    }

    if (!Array.isArray(key)) {
      key = key.split('.');
    }

    const result = key.reduce((acc: any, current: string) => acc && acc[current], this.settings);

    return result;
  }

}

/* eslint-disable */
export class FakeConfigurationService implements PublicPart<ConfigurationService> {
  init(): Promise<boolean> {
    throw new Error('Method not implemented.');
  }
  getSettings(key?: string | string[]) {
    throw new Error('Method not implemented.');
  }
}

export const fakeConfigurationServiceProvider = { provide: ConfigurationService, useClass: FakeConfigurationService };
/* eslint-enable */
