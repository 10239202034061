// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { DapDocumentBase, DapDocumentFilterData, DapNextRevisionVersion,
    DapTemplateWithSubject } from '@/_models/dap-document';
import { DapDocumentFilter } from '@/_models/dap-document/dap-document-filter';
import { Action } from '@ngrx/store';

export class FetchDapDocumentFilterDataAction implements Action {
    public type = '[DapDocumentManagement] Fetch DapDocument filter data action';
}
export class FetchDapDocumentFilterDataOkAction implements Action {
    public type = '[DapDocumentManagement] Fetch DapDocument filter data Ok action';
}
export class FetchDapDocumentFilterDataFailedAction implements Action {
    public type = '[DapDocumentManagement] Fetch DapDocument filter data Failed action';
    constructor(public readonly error: Error | string | any) { }
}
export class LoadDapDocumentFilterDataAction implements Action {
    readonly type = '[DapDocumentManagement] InState Load DapDocument filter data action';

    constructor(public readonly filterData: DapDocumentFilterData) { }
}

export class FetchDapDocumentsAction implements Action {
    public type = '[DapDocumentManagement] Fetch DapDocuments action';

    constructor(public readonly filter: DapDocumentFilter) { }
}
export class FetchDapDocumentsOkAction implements Action {
    public type = '[DapDocumentManagement] Fetch DapDocuments Ok action';
}
export class FetchDapDocumentsFailedAction implements Action {
    public type = '[DapDocumentManagement] Fetch DapDocuments Failed action';
    constructor(public readonly error: Error | string | any) { }
}
export class LoadDapDocumentsAction implements Action {
    readonly type = '[DapDocumentManagement] InState Load DapDocuments action';

    constructor(public readonly documents: DapDocumentBase[]) { }
}

export class MarkDapDocumentsAsDiagramLoadingAction implements Action {
    readonly type = '[DapDocumentManagement] InState Mark DapDocuments as diagram loading action';

    constructor(public readonly documents: DapDocumentBase[]) { }
}
export class FetchDapDocumentScoreDiagramsAction implements Action {
    public type = '[DapDocumentManagement] Fetch DapDocuments score diagrams action';

    constructor(public readonly documents: DapDocumentBase[]) { }
}
export class FetchDapDocumentScoreDiagramsOkAction implements Action {
    public type = '[DapDocumentManagement] Fetch DapDocuments score diagrams Ok action';
}
export class FetchDapDocumentScoreDiagramsFailedAction implements Action {
    public type = '[DapDocumentManagement] Fetch DapDocuments score diagrams Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class ToggleDapDocumentFavouriteAction implements Action {
    readonly type = '[DapDocumentManagement] Toggle the favourite status of a DAP document';

    constructor(public readonly dapHeaderId: number) { }
}
export class ToggleDapDocumentFavouriteOkAction implements Action {
    public type = '[DapDocumentManagement] Toggle the favourite status of a DAP document Ok action';
}
export class ToggleDapDocumentFavouriteFailedAction implements Action {
    public type = '[DapDocumentManagement] Toggle the favourite status of a DAP document Failed action';
    constructor(public readonly error: Error | string | any) { }
}
export class ToggleDapDocumentFavouriteInStoreAction implements Action {
    readonly type = '[DapDocumentManagement] InState toggle favourite status of a document action';

    constructor(public readonly dapHeaderId: number, public readonly newState: boolean) { }
}

export class CreateDocumentAction implements Action {
    readonly type = '[DapDocumentManagement] Create document action';

    constructor(public readonly templateId: number, public readonly subject: string) { }
}
export class CreateDocumentOkAction implements Action {
    public type = '[DapDocumentManagement] Create document Ok action';
}
export class CreateDocumentFailedAction implements Action {
    public type = '[DapDocumentManagement] Create document Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class CopyDocumentAction implements Action {
    readonly type = '[DapDocumentManagement] Copy document action';

    constructor(public readonly documentId: number, public readonly subject: string) { }
}
export class CopyDocumentOkAction implements Action {
    public type = '[DapDocumentManagement] Copy document Ok action';
}
export class CopyDocumentFailedAction implements Action {
    public type = '[DapDocumentManagement] Copy document Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class CreateNewRevisionAction implements Action {
    readonly type = '[DapDocumentManagement] Create new revision action';

    constructor(public readonly documentId: number) { }
}
export class CreateNewRevisionOkAction implements Action {
    public type = '[DapDocumentManagement] Create new revision Ok action';
}
export class CreateNewRevisionFailedAction implements Action {
    public type = '[DapDocumentManagement] Create new revision Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class CreateNewVersionAction implements Action {
    readonly type = '[DapDocumentManagement] Create new version action';

    constructor(public readonly documentId: number) { }
}
export class CreateNewVersionOkAction implements Action {
    public type = '[DapDocumentManagement] Create new version Ok action';
}
export class CreateNewVersionFailedAction implements Action {
    public type = '[DapDocumentManagement] Create new version Failed action';
    constructor(public readonly error: Error | string | any) { }
}

export class UpdateDocumentInStoreAction implements Action {
    readonly type = '[DapDocumentManagement] Update document in store action';

    constructor(public readonly document: DapDocumentBase) { }
}

export class DeleteDocumentAction implements Action {
    readonly type = '[DapDocumentManagement] Delete document action';

    constructor(public readonly documentId: number, public readonly updateInStore: boolean) { }
}
export class DeleteDocumentOkAction implements Action {
    public type = '[DapDocumentManagement] Delete document Ok action';
}
export class DeleteDocumentFailedAction implements Action {
    public type = '[DapDocumentManagement] Delete document Failed action';
    constructor(public readonly error: Error | string | any) { }
}
export class UpdateDeletedDocumentInStoreAction implements Action {
    readonly type = '[DapDocumentManagement] Update deleted document in store action';

    constructor(public readonly document: DapDocumentBase, public readonly updateInStore: boolean) { }
}


export class RouteToDetailsAction implements Action {
    readonly type = '[DapDocumentManagement] Route to document details action';

    constructor(public readonly documentId: number) { }
}

export class FetchDapTemplatesAction implements Action {
    public type = '[DapDocumentManagement] Fetch DapTemplate with scope action';
}
export class FetchDapTemplatesOkAction implements Action {
    public type = '[DapDocumentManagement] Fetch DapTemplate with scope Ok action';
}
export class FetchDapTemplatesFailedAction implements Action {
    public type = '[DapDocumentManagement] Fetch DapTemplate with scope Failed action';
    constructor(public readonly error: Error | string | any) { }
}
export class LoadDapTemplatesAction implements Action {
    readonly type = '[DapDocumentManagement] InState Load DapTemplate with scope action';

    constructor(public readonly templates: DapTemplateWithSubject[]) { }
}

export class FetchNextRevisionVersionsAction implements Action {
    public type = '[DapDocumentManagement] Fetch Next revision/version action';
    constructor(public readonly documentId: number) { }
}
export class FetchNextRevisionVersionsOkAction implements Action {
    public type = '[DapDocumentManagement] Fetch Next revision/version Ok action';
}
export class FetchNextRevisionVersionsFailedAction implements Action {
    public type = '[DapDocumentManagement] Fetch Next revision/version Failed action';
    constructor(public readonly error: Error | string | any) { }
}
export class LoadNextRevisionVersionsAction implements Action {
    readonly type = '[DapDocumentManagement] InState Fetch Next revision/version action';

    constructor(public readonly nextRevisionVersion: DapNextRevisionVersion) { }
}
