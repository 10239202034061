// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { OrderSectionCurve } from './order-section-curve';
import { AutoCompleteCondition, AutoStartCondition } from './section-auto-start-stop';

export class OrderSection {
    public id: number;
    public ordinal?: number;
    public name: string;
    public sizePicklistId?: number;
    public typePicklistId?: number;
    public curves?: OrderSectionCurve[];
    public statusPicklistId: number;
    public startDateRig?: Date;
    public endDateRig?: Date;
    public serviceProviders?: OrderSectionServiceProvider[];
    public startDepth?: number;
    public startDepthUoM?: string;
    public targetDepth?: number;
    public targetDepthUoM?: string;
    public scanningStartDepth?: number;
    public orderWellPlacement?: boolean;
    public standLength: number;
    public standLengthUom: string;
    public surveySensorOffset: number;
    public surveySensorOffsetUom: string;
    public isHistorical?: boolean;
    public comments?: string;
    public followsPreviousSection?: boolean;
    public depthRanges?: OrderSectionDepthRange[];

    // FlowRate params
    public minFlowRate?: number;
    public minFlowRateUoM?: string;
    // in seconds
    public flowRateRecoveryPeriod?: number;
    public plannedMaxRoP?: number;
    public plannedMaxRoPUoM?: string;
    public actualMaxRoP?: number;
    public actualMaxRoPUoM?: string;
    public maxBackfillDepth?: number;
    public maxBackfillDepthUoM?: string;

    public dapComment: string;
    
    public autoStartCondition?: AutoStartCondition;
    public autoCompleteCondition?: AutoCompleteCondition;
    public autoCompleteTimeThreshold?: number;
    public autoCompleteDepthThreshold?: number;
    public wasAutomaticallyStarted: boolean;
    public wasAutomaticallyCompleted: boolean;
    public copiedWithMergeConflicts: boolean;
    public activatedDate: Date;
    public completedDate: Date;
    public holeDepth: number;
    public holeDepthUom: string;

    // Dap
    public dapSectionId : number;
    public dapMwdId : number;
    public dapMlId : number;
    public dapWellPlacementId : number;
}

export class OrderSectionServiceProvider {
    public serviceId: number;
    public serviceProviderId: number;
    public tools: string[];
}

export class OrderSectionDepthRange {
    public id: number;
    public depthRangePicklistId: number;
    public listNum: number;
    public plannedMaxRoP: number;
    public plannedMaxRoPUom: string;
    public startDepth: number;
    public startDepthUom: string;
    public endDepth: number;
    public endDepthUom: string;
    public forcedUntilDepth: number;
    public forcedUntilDepthUom: string;
    public forcedUntilTimeRig: Date;
    public isActive: boolean;
}
