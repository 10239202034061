// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an 'AS IS' BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Measure, PickListItem } from '@/_models';
import { DapDocumentStatus } from '@/_models/dap-common';
import {
    DapDocumentApproval, DapDocumentContact, DapDocumentContentField, DapDocumentContentTable, DapDocumentData,
    DapDocumentDetailsData, DapDocumentRecord, DapScoringModelDefaults, DapTemplateContent
} from '@/_models/dap-document-details';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class DapDocumentDetailsService {

    constructor(private http: HttpClient) { }

    getTemplateContent(id: number): Observable<DapTemplateContent> {
        return this.http.get<DapTemplateContent>(`${environment.dapApi}/DapDocumentDetails/templateContent/${id}`);
    }

    getDocumentContent(id: number): Observable<DapDocumentDetailsData> {
        return this.http.get<DapDocumentDetailsData>(`${environment.dapApi}/DapDocumentDetails/documentContent/${id}`).pipe(
            tap(x => x && this.convertObjects(x))
        );
    }

    saveDocument(id: number, documentDetails: DapDocumentDetailsData): Observable<DapDocumentDetailsData> {
        return this.http.post<DapDocumentDetailsData>(`${environment.dapApi}/DapDocumentDetails/save/${id}`, documentDetails).pipe(
            tap(x => x && this.convertObjects(x))
        );
    }

    updateStatus(documentId: number, newStatus: DapDocumentStatus): Observable<{item1: DapDocumentStatus, item2: string}> {
        return this.http.post<{item1: DapDocumentStatus, item2: string}>(`${environment.dapApi}/DapDocumentDetails/updatestatus/${documentId}`, newStatus);
    }

    saveDocumentBusinessState(documentId: number, businessState: string): Observable<string> {
        return this.http.post(`${environment.dapApi}/DapDocumentDetails/${documentId}/businessState/${businessState}`,
            null, {responseType: 'text'});
    }

    getDocumentData(id: number): Observable<DapDocumentData> {
        return this.http.get<DapDocumentData>(`${environment.dapApi}/DapDocumentDetails/data/${id}`).pipe(
            tap(x => x && x.approvals.filter(item => item.approved).forEach(item => item.approved = new Date(item.approved)))
        );
    }

    approveDocument(id: number, approval: DapDocumentApproval): Observable<DapDocumentData> {
        return this.http.post<DapDocumentData>(`${environment.dapApi}/DapDocumentDetails/approve/${id}`, approval).pipe(
            tap(x => x && x.approvals.filter(item => item.approved).forEach(item => item.approved = new Date(item.approved)))
        );
    }

    clearDocumentApproval(id: number): Observable<DapDocumentData> {
        return this.http.post<DapDocumentData>(`${environment.dapApi}/DapDocumentDetails/clearapproval/${id}`, {}).pipe(
            tap(x => x && x.approvals.filter(item => item.approved).forEach(item => item.approved = new Date(item.approved)))
        );
    }

    getDocumentChanges(id: number): Observable<DapDocumentRecord[]> {
        return this.http.get<DapDocumentRecord[]>(`${environment.dapApi}/DapDocumentDetails/documentChanges/${id}`);
    }
    
    getDocumentContacts(id: number): Observable<DapDocumentContact[]> {
        return this.http.get<DapDocumentContact[]>(`${environment.dapApi}/DapDocumentDetails/contacts/${id}`);
    }

    createDocumentContact(contact: DapDocumentContact): Observable<DapDocumentContact> {
        return this.http.post<DapDocumentContact>(`${environment.dapApi}/DapDocumentDetails/contacts/create`, contact);
    }

    updateDocumentContact(contact: DapDocumentContact): Observable<DapDocumentContact> {
        return this.http.post<DapDocumentContact>(`${environment.dapApi}/DapDocumentDetails/contacts/update`, contact);
    }

    deleteDocumentContact(id: number): Observable<number> {
        return this.http.post<number>(`${environment.dapApi}/DapDocumentDetails/contacts/delete/${id}`, {});
    }

    getPicklistItems(): Observable<PickListItem[]> {
        return this.http.get<PickListItem[]>(`${environment.dapApi}/DapDocumentDetails/picklistItems`);
    }

    getAdGroups(): Observable<string[]> {
        return this.http.get<string[]>(`${environment.dapApi}/DapDocumentDetails/adGroups`);
    }
    
    getScoringModelDefaults(id: number): Observable<DapScoringModelDefaults> {
        return this.http.get<DapScoringModelDefaults>(`${environment.dapApi}/DapDocumentDetails/${id}/scoringDefaults`);
    }

    private convertObjects(docData: DapDocumentDetailsData) {
        if (!docData) { return; }

        if (docData?.activeDocChangeData?.changeDate) {
            docData.activeDocChangeData.changeDate = new Date(docData.activeDocChangeData.changeDate);
        }

        const docContent = docData.content;
        this.convertObjectsForFieldsAndTables(docContent.fields, docContent.tables);
        if (docContent.sections) {
            docContent.sections.forEach(section => {
                this.convertObjectsForFieldsAndTables(section.fields, section.tables);
                if (section.daps) {
                    section.daps.forEach(dap => this.convertObjectsForFieldsAndTables(dap.fields, dap.tables));
                }
            });
        }
    }

    private convertObjectsForFieldsAndTables(fields: DapDocumentContentField[], tables: DapDocumentContentTable[]) {
        if (fields) {
            fields.filter(field => field.datetime).forEach(field => field.datetime = new Date(field.datetime));
            fields.filter(field => field.measure).forEach(field => field.measure = new Measure(field.measure.value, field.measure.uom));
        }

        if (tables) {
            tables.forEach(table => {
                if (table.rows) {
                    table.rows.forEach(rowValues => {
                        if (rowValues) {
                            rowValues.filter(field => field && field.datetime).forEach(field => field.datetime = new Date(field.datetime));
                            rowValues.filter(field => field && field.measure).forEach(field => field.measure = new Measure(field.measure.value, field.measure.uom));
                        }
                    });
                }
            });
        }
    }
}
