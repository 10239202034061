//  -----------------------------------------------------------------------
//  PDS DRQe
//
//  Copyright 2019 PDS Americas LLC
//
//  Licensed under the PDS Open Source WITSML Product License Agreement (the
//  "License"); you may not use this file except in compliance with the License.
//  You may obtain a copy of the License at
//
//      http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//  Unless required by applicable law or agreed to in writing, software
//  distributed under the License is distributed on an "AS IS" BASIS,
//  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//  See the License for the specific language governing permissions and
//  limitations under the License.
//  -----------------------------------------------------------------------

import {
    MonitorOverview, OverviewStatuses, SectionMonitorInfo,
    SectionMonitorStatus, SectionTrendData, SectionMonitorRuleInstanceDataSet,
    Issue, RuleInstanceLastCheckedData, RuleInstanceProblemTime,
    MonitorSectionSettings, DecimatedLog, MatchingIssuesResult,
    DrqKpiScore, DapKpiScore,
    MonitorSelectionInfo,
    KpiScoreTrendCollection
} from '@/_models';
import { IndexedLogMetadata } from '@/_models/live-kpi';

export class MonitorState {
    public sectionId?: number;
    public orderOverviews?: MonitorOverview[];
    public overviewStatuses?: OverviewStatuses[];
    public sectionMonitorInfo?: SectionMonitorInfo;
    public sectionMonitorStatus?: SectionMonitorStatus;
    public sectionTrendData?: SectionTrendData;
    public sectionInstanceData?: SectionMonitorRuleInstanceDataSet;
    public sectionIssues?: Issue[];
    public ruleInstanceLastCheckedData?: RuleInstanceLastCheckedData[];
    public ruleInstancesProblemTime?: RuleInstanceProblemTime[];
    public myRigs?: number[];
    public settings?: MonitorSectionSettings;
    public hDepLog?: DecimatedLog;
    public bDepLog?: DecimatedLog;
    public matchingIssuesResult?: MatchingIssuesResult[];
    public refreshRequest?: Date;
    public plottableLogs?: IndexedLogMetadata[];
    public additionalCurves?: DecimatedLog[];
    public drqKpiScore?: DrqKpiScore;
    public dapKpiScore?: DapKpiScore;
    public selectionInfo?: MonitorSelectionInfo;
    public drqScoreTrends?: KpiScoreTrendCollection;
    public dapScoreTrends?: KpiScoreTrendCollection;
}
