//  -----------------------------------------------------------------------
//  PDS DRQe
//
//  Copyright 2019 PDS Americas LLC
//
//  Licensed under the PDS Open Source WITSML Product License Agreement (the
//  "License"); you may not use this file except in compliance with the License.
//  You may obtain a copy of the License at
//
//      http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//  Unless required by applicable law or agreed to in writing, software
//  distributed under the License is distributed on an "AS IS" BASIS,
//  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//  See the License for the specific language governing permissions and
//  limitations under the License.
//  -----------------------------------------------------------------------

import * as XLSX from 'xlsx';

export const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const EXCEL_EXTENSION = 'xlsx';

export class Exporter {
    public static toExcel(json: any[], sheetName: string, dateNF: string): XLSX.WorkBook {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, { dateNF } as XLSX.JSON2SheetOpts);

        const wb: XLSX.WorkBook = { Sheets: {}, SheetNames: [] } as XLSX.WorkBook;
        wb.Sheets[sheetName] = worksheet;
        wb.SheetNames = [sheetName];

        return wb;
    }

    public static writeToExcel(wb: XLSX.WorkBook) {
        return XLSX.write(wb, { bookType: EXCEL_EXTENSION, type: 'array' });
    }
}
