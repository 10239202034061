// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Store, Action } from '../ngrx-actions';
import {
    LoadInStateAppliancesAction,
    CreateInStateApplianceAction,
    SetSelectedApplianceAction,
    UpdateInStateApplianceAction,
    DeleteInStateApplianceAction,
    ResetApplianceInStateAction,
    LoadInStateAppliancesSlotsCountAction,
    SetApplianceTestStatusAction,
} from './appliance.actions';
import { ApplianceState, ApplianceTestStatus } from './appliance.state';

@Store({} as ApplianceState)
export class ApplianceStore {

    @Action(LoadInStateAppliancesAction)
    public loadAppliances(state: ApplianceState, { appliances }: LoadInStateAppliancesAction) {
        return {
            ...state,
            appliances
        } as ApplianceState;
    }

    @Action(LoadInStateAppliancesSlotsCountAction)
    public loadAppliancesSlotsCount(state: ApplianceState, { slotsCount }: LoadInStateAppliancesSlotsCountAction) {
        return {
            ...state,
            slotsCount
        } as ApplianceState;
    }

    @Action(CreateInStateApplianceAction)
    public createAppliance(state: ApplianceState, { appliance }: CreateInStateApplianceAction) {
        if (!state.appliances) { // extract to contract check
            throw new Error('Appliance state is not defined.');
        }
        if (!appliance) { // extract to contract check
            throw new Error('Appliance is not defined.');
        }

        const newAppliances = [...state.appliances];
        newAppliances.push(appliance);
        return { ...state, appliances: newAppliances } as ApplianceState;
    }

    @Action(SetSelectedApplianceAction)
    public setSelectedAppliance(state: ApplianceState, { applianceId }: SetSelectedApplianceAction) {
        return { ...state, selectedApplianceId: applianceId } as ApplianceState;
    }

    @Action(UpdateInStateApplianceAction)
    public updateAppliance(state: ApplianceState, { appliance }: UpdateInStateApplianceAction) {
        if (!state.appliances) { // extract to contract check
            throw new Error('Appliances state is not defined.');
        }
        if (!appliance) { // extract to contract check
            throw new Error('Appliance is not defined.');
        }

        const index = state.appliances.findIndex(x => x.id === appliance.id);

        if (index < 0) {
            throw new Error(`Appliance (id: ${appliance.id}) not found.`);
        }

        const newAppliances = [...state.appliances];
        newAppliances[index] = { ...appliance };
        return { ...state, appliances: newAppliances } as ApplianceState;
    }

    @Action(DeleteInStateApplianceAction)
    public deleteAppliance(state: ApplianceState, { applianceId }: DeleteInStateApplianceAction) {
        if (!state.appliances) { // extract to contract check
            throw new Error('Appliance state is not defined.');
        }
        if (state.appliances.every(x => x.id !== applianceId)) {
            throw new Error(`Appliance with id "${applianceId}" does not exist in the state.`);
        }

        const newAppliances = state.appliances.filter(x => x.id !== applianceId);
        return { ...state, appliances: newAppliances } as ApplianceState;
    }

    @Action(ResetApplianceInStateAction)
    public resetApplianceInState(state: ApplianceState, { applianceId }: ResetApplianceInStateAction) {
        if (!state.appliances) { // extract to contract check
            throw new Error('Appliances state is not defined.');
        }

        const index = state.appliances.findIndex(x => x.id === applianceId);

        if (index < 0) {
            throw new Error(`Appliance (id: ${applianceId}) not found.`);
        }

        const newAppliance = [...state.appliances];
        newAppliance[index] = { ...state.appliances[index] };
        return { ...state, appliances: newAppliance } as ApplianceState;
    }

    @Action(SetApplianceTestStatusAction)
    public setApplianceTestStatus(state: ApplianceState, { status, errorMessage }: SetApplianceTestStatusAction) {
        // to reach Ok or Fail status you must go though Pending. Otherwise the status is considered reset/discarded
        if (state.applianceTestStatus == null
            && (status === ApplianceTestStatus.Ok || status === ApplianceTestStatus.Fail || status == null)) {
            return state;
        }

        return { ...state, applianceTestStatus: status, applianceTestErrorMessage: errorMessage } as ApplianceState;
    }
}
