//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { RuleCategoryType } from './rule-category-type';

export class KpiOverride {
    public id: number;
    public name: string; // todo: do we need this?
    public isGreen: boolean;
    public sectionId: number;
    public witsmlServerConnectionId: number;
    public startDateTimeUtc?: Date;
    public endDateTimeUtc?: Date;
    public comment?: string;
    // target issue selection
    public subjectType: string;
    public includeCurves: boolean;
    // todo: rename to indexType
    public type: RuleCategoryType;
    public service: string;
    public rules: number[];
    public subjects: number[];
    public problemTimeRelation: string;
    public problemTimeDuration: number;

    // todo: deprecated; leave them for consistency and no build errors
    /* @deprecated */
    public isConstant: boolean;
    /* @deprecated */
    public weight: number;
    /* @deprecated */
    public ruleId: number;
    /* @deprecated */
    public indexType: RuleCategoryType;
}
