// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { ChangeDetectionStrategy, Component, Input, ChangeDetectorRef } from '@angular/core';

export type FadeState = 'visible' | 'hidden';

@Component({
    selector: 'app-fade',
    template: `
<div
    *ngIf="show"
    class="fade"
    [@state]="state"
    (@state.done)="animationDone($event)"
>
    <ng-content #fadeableContent></ng-content>
</div>
`,
    styles: [`
:host {
  display: block;
}
.fade {
  opacity: 0;
}
    `],
    animations: [
        trigger('state', [
            state(
                'visible',
                style({
                    opacity: '1'
                })
            ),
            state(
                'hidden',
                style({
                    opacity: '0'
                })
            ),
            transition('* => visible', [animate('250ms ease-out')]),
            transition('visible => hidden', [animate('250ms ease-out')])
        ])
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FadeComponent {
    state: FadeState;
    private _show: boolean;
    get show() {
        return this._show;
    }
    @Input()
    set show(value: boolean) {
        if (value) {
            this._show = value;
            this.state = 'visible';
        } else {
            this.state = 'hidden';
        }
    }

    constructor(private readonly cd: ChangeDetectorRef) { }

    animationDone(event: AnimationEvent) {
        if (event.fromState === 'visible' && event.toState === 'hidden') {
            this._show = false;
            this.cd.detectChanges();
        }
    }
}
