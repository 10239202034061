// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Action } from '@ngrx/store';
import { SystemConfiguration } from '@/_models/system-configuration';

export class LoadSystemConfigurationAction implements Action {
    public type = '[Admin] Load System Configuration action';

    constructor(public readonly data: SystemConfiguration) { }
}

export class FetchPublicSystemConfigurationAction implements Action {
    public type = '[Admin] Fetch Public System Configuration action';
}

export class FetchPublicOkSystemConfigurationAction implements Action {
    public type = '[Admin] Fetch OK Public System Configuration action';
}

export class FetchPublicFailedSystemConfigurationAction implements Action {
    public type = '[Admin] Fetch Cancel Public System Configuration action';
    constructor(public readonly error: Error | string | any) { }
}

export class FetchSystemConfigurationAction implements Action {
    public type = '[Admin] Fetch System Configuration action';
}

export class FetchOkSystemConfigurationAction implements Action {
    public type = '[Admin] Fetch OK System Configuration action';
}

export class FetchFailedSystemConfigurationAction implements Action {
    public type = '[Admin] Fetch Cancel System Configuration action';
    constructor(public readonly error: Error | string | any) { }
}

export class PersistSystemConfigurationAction implements Action {
    public type = '[Admin] Persist System Configuration action';
    constructor(public readonly config: SystemConfiguration) { }
}

export class PersistOkSystemConfigurationAction implements Action {
    public type = '[Admin] Persist OK System Configuration action';
}

export class PersistFailedSystemConfigurationAction implements Action {
    public type = '[Admin] Persist Cancel System Configuration action';
    constructor(public readonly error: Error | string | any) { }
}
