//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { AlertStateStatus } from './alert-state-status';
import { ProblemTimeData } from '../problem-time-data';

export class AlertOverview extends ProblemTimeData {
    public id: number;
    public state: AlertStateStatus;
    public startTime: Date;
    public severity: number;
    public ruleName: string;
    public subjectName: string;
    public cause: string;
    public targetId: number;
    public targetName: string;
    public wellId: string;
    public wellBoreId: string;
    public sectionId: number;
    public rigLocalTime: Date;
}
