<div class="row">
    <div class="col-6">
        <div class="alert alert-primary d-flex align-items-center">
            <i class="fa-solid fa-circle-info mr-3"></i>
            An issue with suppressed display will appear green in the Monitor page, while the suppression is active.
        </div>
        <div *ngIf="error === null && !isSuppressed">
            <div class="mb-2">Suppress red invalid status indication:</div>
            <div class="ml-2">
                <input id="suppress-choices-thisIssue" type="radio" name="suppress-choices"
                    [value]="RuleSuppressionStatus.CurrentIssue" [(ngModel)]="status" [disabled]="!isLastIssueOpen" />
                <label class="ml-2" for="suppress-choices-thisIssue">Current active issue</label>
            </div>
            <div class="ml-2">
                <input id="suppress-choices-futureIssues" type="radio" name="suppress-choices"
                    [value]="RuleSuppressionStatus.All" [(ngModel)]="status" />
                <label class="ml-2" for="suppress-choices-futureIssues">New issues for this rule and subject
                    (including the current issue, if any)</label>
            </div>
            <div class="ml-2">
                <input id="suppress-choices-withReasonCode" type="radio" name="suppress-choices"
                    [value]="RuleSuppressionStatus.ByReasonCode" [(ngModel)]="status" [disabled]="!isLastIssueOpen"/>
                <label class="ml-2" for="suppress-choices-withReasonCode">As above, but only for issues with matching
                    Reason Codes</label>
            </div>
            <div class="mt-3 mb-2">Limit the duration of this suppression (default is until end of Section):</div>
            <div class="ml-2 d-flex flex-row align-items-center">
                <input id="suppress-limit" type="checkbox" [(ngModel)]="suppressLimitNeeded"
                    (change)="setSuppressDisplayUntil()" />
                <div style="min-width: 150px" class="ml-2">
                    <app-period-selector [disabled]="!suppressLimitNeeded"
                        (changed)="onLimitChanged($event)"></app-period-selector>
                </div>
                <div *ngIf="suppressLimitNeeded" class="ml-3">(Until {{suppressUntil}})</div>
            </div>
            <button class="btn btn-md btn-secondary mt-3" type="submit" title="Apply" (click)="onApply()"
                [disabled]="status === RuleSuppressionStatus.NotSuppressed">
                Apply
            </button>
        </div>
        <div *ngIf="error === null && isSuppressed">
            <div class="mb-2">Suppression is already applied to this rule and subject.</div>
            <div class="mb-2" *ngIf="currentSuppressionStatus === RuleSuppressionStatus.CurrentIssue">Currently active
                Issue is suppressed.</div>
            <div class="mb-2" *ngIf="currentSuppressionStatus === RuleSuppressionStatus.All">Future issues matching this
                rule and subject will be suppressed.</div>
            <div class="mb-2" *ngIf="currentSuppressionStatus === RuleSuppressionStatus.ByReasonCode">Only applies to
                issues with a Reason Code of "{{currentSuppressionReasonCode}}".</div>
            <div class="mb-2" *ngIf="currentSuppressionLimit">Suppression is until {{currentSuppressionLimit}}.</div>
            <button class="btn btn-md btn-secondary mt-3" type="submit" title="Clear" (click)="onClear()">
                Clear Suppression
            </button>
        </div>
        <div *ngIf="error !== null">
            <div class="alert alert-danger d-flex align-items-center">
                <i class="fa-solid fa-circle-info mr-3"></i>
                {{error}}
            </div>
        </div>
    </div>
    <div class="col-6">
        <div class="alert alert-primary d-flex align-items-center">
            <i class="fa-solid fa-circle-info mr-3"></i>
            Any issue with suppressed problem time will not be taken into account when calculating KPIs.
        </div>
        <div *ngIf="error === null && !isProblemTimeSuppressed">
            <div class="mb-2">Suppress problem time for:</div>
            <div class="ml-2">
                <input id="suppress-pt-choices-thisIssue" type="radio" name="suppress-pt-choices"
                    [value]="RuleSuppressionStatus.CurrentIssue" [(ngModel)]="statusProblemTime" [disabled]="!isLastIssueOpen" />
                <label class="ml-2" for="suppress-pt-choices-thisIssue">Current active issue</label>
            </div>
            <div class="ml-2">
                <input id="suppress-pt-choices-futureIssues" type="radio" name="suppress-pt-choices"
                    [value]="RuleSuppressionStatus.All" [(ngModel)]="statusProblemTime" />
                <label class="ml-2" for="suppress-pt-choices-futureIssues">New issues for this rule and subject
                    (including the current issue, if any)</label>
            </div>
            <div class="ml-2">
                <input id="suppress-pt-choices-withReasonCode" type="radio" name="suppress-pt-choices"
                    [value]="RuleSuppressionStatus.ByReasonCode" [(ngModel)]="statusProblemTime"  [disabled]="!isLastIssueOpen" />
                <label class="ml-2" for="suppress-pt-choices-withReasonCode">As above, but only for issues with matching
                    Reason Codes</label>
            </div>
            <button class="btn btn-md btn-secondary mt-3" type="submit" title="Apply" (click)="onApplyProblemTimeSuppression()"
                [disabled]="statusProblemTime === RuleSuppressionStatus.NotSuppressed">
                Apply
            </button>
        </div>
        <div *ngIf="error === null && isProblemTimeSuppressed">
            <div class="mb-2">Suppression is already applied to this rule and subject.</div>
            <div class="mb-2" *ngIf="currentProblemTimeSuppressionStatus === RuleSuppressionStatus.CurrentIssue">Currently active
                Issue is suppressed.</div>
            <div class="mb-2" *ngIf="currentProblemTimeSuppressionStatus === RuleSuppressionStatus.All">Future issues matching this
                rule and subject will be suppressed.</div>
            <div class="mb-2" *ngIf="currentProblemTimeSuppressionStatus === RuleSuppressionStatus.ByReasonCode">Only applies to
                issues with a Reason Code of "{{currentProblemTimeSuppressionReasonCode}}".</div>
            <div class="mb-2" *ngIf="currentProblemTimeSuppressionLimit">Suppression is until {{currentProblemTimeSuppressionLimit}}.</div>
            <button class="btn btn-md btn-secondary mt-3" type="submit" title="Clear" (click)="onClearProblemTimeSuppression()">
                Clear Suppression
            </button>
        </div>
        <div *ngIf="error !== null">
            <div class="alert alert-danger d-flex align-items-center">
                <i class="fa-solid fa-circle-info mr-3"></i>
                {{error}}
            </div>
        </div>
    </div>
</div>