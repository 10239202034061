// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { IndicatorStatus } from '../indicator-status';
import { ProblemTimeData } from '../problem-time-data';
import { IssueReasonCode } from '../reason-code';
import { RuleSuppressionStatus } from '../rule-management';

export class SectionMonitorRuleInstanceDataSet {
    public sectionId?: number;
    public categoryId?: string;
    public instances: SectionMonitorRuleInstance[];
}

export class SectionMonitorRuleInstance {
    public ruleInstanceId: number;
    public targetId: number;
    public subjectType: string;
    public subjectName: string;
    public subjectMappedName: string;
    public logName: string;
    public curveLabel: string;
    public subjectId: number;
    public monitor: string;

    public service: string;
    public type: string;
    public subject: string;
    public group: string;

    public issueStartRigTime: Date;
    public issueEndRigTime: Date;
    public issueComment: string;
    public lastCheckedDateTime: Date;
    public status: IndicatorStatus;
    public responsible: string;

    public enabled?: boolean;
    public isRuleActive?: boolean;

    public suppressionStatus: RuleSuppressionStatus;
    public suppressedUntilRigTime?: Date;
    public suppressedReasonCode?: IssueReasonCode;
    public suppressedReasonCodeDescription?: string;

    public problemTimeSuppressionStatus: RuleSuppressionStatus;
    public problemTimeSuppressedUntilRigTime?: Date;
    public problemTimeSuppressedReasonCode?: IssueReasonCode;
    public problemTimeSuppressedReasonCodeDescription?: string;
}

export class RuleInstanceProblemTime extends ProblemTimeData {
    public ruleInstanceId: number;
}
