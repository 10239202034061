//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { DatePipe } from '@angular/common';

export type DatePipePreDefinedFormats = 'short' | 'medium' | 'long' | 'full' | 'shortDate' | 'mediumDate' | 'longDate' | 'fullDate'
    | 'shortTime' | 'mediumTime' | 'longTime' | 'fullTime';

/**
 * DatePipePreDefinedFormats
 * - **short**: equivalent to 'M/d/yy, h:mm a' (6/15/15, 9:03 AM).
 * - **medium**: equivalent to 'MMM d, y, h:mm:ss a' (Jun 15, 2015, 9:03:01 AM).
 * - **long**: equivalent to 'MMMM d, y, h:mm:ss a z' (June 15, 2015 at 9:03:01 AM GMT+1).
 * - **full**: equivalent to 'EEEE, MMMM d, y, h:mm:ss a zzzz' (Monday, June 15, 2015 at 9:03:01 AM GMT+01:00).
 * - **shortDate**: equivalent to 'M/d/yy' (6/15/15).
 * - **mediumDate**: equivalent to 'MMM d, y' (Jun 15, 2015).
 * - **longDate**: equivalent to 'MMMM d, y' (June 15, 2015).
 * - **fullDate**: equivalent to 'EEEE, MMMM d, y' (Monday, June 15, 2015).
 * - **shortTime**: equivalent to 'h:mm a' (9:03 AM).
 * - **mediumTime**: equivalent to 'h:mm:ss a' (9:03:01 AM).
 * - **longTime**: equivalent to 'h:mm:ss a z' (9:03:01 AM GMT+1).
 * - **fullTime**: equivalent to 'h:mm:ss a zzzz' (9:03:01 AM GMT+01:00).
 */
export function dateFormatter(date: Date | string, format: DatePipePreDefinedFormats | string, locale: string) {
    return new DatePipe(locale).transform(date, format);
}
