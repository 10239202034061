// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Action, Store } from '../ngrx-actions';
import { LoadDapStatusFilterDataAction, LoadDapDocumentsAction,
    MarkDapDocumentsAsDiagramLoadingAction, ToggleDapDocumentFavouriteInStoreAction } from './dap-status-management.actions';
import { DapStatusManagementState } from './dap-status-management.state';

@Store({} as DapStatusManagementState)
export class DapStatusManagementStore {

    @Action(LoadDapStatusFilterDataAction)
    public loadDapStatusFilterData(state: DapStatusManagementState, { filterData }: LoadDapStatusFilterDataAction) {
        return { ...state, filterData } as DapStatusManagementState;
    }

    @Action(LoadDapDocumentsAction)
    public loadDapDocuments(state: DapStatusManagementState, { documents }: LoadDapDocumentsAction) {
        return { ...state, documents } as DapStatusManagementState;
    }

    @Action(MarkDapDocumentsAsDiagramLoadingAction)
    public markDapDocumentsToLoadDiagram(state: DapStatusManagementState, { documents }: LoadDapDocumentsAction) {
        documents = documents.map(d => ({...d, scoreDiagram: 'PHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJMNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiCiAgdmlld0JveD0iMCAwIDEwMCAxMDAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDAgMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CiAgPGc+CiAgICA8dGl0bGU+TG9hZGluZy4gUGxlYXNlIHdhaXQuLi48L3RpdGxlPgogIDxyZWN0IGlkPSJwYXRoLTEiIHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAiIGhlaWdodD0iMTAwIiBmaWxsPSIjMDAwMCI+PC9yZWN0PgogIDxjaXJjbGUgZmlsbD0iIzAwMCIgc3Ryb2tlPSJub25lIiBjeD0iNiIgY3k9IjUwIiByPSI2Ij4KICAgIDxhbmltYXRlCiAgICAgIGF0dHJpYnV0ZU5hbWU9Im9wYWNpdHkiCiAgICAgIGR1cj0iMXMiCiAgICAgIHZhbHVlcz0iMDsxOzAiCiAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIgogICAgICBiZWdpbj0iMC4xIi8+ICAgIAogIDwvY2lyY2xlPgogIDxjaXJjbGUgZmlsbD0iIzAwMCIgc3Ryb2tlPSJub25lIiBjeD0iMjYiIGN5PSI1MCIgcj0iNiI+CiAgICA8YW5pbWF0ZQogICAgICBhdHRyaWJ1dGVOYW1lPSJvcGFjaXR5IgogICAgICBkdXI9IjFzIgogICAgICB2YWx1ZXM9IjA7MTswIgogICAgICByZXBlYXRDb3VudD0iaW5kZWZpbml0ZSIgCiAgICAgIGJlZ2luPSIwLjIiLz4gICAgICAgCiAgPC9jaXJjbGU+CiAgPGNpcmNsZSBmaWxsPSIjMDAwIiBzdHJva2U9Im5vbmUiIGN4PSI0NiIgY3k9IjUwIiByPSI2Ij4KICAgIDxhbmltYXRlCiAgICAgIGF0dHJpYnV0ZU5hbWU9Im9wYWNpdHkiCiAgICAgIGR1cj0iMXMiCiAgICAgIHZhbHVlcz0iMDsxOzAiCiAgICAgIHJlcGVhdENvdW50PSJpbmRlZmluaXRlIiAKICAgICAgYmVnaW49IjAuMyIvPiAgICAgCiAgPC9jaXJjbGU+CiAgPC9nPgo8L3N2Zz4K'}));
        return { ...state, documents } as DapStatusManagementState;
    }

    @Action(ToggleDapDocumentFavouriteInStoreAction)
    public toggleDapDocumentFavourite(state: DapStatusManagementState,
        { dapHeaderId, newState }: ToggleDapDocumentFavouriteInStoreAction) {
        const documents = state.documents.map(d => ({ ...d, isMyFavourite: d.dapHeaderId === dapHeaderId ? newState : d.isMyFavourite }));
        return { ...state, documents } as DapStatusManagementState;
    }
}
