// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { PublicPart } from 'ngx-shared';

import { MnemonicCatalog, ImportValidationResult, MnemonicImportMode, IdNamePair } from '@/_models';

@Injectable({ providedIn: 'root' })
export class MnemonicService {
    constructor(private http: HttpClient) { }

    getAllCatalog(serviceProviderId?: number): Observable<MnemonicCatalog[]> {
        const serviceProviderQuery = serviceProviderId != null ? '?serviceProviderId=' + serviceProviderId : '';
        return this.http.get<MnemonicCatalog[]>(`${environment.portalWebApi}/Mnemonics/catalog${serviceProviderQuery}`);
    }

    getServiceIds(serviceProviderId?: number): Observable<IdNamePair[]> {
        const serviceProviderQuery = serviceProviderId != null ? '?serviceProviderId=' + serviceProviderId : '';
        return this.http.get<IdNamePair[]>(`${environment.portalWebApi}/Mnemonics/serviceIds${serviceProviderQuery}`);
    }

    getToolIds(serviceProviderId?: number, serviceId?: number): Observable<IdNamePair[]> {
        const query = serviceProviderId != null
                ? serviceId != null
                    ? '?serviceProviderId=' + serviceProviderId + '&serviceId=' + serviceId
                    : '?serviceProviderId=' + serviceProviderId
                : serviceId != null
                    ? '?serviceId=' + serviceId
                    : '';

        return this.http.get<IdNamePair[]>(`${environment.portalWebApi}/Mnemonics/toolIds${query}`);
    }

    getTemplate(): Observable<Blob> {
        return this.http.get<Blob>(
            `${environment.portalWebApi}/Mnemonics/template`,
            { responseType: 'blob' as 'json' }
        );
    }

    getMnemonicExport(serviceProviderId: number): Observable<Blob> {
        return this.http.get<Blob>(
            `${environment.portalWebApi}/Mnemonics/download/${serviceProviderId}`,
            { responseType: 'blob' as 'json' }
        );
    }

    uploadFile(serviceProviderId: number, mode: MnemonicImportMode, file: File): Observable<ImportValidationResult | Blob> {
        const formData = new FormData();
        formData.append('files', file);
        const url = `${environment.portalWebApi}/Mnemonics/upload/${mode}/${serviceProviderId}`;
        return mode === MnemonicImportMode.ExportValidationFile
            ? this.http.post<Blob>(url, formData, { responseType: 'blob' as 'json' })
            : this.http.post<ImportValidationResult>(url, formData, { reportProgress: true });
    }
}

/* eslint-disable */
export class FakeMnemonicService implements PublicPart<MnemonicService> {
    getAllCatalog(serviceProviderId?: number): Observable<MnemonicCatalog[]> {
        throw new Error('Method not implemented: getAllCatalog');
    }

    getServiceIds(serviceProviderId?: number): Observable<IdNamePair[]> {
        throw new Error('Method not implemented: getServiceIds');
    }

    getToolIds(serviceProviderId?: number, serviceId?: number): Observable<IdNamePair[]> {
        throw new Error('Method not implemented: getToolIds');
    }

    getTemplate(): Observable<Blob> {
        throw new Error('Method not implemented: getTemplate');
    }

    getMnemonicExport(serviceProviderId: number): Observable<Blob> {
        throw new Error('Method not implemented: getMnemonicExport');
    }

    uploadFile(serviceProviderId: number, mode: MnemonicImportMode, file: File): Observable<ImportValidationResult> {
        throw new Error('Method not implemented: uploadFile');
    }
}


export const fakeMnemonicServiceProvider = { provide: MnemonicService, useClass: FakeMnemonicService };
/* eslint-enable */
