// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an 'AS IS' BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { DapBaseTemplate } from '@/_models/dap-template-management';
import { PublicPart } from '@/_shared';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DapTemplateManagementService {

    constructor(private http: HttpClient) { }

    getBaseTemplates(): Observable<DapBaseTemplate[]> {
        return this.http.get<DapBaseTemplate[]>(`${environment.dapApi}/DapTemplateManagement/basetemplates`);
    }

    deprecateTemplate(templateId: number): Observable<DapBaseTemplate> {
        return this.http.post<DapBaseTemplate>(`${environment.dapApi}/DapTemplateManagement/${templateId}/deprecate`, {});
    }

    deleteTemplate(templateId: number): Observable<DapBaseTemplate[]> {
        return this.http.delete<DapBaseTemplate[]>(`${environment.dapApi}/DapTemplateManagement/${templateId}/delete`);
    }
}

/* eslint-disable */
export class FakeDapTemplateManagementService implements PublicPart<DapTemplateManagementService> {
    getBaseTemplates(): Observable<DapBaseTemplate[]> {
        throw new Error('Method not implemented.');
    }

    deprecateTemplate(templateId: number): Observable<DapBaseTemplate> {
        throw new Error('Method not implemented.');
    }

    deleteTemplate(templateId: number): Observable<DapBaseTemplate[]> {
        throw new Error('Method not implemented.');
    }
}
/* eslint-enable */
