// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

export class DapDispositionItem {
    public dispositionResult: DispositionResult;
    public hasReadonlyParent = false;
    public hasCriticalParent = false;
}

export class DispositionResult {
    public mutable: boolean;
    public mutableSetBy: string;
    public visible: boolean;
    public visibleSetBy: string;
    public nullable: boolean;
    public nullableSetBy: string;
    public fixed: boolean;
    public fixedSetBy: string;
    public critical: boolean;
    public criticalSetBy: string;
}

export class DispositionResultGridItem extends DispositionResult {
    public name: string;
    public itemType: string;
    public children: DispositionResultGridItem[];
}
