// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { DapDapStatus, DapDocumentStatus, DapSectionStatus } from '../dap-common';

export const DapActiveColor = '#555555';
export const DapScoredColor = '#5B9Bd5';
export const DapCompletedColor = '#70AD47';
export const DapAbandonColor = '#C00000';

export function documentStatusColor(status: DapDocumentStatus): string {
    if (status === DapDocumentStatus.Active) {
        return DapActiveColor;
    } else if (status === DapDocumentStatus.Completed) {
        return DapCompletedColor;
    } else if (status === DapDocumentStatus.Abandoned) {
        return DapAbandonColor;
    }
    return DapActiveColor;
}

export function sectionStatusColor(status: DapSectionStatus): string {
    if (status === DapSectionStatus.Active) {
        return DapActiveColor;
    } else if (status === DapSectionStatus.Completed) {
        return DapCompletedColor;
    } else if (status === DapSectionStatus.Abandoned) {
        return DapAbandonColor;
    }
    return DapActiveColor;
}

export function dapStatusColor(status: DapDapStatus): string {
    if (status === DapDapStatus.Active) {
        return DapActiveColor;
    } else if (status === DapDapStatus.Scored) {
        return DapScoredColor;
    } else if (status === DapDapStatus.Completed) {
        return DapCompletedColor;
    } else if (status === DapDapStatus.Abandoned) {
        return DapAbandonColor;
    }
    return DapActiveColor;
}
