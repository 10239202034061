//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { Store, Action } from '../ngrx-actions';

import {
    LoadInStateSimpleOrdersAction, LoadInStateOrderDetailsAction, DeleteInStateOrderAction, UpdateInStateSectionStatusAction,
    LoadInStateMnemonicToolsAction, LoadInStateOrderMnemonicCatalogAction, LoadInStateOrderListAction, LoadInStateCopyOrderAction,
    LoadInStateCopySectionAction, LoadInStateOrderValidationMessagesAction, LoadInStateOrderExtendedListAction,
    LoadInStateDapDocumentsAction, LoadInStateSyncOrderAction, LoadInStateTargetWitsmlObjectsAction, LoadInStateTargetWellboresAction,
    LoadInStateActiveWellWellboresAction, LoadInStateMyRigsAction, LoadInStateIsTemplateAction, UpdateOrderDetailsIsTemplateAction,
} from './order.actions';
import { OrderState } from './order.state';
import { SectionStatus, OrderStatus, WitsmlObject } from '@/_models';

@Store({} as OrderStore)
export class OrderStore {
    @Action(LoadInStateSimpleOrdersAction)
    public loadInStateSimpleOrders(state: OrderState, { orders }: LoadInStateSimpleOrdersAction) {
        return { ...state, simpleOrders: orders } as OrderState;
    }

    @Action(LoadInStateIsTemplateAction)
    public loadInStateUpdatedSimpleOrders(state: OrderState, { orderId, isTemplate }: LoadInStateIsTemplateAction) {
        const updatedOrders = state.simpleOrders?.map(o => o.id === orderId ? {...o, isTemplate: isTemplate} : o);
        return { ...state, simpleOrders: updatedOrders } as OrderState;
    }

    @Action(LoadInStateOrderExtendedListAction)
    public loadInStateOrderExtendedList(state: OrderState, { orders }: LoadInStateOrderExtendedListAction) {
        return { ...state, orderExtendedList: orders } as OrderState;
    }

    @Action(LoadInStateOrderDetailsAction)
    public loadInStateOrderDetails(state: OrderState, { order }: LoadInStateOrderDetailsAction) {
        return { ...state, orderDetails: order } as OrderState;
    }

    @Action(UpdateOrderDetailsIsTemplateAction)
    public updateOrderDetailsIsTemplate(state: OrderState, { orderId, isTemplate }: UpdateOrderDetailsIsTemplateAction) {
        const newOrder = state && state.orderDetails && { ...state.orderDetails };
        if (newOrder == null || newOrder.id !== orderId) {
            return;
        }

        newOrder.isTemplate = isTemplate;

        return { ...state, orderDetails: newOrder } as OrderState;
    }

    @Action(LoadInStateOrderListAction)
    public loadInStateOrderList(state: OrderState, { orderList }: LoadInStateOrderListAction) {
        return { ...state, orderList } as OrderState;
    }

    @Action(DeleteInStateOrderAction)
    public deleteInStateOrder(state: OrderState, { orderId }: DeleteInStateOrderAction) {
        const newOrders = Array.isArray(state.simpleOrders) ? state.simpleOrders.filter(x => x.id !== orderId) : null;
        return { ...state, simpleOrders: newOrders } as OrderState;
    }

    @Action(UpdateInStateSectionStatusAction)
    public updateInStateSectionStatus(state: OrderState, { sectionId, status, statusMap }: UpdateInStateSectionStatusAction) {
        const newOrder = state && state.orderDetails && { ...state.orderDetails };
        if (newOrder == null) {
            return;
        }
        newOrder.sections = Array.isArray(newOrder.sections) ? [...newOrder.sections] : [];
        const sectionIndex = newOrder.sections.findIndex(x => x.id === sectionId);
        if (sectionIndex < 0) { return state; }

        const newSection = newOrder.sections[sectionIndex] = { ...newOrder.sections[sectionIndex] };
        newSection.statusPicklistId = Array.from(statusMap.entries()).find(x => x[1] === status)[0];
        const sectionStatuses = Array.isArray(newOrder.sections)
            ? newOrder.sections.map(x => statusMap.get(x.statusPicklistId))
            : [];
        newOrder.status = aggregateOrderStatus(sectionStatuses);
        return { ...state, orderDetails: newOrder } as OrderState;
    }

    @Action(LoadInStateMnemonicToolsAction)
    public loadInStateMnemonicTools(state: OrderState, { tools }: LoadInStateMnemonicToolsAction) {
        return { ...state, tools: tools } as OrderState;
    }

    @Action(LoadInStateOrderMnemonicCatalogAction)
    public loadInStateOrderMnemonicCatalog(state: OrderState, { catalog }: LoadInStateOrderMnemonicCatalogAction) {
        return { ...state, catalog: catalog } as OrderState;
    }

    @Action(LoadInStateCopyOrderAction)
    public loadInStateCopyOrder(state: OrderState, { order, params }: LoadInStateCopyOrderAction) {
        let copyOrderResultWithoutSection = order ? { ...order } : undefined;

        if (order) {
            copyOrderResultWithoutSection = { ...copyOrderResultWithoutSection, sections: [] };
        }

        return {
            ...state,
            copyOrderResult: order ? { order: copyOrderResultWithoutSection, params: params } : undefined,
            // does not have copy section params
            copySectionResult: order ? { sections: order.sections, params: null, targets: order?.targets || null } : undefined
        } as OrderState;
    }

    @Action(LoadInStateCopySectionAction)
    public loadInStateCopySection(state: OrderState, { section, params }: LoadInStateCopySectionAction) {
        return {
            ...state,
            copySectionResult: section ? { sections: [section], params: params } : undefined
        } as OrderState;
    }

    @Action(LoadInStateSyncOrderAction)
    public loadInStateSyncOrder(state: OrderState, { order }: LoadInStateSyncOrderAction) {
        return {
            ...state,
            syncOrder: order
        } as OrderState;
    }

    @Action(LoadInStateOrderValidationMessagesAction)
    public loadInStateOrderValidationMessages(state: OrderState, { orderValidation }: LoadInStateOrderValidationMessagesAction) {
        return { ...state, orderValidation } as OrderState;
    }

    @Action(LoadInStateDapDocumentsAction)
    public loadInStateDapDocuments(state: OrderState, { dapDocuments }: LoadInStateDapDocumentsAction) {
        return { ...state, dapDocuments } as OrderState;
    }

    @Action(LoadInStateTargetWitsmlObjectsAction)
    public LoadInStateTargetWitsmlObjects(state: OrderState, { targetWitsmlObjects }: LoadInStateTargetWitsmlObjectsAction) {
        return { ...state, targetWitsmlObjects } as OrderState;
    }

    @Action(LoadInStateActiveWellWellboresAction)
    public LoadInStateActiveWellWellbores(state: OrderState, { activeWellWellbores }: LoadInStateActiveWellWellboresAction) {
        // update the IsActive value of the Wells that are already in state
        const wells = state && state.targetWitsmlObjects && [ ...state.targetWitsmlObjects ];
        if (wells != null) {
            activeWellWellbores.forEach(activeWell => {
                const matchedWellIndex = wells.findIndex(x => x.uid === activeWell.uid);
                if (matchedWellIndex >= 0) {
                    const newWell = { ...wells[matchedWellIndex] };
                    newWell.isActive = true;
                    wells[matchedWellIndex] = newWell;
                }
            });
        }
        return { ...state, activeWellWellbores, targetWitsmlObjects: wells } as OrderState;
    }

    @Action(LoadInStateTargetWellboresAction)
    public LoadInStateTargetWellbores(state: OrderState, { wellUid, targetWellbores }: LoadInStateTargetWellboresAction) {
        // set Wellbores' IsActive value based on the state's active wellbores list
        const activeWellWellbores = state?.activeWellWellbores?.find(x => x.uid === wellUid);
        let newWellbores: WitsmlObject[] = [];
        if (activeWellWellbores != null) {
            for (let i = 0; i < targetWellbores.length; i++) {
                const newWb = { ...targetWellbores[i] };
                newWb.isActive = activeWellWellbores.children.some(x => x.uid === newWb.uid);
                newWellbores.push(newWb);
            }
        } else {
            newWellbores = targetWellbores;
        }

        // set the wellbores as children of the matched well
        const wells = state && state.targetWitsmlObjects && [ ...state.targetWitsmlObjects ];
        if (wells == null) {
            return;
        }
        const matchedWellIndex = wells.findIndex(x => x.uid === wellUid);
        if (matchedWellIndex >= 0) {
            const newWell = { ...wells[matchedWellIndex] };
            newWell.children = newWellbores;
            newWell.childrenLoaded = true;
            wells[matchedWellIndex] = newWell;
        }
        return { ...state, targetWitsmlObjects: wells } as OrderState;
    }

    @Action(LoadInStateMyRigsAction)
    public loadInStateMyRigs(state: OrderState, { myRigs }: LoadInStateMyRigsAction) {
        return { ...state, myRigs: myRigs } as OrderState;
    }
}

function aggregateOrderStatus(sectionStatuses: string[]) {
    if (sectionStatuses.some(x => x === SectionStatus.Active)) { return OrderStatus.Active; }
    if (sectionStatuses.some(x => x === SectionStatus.Verified)) { return OrderStatus.Verified; }
    if (sectionStatuses.some(x => x === SectionStatus.Ready)) { return OrderStatus.Ready; }
    if (sectionStatuses.some(x => x === SectionStatus.Draft)) { return OrderStatus.Draft; }
    if (sectionStatuses.some(x => x === SectionStatus.Completed)) { return OrderStatus.Completed; }
    return OrderStatus.Empty;
}
