// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { FormGroup } from '@angular/forms';
import { DapDispositionItem, DispositionResult } from './dap-disposition-item';
import { DapFieldVM } from './dap-field-vm';
import { DapGroupVM } from './dap-group-vm';
import { DapTableVM } from './dap-table-vm';

export class DapDataContainerVM extends DapDispositionItem {
    public groupVMs: DapGroupVM[] = [];
    public scoringForm: FormGroup;
    public scoringDispositionResult: DispositionResult;

    public getFieldVMs(): DapFieldVM[] {
        return [].concat(...this.groupVMs.map(x => x.getFieldVMs()));
    }

    public getTableVMs(): DapTableVM[] {
        return [].concat(...this.groupVMs.map(x => x.getTableVMs()));
    }
}
