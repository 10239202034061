//  -----------------------------------------------------------------------
//  PDS DRQe
//
//  Copyright 2019 PDS Americas LLC
//
//  Licensed under the PDS Open Source WITSML Product License Agreement (the
//  "License"); you may not use this file except in compliance with the License.
//  You may obtain a copy of the License at
//
//      http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//  Unless required by applicable law or agreed to in writing, software
//  distributed under the License is distributed on an "AS IS" BASIS,
//  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//  See the License for the specific language governing permissions and
//  limitations under the License.
//  -----------------------------------------------------------------------

import { Component, Inject, Input, LOCALE_ID, OnChanges, OnDestroy, OnInit, SimpleChanges } from "@angular/core";
import { IssueDetailsDialogIssueVm } from "./issue-details-dialog-vm";
import { RuleSuppressionInfo, RuleSuppressionStatus } from "@/_models";
import { RigTimeService } from "@/_services";
import { Subject, takeUntil, tap } from "rxjs";
import { dateFormatter } from "../date-formatter";
import { AppStore, SetProblemTimeSuppressionAction, SetRuleSuppressionAction } from "@state";


@Component({
    selector: 'app-issue-details-suppression-tab',
    templateUrl: './suppression-tab.component.html',
})
export class SuppressionTabComponent implements OnInit, OnDestroy, OnChanges {
    @Input() public issues: IssueDetailsDialogIssueVm[];
    @Input() public sectionId: number|null;

    public ruleInstanceIds: number[];
    
    public error: string|null = null;
    public RuleSuppressionStatus = RuleSuppressionStatus;

    public status = RuleSuppressionStatus.NotSuppressed;
    public suppressLimitNeeded = false;
    public suppressUntil: string;

    public statusProblemTime = RuleSuppressionStatus.NotSuppressed;

    public isLastIssueOpen = true;

    public isSuppressed = false;
    public currentSuppressionStatus = RuleSuppressionStatus.NotSuppressed;
    public currentSuppressionReasonCode: string;
    public currentSuppressionLimit: string;

    public isProblemTimeSuppressed = false;
    public currentProblemTimeSuppressionStatus = RuleSuppressionStatus.NotSuppressed;
    public currentProblemTimeSuppressionReasonCode: string;
    public currentProblemTimeSuppressionLimit: string;

    private destroy$ = new Subject();
    private limitDisplaySuppressionInSeconds = 0;
    private rigTime: Date;

    constructor(
        private readonly rigTimeService: RigTimeService,
        private readonly store: AppStore,
        @Inject(LOCALE_ID) private readonly locale: string) {}

    ngOnInit(): void {
        this.rigTimeService.time$.pipe(
            tap(d => {
                this.rigTime = d;
                this.setSuppressDisplayUntil();
            }),
            takeUntil(this.destroy$)
        ).subscribe();
        this.applyData();
        this.rigTimeService.startTimer();
    }

    ngOnDestroy(): void {
        this.rigTimeService.stopTimer();
        this.destroy$.next(true);
        this.destroy$.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data || changes.issues) {
            this.applyData();
        }
    }

    public onApply() {
        if (this.status === RuleSuppressionStatus.NotSuppressed) { return; }
        const info = {
            status: this.status,
            limitInMinutes: this.suppressLimitNeeded ? this.limitDisplaySuppressionInSeconds : null,
            reasonCode: this.status === RuleSuppressionStatus.ByReasonCode ? this.issues[0].reasonCode : null
        } as RuleSuppressionInfo;
        this.store.dispatch(new SetRuleSuppressionAction(this.issues[0].ruleInstance.ruleInstanceId, info, this.sectionId));
    }

    public onClear() {
        const info = {
            status: RuleSuppressionStatus.NotSuppressed,
            limitInMinutes: null,
            reasonCode: null
        } as RuleSuppressionInfo;
        this.store.dispatch(new SetRuleSuppressionAction(this.issues[0].ruleInstance.ruleInstanceId, info, this.sectionId));
    }

    public onApplyProblemTimeSuppression() {
        if (this.statusProblemTime === RuleSuppressionStatus.NotSuppressed) { return; }
        const info = {
            status: this.statusProblemTime,
            reasonCode: this.statusProblemTime === RuleSuppressionStatus.ByReasonCode ? this.issues[0].reasonCode : null
        } as RuleSuppressionInfo;
        this.store.dispatch(new SetProblemTimeSuppressionAction(this.issues[0].ruleInstance.ruleInstanceId, info, this.sectionId, this.ruleInstanceIds));
    }

    public onClearProblemTimeSuppression() {
        const info = {
            status: RuleSuppressionStatus.NotSuppressed,
            limitInMinutes: null,
            reasonCode: null
        } as RuleSuppressionInfo;
        this.store.dispatch(new SetProblemTimeSuppressionAction(this.issues[0].ruleInstance.ruleInstanceId, info, this.sectionId, this.ruleInstanceIds));
    }

    public onClose() {
        this.status = RuleSuppressionStatus.NotSuppressed;
        this.statusProblemTime = RuleSuppressionStatus.NotSuppressed;
        this.isLastIssueOpen = false;
        this.suppressLimitNeeded = false;
        this.isSuppressed = false;
        this.currentSuppressionStatus = RuleSuppressionStatus.NotSuppressed;
        this.currentSuppressionReasonCode = null;
        this.currentSuppressionLimit = null;
        this.currentProblemTimeSuppressionStatus = RuleSuppressionStatus.NotSuppressed;
        this.currentProblemTimeSuppressionReasonCode = null;
        this.currentProblemTimeSuppressionLimit = null;
        this.isLastIssueOpen = false;
    }

    public onLimitChanged(limit: number) {
        this.limitDisplaySuppressionInSeconds = limit / 60;
        this.setSuppressDisplayUntil();
    }

    public applyTimeZone(timeZone: string) {
        this.rigTimeService.applyTimeZone(timeZone);
        this.setSuppressDisplayUntil();
    }

    public setSuppressDisplayUntil() {
        if (!this.rigTime || !this.rigTimeService.timeZone) { return; }
        const date = new Date(this.rigTime);
        date.setMinutes(date.getMinutes() + Number(this.limitDisplaySuppressionInSeconds));
        this.suppressUntil = `${dateFormatter(date, 'short', this.locale)} ${this.rigTimeService.timeZone}`;
    }

    private applyData() {
        if (!this.issues || this.issues.length === 0){
            this.error = "No issues are selected. There is no rule to suppress.";
            return;
        } else if (this.issues.some(i => i.ruleInstance.ruleInstanceId !== this.issues[0].ruleInstance.ruleInstanceId)) {
            this.error = "Issues from more than one rule are selected.";
            return;
        } else {
            this.error = null;
        }
        const rule = this.issues[0].ruleInstance;
        this.isSuppressed = rule.suppressionStatus !== RuleSuppressionStatus.NotSuppressed;
        this.currentSuppressionStatus = rule.suppressionStatus;
        this.currentSuppressionReasonCode = rule.suppressedReasonCode ? `${rule.suppressedReasonCode} : ${rule.suppressedReasonCodeDescription}` : null;
        this.currentSuppressionLimit = rule.suppressedUntilRigTime ? `${dateFormatter(rule.suppressedUntilRigTime, 'short', this.locale)} ${this.rigTimeService.timeZone}` : null;
        this.isProblemTimeSuppressed = rule.problemTimeSuppressionStatus !== RuleSuppressionStatus.NotSuppressed;
        this.currentProblemTimeSuppressionStatus = rule.problemTimeSuppressionStatus;
        this.currentProblemTimeSuppressionReasonCode = rule.problemTimeSuppressedReasonCode ? `${rule.problemTimeSuppressedReasonCode} : ${rule.problemTimeSuppressedReasonCodeDescription}` : null;
        this.currentProblemTimeSuppressionLimit = rule.problemTimeSuppressedUntilRigTime ? `${dateFormatter(rule.problemTimeSuppressedUntilRigTime, 'short', this.locale)} ${this.rigTimeService.timeZone}` : null;
        this.isLastIssueOpen = this.issues[0].endTime == null;
        if (!this.isLastIssueOpen && this.status === RuleSuppressionStatus.CurrentIssue) {
            this.status = RuleSuppressionStatus.NotSuppressed;
        }
    }
}