//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { Action } from '@ngrx/store';
import { SystemParameters } from '@/_models';

export class LoadInStateSysParametersAction implements Action {
  public type = '[SysParameters] Load in state system parameters action';
  constructor(public readonly parameters: SystemParameters) {}
}

//#region async Fetch SystemParameters

export class FetchSystemParametersAction implements Action {
    public type = '[SysParameters] Fetch SystemParameters action';
}
export class FetchOkSystemParametersAction implements Action {
    public type = '[SysParameters] Fetch OK SystemParameters action';
}
export class FetchFailedSystemParametersAction implements Action {
    public type = '[SysParameters] Fetch Fail SystemParameters action';
    constructor(public readonly error: Error | string | any) {}
}

//#endregion async Fetch SystemParameters
