// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS America LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//      http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { CustomValidators } from '@/_helpers';
import { DapDocumentContentFieldValue, DapTemplateContentColumn, DapTemplateTableCompositeKey } from '@/_models/dap-document-details';
import { ValidationErrors } from '@angular/forms';
import { areFieldValuesEqual, getFieldValue } from './dap-document-field-value.helper';
import { DapTableRowValue } from './vm';

export type ValueValidatorFunc = (value: DapDocumentContentFieldValue) => ValidationErrors | null;
export type MultiValueValidatorFunc =
    (value: DapDocumentContentFieldValue, index: number, allValues: DapDocumentContentFieldValue[]) => ValidationErrors | null;
export type MultiRowValidatorFunc =
    (value: DapTableRowValue, index: number, allValues: DapTableRowValue[], columns: DapTemplateContentColumn[]) => ValidationErrors | null;

export class DapDocumentValueValidators {
    static valueRequiredFunc(format: string): ValueValidatorFunc {
        return (value: DapDocumentContentFieldValue): ValidationErrors | null => {
            if (CustomValidators.isEmptyInputValue(getFieldValue(format, value))) {
                return { required: true };
            } else {
                return null;
            }
        };
    }

    static valuePropertyRequiredFunc(format: string, propertyName: string): ValueValidatorFunc {
        return (value: DapDocumentContentFieldValue): ValidationErrors | null => {
            const fieldValue = getFieldValue(format, value);
            if (fieldValue && !CustomValidators.isEmptyInputValue(fieldValue[propertyName])) {
                return null;
            }

            return {
                propertyRequired: { name: propertyName }
            };
        };
    }

    static valueUniqueFunc(format: string): MultiValueValidatorFunc {
        return (value: DapDocumentContentFieldValue, index: number,
            allValues: DapDocumentContentFieldValue[]): ValidationErrors | null => {

                let isUnique = true;
                for (let i = 0; i < allValues.length; i++) {
                    if (i !== index && areFieldValuesEqual(format, value, allValues[i])) {
                        isUnique = false;
                        break;
                    }
                }

                return isUnique ? null : { unique: false };
        };
    }

    static rowUniqueFunc(compositeKey: DapTemplateTableCompositeKey): MultiRowValidatorFunc {
        return (value: DapTableRowValue, index: number,
            allValues: DapTableRowValue[],
            columns: DapTemplateContentColumn[]): ValidationErrors | null => {
            
            const compositeColumns = compositeKey?.columns?.map(x => x.replace('col:', ''));
            let isUnique = false;

            for (let i = 0; i < allValues.length; i++) {
                isUnique = i === index;

                if (i !== index) {
                    compositeColumns.forEach(col => {
                        const columnType = columns?.find(x => x.key == compositeColumns[0])?.format;
                        if (!areFieldValuesEqual(columnType, value[col], allValues[i][col])) {
                            isUnique = true;
                        }
                    });
                        
                    if (!isUnique) {
                        break;
                    }
                }
            }

            return isUnique ? null : { unique: false };
        };
    }
}
