// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an 'AS IS' BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { PublicPart } from 'ngx-shared';

import { Appliance } from '@/_models/appliance';

class ApplianceTestResponse {
    public isAvailable: boolean;
    public message?: string;
}

@Injectable({
    providedIn: 'root'
})
export class ApplianceService {

    constructor(private http: HttpClient) { }

    getAll(): Observable<Appliance[]> {
        return this.http.get<Appliance[]>(`${environment.portalWebApi}/Appliances`);
    }

    getSlotsCount(): Observable<number> {
        return this.http.get<number>(`${environment.portalWebApi}/Appliances/slotsCount`);
    }

    update(appliance: Appliance): Observable<Appliance> {
        return this.http.post<Appliance>(`${environment.portalWebApi}/Appliances/update`, appliance);
    }

    create(appliance: Appliance): Observable<Appliance> {
        return this.http.post<Appliance>(`${environment.portalWebApi}/Appliances/create`, appliance);
    }

    delete(applianceId: number): Observable<void> {
        return this.http.delete<void>(`${environment.portalWebApi}/Appliances/delete/${applianceId}`);
    }

    checkApplianceConnection(appliance: Appliance): Observable<ApplianceTestResponse> {
        return this.http.post<ApplianceTestResponse>(`${environment.portalWebApi}/Appliances/testAppliance`, appliance);
    }
}


/* eslint-disable */
export class FakeApplianceService implements PublicPart<ApplianceService> {
    getAll(): Observable<Appliance[]> {
        throw new Error('Method not implemented.');
    }

    getSlotsCount(): Observable<number> {
        throw new Error('Method not implemented.');
    }

    update(appliance: Appliance): Observable<Appliance> {
        throw new Error('Method not implemented.');
    }

    create(appliance: Appliance): Observable<Appliance> {
        throw new Error('Method not implemented.');
    }

    delete(applianceId: number): Observable<void> {
        throw new Error('Method not implemented.');
    }

    checkApplianceConnection(appliance: Appliance): Observable<ApplianceTestResponse> {
        throw new Error('Method not implemented.');
    }
}

export const fakeApplianceServiceProvider = { provide: ApplianceService, useClass: FakeApplianceService };
/* eslint-enable */
