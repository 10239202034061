// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { PublicPart } from 'ngx-shared';

import { IdNamePair, MnemonicCatalog } from '@/_models';
import { DapCurve, DapTool, StringWrapper } from '@/_models/dap-document-details';

@Injectable({ providedIn: 'root' })
export class DapCatalogService {
    constructor(private http: HttpClient) { }

    getServiceIds(serviceProviderId?: number): Observable<IdNamePair[]> {
        const serviceProviderQuery = serviceProviderId != null ? '?serviceProviderId=' + serviceProviderId : '';
        return this.http.get<IdNamePair[]>(`${environment.dapApi}/DapCatalog/serviceIds${serviceProviderQuery}`);
    }

    getTools(serviceProviderId?: number, serviceId?: number): Observable<DapTool[]> {
        return this.http.get<DapTool[]>(`${environment.dapApi}/DapCatalog/tools/${serviceProviderId}/${serviceId}`);
    }

    getToolGroups(serviceProviderId?: number, serviceId?: number): Observable<IdNamePair[]> {
        return this.http.get<IdNamePair[]>(`${environment.dapApi}/DapCatalog/toolGroups/${serviceProviderId}/${serviceId}`);
    }

    getCurves(serviceProviderId: number, serviceId: number, tool: string): Observable<DapCurve[]> {
        const data = {
            stringValue: tool
        } as StringWrapper;
        return this.http.post<DapCurve[]>(`${environment.dapApi}/DapCatalog/curves/${serviceProviderId}/${serviceId}`, data);
    }

    getCurvesMostCommon(serviceProviderId: number, serviceId: number, tools: string[]): Observable<DapCurve[]> {
        return this.http.post<DapCurve[]>(`${environment.dapApi}/DapCatalog/curvesMostCommon/${serviceProviderId}/${serviceId}`, tools);
    }

    getAllCatalog(serviceProviderId?: number): Observable<MnemonicCatalog[]> {
        const serviceProviderQuery = serviceProviderId != null ? '?serviceProviderId=' + serviceProviderId : '';
        return this.http.get<MnemonicCatalog[]>(`${environment.dapApi}/DapCatalog/catalog${serviceProviderQuery}`);
    }
}

/* eslint-disable */
export class FakeDapCatalogService implements PublicPart<DapCatalogService> {
    getServiceIds(serviceProviderId?: number): Observable<IdNamePair[]> {
        throw new Error('Method not implemented: getServiceIds');
    }

    getTools(serviceProviderId?: number, serviceId?: number): Observable<DapTool[]> {
        throw new Error('Method not implemented: getToolIds');
    }

    getToolGroups(serviceProviderId?: number, serviceId?: number): Observable<IdNamePair[]> {
        throw new Error('Method not implemented: getToolGroups');
    }

    getCurves(serviceProviderId: number, serviceId: number, tool: string): Observable<DapCurve[]> {
        throw new Error('Method not implemented: getCurves');
    }

    getCurvesMostCommon(serviceProviderId: number, serviceId: number, tools: string[]): Observable<DapCurve[]> {
        throw new Error('Method not implemented.');
    }

    getAllCatalog(serviceProviderId?: number): Observable<MnemonicCatalog[]> {
        throw new Error('Method not implemented.');
    }
}


export const fakeMnemonicServiceProvider = { provide: DapCatalogService, useClass: FakeDapCatalogService };
/* eslint-enable */
