// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS America LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//      http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Measure } from "@/_models";

export class DapDocContainerDiff {
    public key: string;
    public name: string;
    public isCritical: boolean;
    public fieldsDiff: DapDocFieldDiff[];
    public tablesDiff: DapDocTableDiff[];
    public onlyInDoc1 = false;
    public onlyInDoc2 = false;
}

export class DapDocDiff extends DapDocContainerDiff {
    public sectionsDiff: DapDocSectionDiff[];
}

export class DapDocFieldDiff {
    public key: string;
    public name: string;
    public isCritical: boolean;
    public doc1Value: string | number | Date | Measure | boolean | null;
    public doc2Value: string | number | Date | Measure | boolean | null;
}

export class DapDocTableDiff {
    public key: string;
    public name: string;
    public rowsDiff: DapDocRowDiff[];
}

export class DapDocRowDiff {
    public index: number;
    public isCritical: boolean;
    public onlyInDoc1 = false;
    public onlyInDoc2 = false;
    public fieldsDiff: DapDocFieldDiff[];
}

export class DapDocSectionDiff extends DapDocContainerDiff {
    public dapsDiff: DapDocContainerDiff[];
}
