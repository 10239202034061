//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

export class RuleManagementFilters {
    // some of the filters aren't actual records in the db and are identified by their name
    constructor(
        public orderId?: string,
        public ruleId?: string,
        public parameterNameStartsWith?: string,
        public subjectTypeId?: number,
        public mnemonicNameStartsWith?: string,
        public serviceProviderId?: string,
        public serviceId?: string,
        public toolId?: string,
        public indexTypeId?: string,
        public isMemory?: string
    ) {}
}
