// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { asideMenuCssClasses, Replace } from '../shared';

/* eslint-disable @angular-eslint/component-selector */
@Component({
  selector: 'ngx-aside',
  template: `
    <aside class="aside-menu">
      <ng-content></ng-content>
    </aside>
  `
})
export class NgxAsideComponent implements OnInit, OnDestroy {
  @Input() display: any;
  @Input() fixed: boolean;
  @Input() offCanvas: boolean;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    Replace(this.el);
    this.isFixed();
    this.isOffCanvas();
    this.displayBreakpoint();
  }

  ngOnDestroy(): void {
      document.body.classList.remove('aside-menu-fixed');
  }

  isFixed(): void {
    if (this.fixed) { document.querySelector('body').classList.add('aside-menu-fixed'); }
  }

  isOffCanvas(): void {
    if (this.offCanvas) { document.querySelector('body').classList.add('aside-menu-off-canvas'); }
  }

  displayBreakpoint(): void {
    if (this.display !== false ) {
      let cssClass;
      this.display ? cssClass = `aside-menu-${this.display}-show` : cssClass = asideMenuCssClasses[0];
      document.querySelector('body').classList.add(cssClass);
    }
  }
}
