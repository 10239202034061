// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { UntypedFormGroup, ValidationErrors, AbstractControl, UntypedFormArray } from '@angular/forms';

export interface AllValidationErrors {
    control_name: string;
    error_name: string;
    error_value: any;
}

export function getFormValidationErrors(control: AbstractControl, name: string = null): AllValidationErrors[] {
    let errors: AllValidationErrors[] = [];
    extractErrors(name, control.errors, errors);

    if (control instanceof UntypedFormArray || control instanceof UntypedFormGroup) {
        Object.keys(control.controls).forEach(key => {
            const child = control.controls[key];
            errors = errors.concat(getFormValidationErrors(child, key));
        });
    }

    return errors;
}

function extractErrors(controlKey: string, controlErrors: ValidationErrors, errors: AllValidationErrors[]) {
    if (controlErrors !== null) {
        Object.keys(controlErrors).forEach(keyError => {
            errors.push({
                control_name: controlKey,
                error_name: keyError,
                error_value: controlErrors[keyError]
            });
        });
    }
}
