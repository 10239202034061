//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

export class KpiIssue {
    public id: number;

    public startTimeUtc: Date;
    public endTimeUtc: Date;

    public categoryService: string;
    public categoryTypeName: string;
    public subjectType: string;
    public subjectName: string;
    public ruleId: number;
    public ruleName: string;

    public isTimeIssue: boolean;
    public isDepthIssue: boolean;
    public isProblemTime: boolean;
    public problemTimeDuration: number;

    // text because of how the odata works and handles easier comparisons with enums
    public reasonCode: number;
    public reasonCodeDescription: string;
    public comment: string;
    public status: string;
}
