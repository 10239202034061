// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

export function getPropertyName<T>(propertyFunction: (x: T) => any): keyof T;
export function getPropertyName(propertyFunction: (x: any) => any) {
    // TODO: [GP] it will be good to integrate with ts-nameof library  instead of this regex magic
    //       (I had problems to use it in Angular, but probably it's some small configuration or the usage of ts-patch)
    // eslint-disable-next-line no-useless-escape
    return /\.([^\.;]+)(;?\s*})?$/.exec(propertyFunction.toString())[1];
}
