// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

enum TimeZoneStatus {
    Canonical = 'Canonical',
    Alias = 'Alias',
    Deprecated = 'Deprecated',
    Invalid = 'Invalid',
}

class TimeZoneInfo {
    countryCode?: string;
    name: string;
    label?: string;
    portionOfCountry?: string;
    status: string;
    utcOffset: string;
    utcOffsetValue?: number;
    utcDstOffset: string;
}

/** Full list of timezones from AINA standard. From https://en.wikipedia.org/wiki/List_of_tz_database_time_zones */
export const AINA_FullTzList: TimeZoneInfo[] = [
    {
        'countryCode': 'CI',
        'name': 'Africa/Abidjan',
        'status': 'Canonical',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'GH',
        'name': 'Africa/Accra',
        'status': 'Canonical',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'ET',
        'name': 'Africa/Addis_Ababa',
        'status': 'Alias',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'DZ',
        'name': 'Africa/Algiers',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'ER',
        'name': 'Africa/Asmara',
        'status': 'Alias',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'ML',
        'name': 'Africa/Bamako',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'CF',
        'name': 'Africa/Bangui',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'GM',
        'name': 'Africa/Banjul',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'GW',
        'name': 'Africa/Bissau',
        'status': 'Canonical',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'MW',
        'name': 'Africa/Blantyre',
        'status': 'Alias',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'CG',
        'name': 'Africa/Brazzaville',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'BI',
        'name': 'Africa/Bujumbura',
        'status': 'Alias',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'EG',
        'name': 'Africa/Cairo',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'MA',
        'name': 'Africa/Casablanca',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'ES',
        'name': 'Africa/Ceuta',
        'portionOfCountry': 'Ceuta, Melilla',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'GN',
        'name': 'Africa/Conakry',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'SN',
        'name': 'Africa/Dakar',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'TZ',
        'name': 'Africa/Dar_es_Salaam',
        'status': 'Alias',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'DJ',
        'name': 'Africa/Djibouti',
        'status': 'Alias',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'CM',
        'name': 'Africa/Douala',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'EH',
        'name': 'Africa/El_Aaiun',
        'status': 'Canonical',
        'utcOffset': '+00:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'SL',
        'name': 'Africa/Freetown',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'BW',
        'name': 'Africa/Gaborone',
        'status': 'Alias',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'ZW',
        'name': 'Africa/Harare',
        'status': 'Alias',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'ZA',
        'name': 'Africa/Johannesburg',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'SS',
        'name': 'Africa/Juba',
        'status': 'Canonical',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'UG',
        'name': 'Africa/Kampala',
        'status': 'Alias',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'SD',
        'name': 'Africa/Khartoum',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'RW',
        'name': 'Africa/Kigali',
        'status': 'Alias',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'CD',
        'name': 'Africa/Kinshasa',
        'portionOfCountry': 'Dem. Rep. of Congo (west)',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'NG',
        'name': 'Africa/Lagos',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'GA',
        'name': 'Africa/Libreville',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'TG',
        'name': 'Africa/Lome',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'AO',
        'name': 'Africa/Luanda',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'CD',
        'name': 'Africa/Lubumbashi',
        'portionOfCountry': 'Dem. Rep. of Congo (east)',
        'status': 'Alias',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'ZM',
        'name': 'Africa/Lusaka',
        'status': 'Alias',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'GQ',
        'name': 'Africa/Malabo',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'MZ',
        'name': 'Africa/Maputo',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'LS',
        'name': 'Africa/Maseru',
        'status': 'Alias',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'SZ',
        'name': 'Africa/Mbabane',
        'status': 'Alias',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'SO',
        'name': 'Africa/Mogadishu',
        'status': 'Alias',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'LR',
        'name': 'Africa/Monrovia',
        'status': 'Canonical',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'KE',
        'name': 'Africa/Nairobi',
        'status': 'Canonical',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'TD',
        'name': 'Africa/Ndjamena',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'NE',
        'name': 'Africa/Niamey',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'MR',
        'name': 'Africa/Nouakchott',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'BF',
        'name': 'Africa/Ouagadougou',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'BJ',
        'name': 'Africa/Porto-Novo',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'ST',
        'name': 'Africa/Sao_Tome',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'name': 'Africa/Timbuktu',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'LY',
        'name': 'Africa/Tripoli',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'TN',
        'name': 'Africa/Tunis',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'NA',
        'name': 'Africa/Windhoek',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Adak',
        'portionOfCountry': 'Aleutian Islands',
        'status': 'Canonical',
        'utcOffset': '-10:00',
        'utcDstOffset': '-09:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Anchorage',
        'portionOfCountry': 'Alaska (most areas)',
        'status': 'Canonical',
        'utcOffset': '-09:00',
        'utcDstOffset': '-08:00'
    },
    {
        'countryCode': 'AI',
        'name': 'America/Anguilla',
        'status': 'Alias',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'AG',
        'name': 'America/Antigua',
        'status': 'Alias',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'BR',
        'name': 'America/Araguaina',
        'portionOfCountry': 'Tocantins',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'AR',
        'name': 'America/Argentina/Buenos_Aires',
        'portionOfCountry': 'Buenos Aires (BA, CF)',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'AR',
        'name': 'America/Argentina/Catamarca',
        'portionOfCountry': 'Catamarca (CT); Chubut (CH)',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'name': 'America/Argentina/ComodRivadavia',
        'status': 'Alias',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'AR',
        'name': 'America/Argentina/Cordoba',
        'portionOfCountry': 'Argentina (most areas: CB, CC, CN, ER, FM, MN, SE, SF)',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'AR',
        'name': 'America/Argentina/Jujuy',
        'portionOfCountry': 'Jujuy (JY)',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'AR',
        'name': 'America/Argentina/La_Rioja',
        'portionOfCountry': 'La Rioja (LR)',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'AR',
        'name': 'America/Argentina/Mendoza',
        'portionOfCountry': 'Mendoza (MZ)',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'AR',
        'name': 'America/Argentina/Rio_Gallegos',
        'portionOfCountry': 'Santa Cruz (SC)',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'AR',
        'name': 'America/Argentina/Salta',
        'portionOfCountry': 'Salta (SA, LP, NQ, RN)',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'AR',
        'name': 'America/Argentina/San_Juan',
        'portionOfCountry': 'San Juan (SJ)',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'AR',
        'name': 'America/Argentina/San_Luis',
        'portionOfCountry': 'San Luis (SL)',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'AR',
        'name': 'America/Argentina/Tucuman',
        'portionOfCountry': 'Tucuman (TM)',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'AR',
        'name': 'America/Argentina/Ushuaia',
        'portionOfCountry': 'Tierra del Fuego (TF)',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'AW',
        'name': 'America/Aruba',
        'status': 'Alias',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'PY',
        'name': 'America/Asuncion',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Atikokan',
        'portionOfCountry': 'EST - ON (Atikokan); NU (Coral H)',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-05:00'
    },
    {
        'name': 'America/Atka',
        'status': 'Alias',
        'utcOffset': '-10:00',
        'utcDstOffset': '-09:00'
    },
    {
        'countryCode': 'BR',
        'name': 'America/Bahia',
        'portionOfCountry': 'Bahia',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'MX',
        'name': 'America/Bahia_Banderas',
        'portionOfCountry': 'Central Time - Bahia de Banderas',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'BB',
        'name': 'America/Barbados',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'BR',
        'name': 'America/Belem',
        'portionOfCountry': 'Para (east); Amapa',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'BZ',
        'name': 'America/Belize',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-06:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Blanc-Sablon',
        'portionOfCountry': 'AST - QC (Lower North Shore)',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'BR',
        'name': 'America/Boa_Vista',
        'portionOfCountry': 'Roraima',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'CO',
        'name': 'America/Bogota',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Boise',
        'portionOfCountry': 'Mountain - ID (south); OR (east)',
        'status': 'Canonical',
        'utcOffset': '-07:00',
        'utcDstOffset': '-06:00'
    },
    {
        'name': 'America/Buenos_Aires',
        'status': 'Alias',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Cambridge_Bay',
        'portionOfCountry': 'Mountain - NU (west)',
        'status': 'Canonical',
        'utcOffset': '-07:00',
        'utcDstOffset': '-06:00'
    },
    {
        'countryCode': 'BR',
        'name': 'America/Campo_Grande',
        'portionOfCountry': 'Mato Grosso do Sul',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'MX',
        'name': 'America/Cancun',
        'portionOfCountry': 'Eastern Standard Time - Quintana Roo',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'VE',
        'name': 'America/Caracas',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'name': 'America/Catamarca',
        'status': 'Alias',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'GF',
        'name': 'America/Cayenne',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'KY',
        'name': 'America/Cayman',
        'status': 'Alias',
        'utcOffset': '-05:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Chicago',
        'portionOfCountry': 'Central (most areas)',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'MX',
        'name': 'America/Chihuahua',
        'portionOfCountry': 'Mountain Time - Chihuahua (most areas)',
        'status': 'Canonical',
        'utcOffset': '-07:00',
        'utcDstOffset': '-06:00'
    },
    {
        'name': 'America/Coral_Harbour',
        'status': 'Alias',
        'utcOffset': '-05:00',
        'utcDstOffset': '-05:00'
    },
    {
        'name': 'America/Cordoba',
        'status': 'Alias',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'CR',
        'name': 'America/Costa_Rica',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-06:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Creston',
        'portionOfCountry': 'MST - BC (Creston)',
        'status': 'Canonical',
        'utcOffset': '-07:00',
        'utcDstOffset': '-07:00'
    },
    {
        'countryCode': 'BR',
        'name': 'America/Cuiaba',
        'portionOfCountry': 'Mato Grosso',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'CW',
        'name': 'America/Curacao',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'GL',
        'name': 'America/Danmarkshavn',
        'portionOfCountry': 'National Park (east coast)',
        'status': 'Canonical',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Dawson',
        'portionOfCountry': 'Pacific - Yukon (north)',
        'status': 'Canonical',
        'utcOffset': '-08:00',
        'utcDstOffset': '-07:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Dawson_Creek',
        'portionOfCountry': 'MST - BC (Dawson Cr, Ft St John)',
        'status': 'Canonical',
        'utcOffset': '-07:00',
        'utcDstOffset': '-07:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Denver',
        'portionOfCountry': 'Mountain (most areas)',
        'status': 'Canonical',
        'utcOffset': '-07:00',
        'utcDstOffset': '-06:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Detroit',
        'portionOfCountry': 'Eastern - MI (most areas)',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'DM',
        'name': 'America/Dominica',
        'status': 'Alias',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Edmonton',
        'portionOfCountry': 'Mountain - AB; BC (E); SK (W)',
        'status': 'Canonical',
        'utcOffset': '-07:00',
        'utcDstOffset': '-06:00'
    },
    {
        'countryCode': 'BR',
        'name': 'America/Eirunepe',
        'portionOfCountry': 'Amazonas (west)',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'SV',
        'name': 'America/El_Salvador',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-06:00'
    },
    {
        'name': 'America/Ensenada',
        'status': 'Alias',
        'utcOffset': '-08:00',
        'utcDstOffset': '-07:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Fort_Nelson',
        'portionOfCountry': 'MST - BC (Ft Nelson)',
        'status': 'Canonical',
        'utcOffset': '-07:00',
        'utcDstOffset': '-07:00'
    },
    {
        'name': 'America/Fort_Wayne',
        'status': 'Alias',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'BR',
        'name': 'America/Fortaleza',
        'portionOfCountry': 'Brazil (northeast: MA, PI, CE, RN, PB)',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Glace_Bay',
        'portionOfCountry': 'Atlantic - NS (Cape Breton)',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'GL',
        'name': 'America/Godthab',
        'portionOfCountry': 'Greenland (most areas)',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-02:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Goose_Bay',
        'portionOfCountry': 'Atlantic - Labrador (most areas)',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'TC',
        'name': 'America/Grand_Turk',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'GD',
        'name': 'America/Grenada',
        'status': 'Alias',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'GP',
        'name': 'America/Guadeloupe',
        'status': 'Alias',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'GT',
        'name': 'America/Guatemala',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-06:00'
    },
    {
        'countryCode': 'EC',
        'name': 'America/Guayaquil',
        'portionOfCountry': 'Ecuador (mainland)',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'GY',
        'name': 'America/Guyana',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Halifax',
        'portionOfCountry': 'Atlantic - NS (most areas); PE',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'CU',
        'name': 'America/Havana',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'MX',
        'name': 'America/Hermosillo',
        'portionOfCountry': 'Mountain Standard Time - Sonora',
        'status': 'Canonical',
        'utcOffset': '-07:00',
        'utcDstOffset': '-07:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Indiana/Indianapolis',
        'portionOfCountry': 'Eastern - IN (most areas)',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Indiana/Knox',
        'portionOfCountry': 'Central - IN (Starke)',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Indiana/Marengo',
        'portionOfCountry': 'Eastern - IN (Crawford)',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Indiana/Petersburg',
        'portionOfCountry': 'Eastern - IN (Pike)',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Indiana/Tell_City',
        'portionOfCountry': 'Central - IN (Perry)',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Indiana/Vevay',
        'portionOfCountry': 'Eastern - IN (Switzerland)',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Indiana/Vincennes',
        'portionOfCountry': 'Eastern - IN (Da, Du, K, Mn)',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Indiana/Winamac',
        'portionOfCountry': 'Eastern - IN (Pulaski)',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'name': 'America/Indianapolis',
        'status': 'Alias',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Inuvik',
        'portionOfCountry': 'Mountain - NT (west)',
        'status': 'Canonical',
        'utcOffset': '-07:00',
        'utcDstOffset': '-06:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Iqaluit',
        'portionOfCountry': 'Eastern - NU (most east areas)',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'JM',
        'name': 'America/Jamaica',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-05:00'
    },
    {
        'name': 'America/Jujuy',
        'status': 'Alias',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Juneau',
        'portionOfCountry': 'Alaska - Juneau area',
        'status': 'Canonical',
        'utcOffset': '-09:00',
        'utcDstOffset': '-08:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Kentucky/Louisville',
        'portionOfCountry': 'Eastern - KY (Louisville area)',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Kentucky/Monticello',
        'portionOfCountry': 'Eastern - KY (Wayne)',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'name': 'America/Knox_IN',
        'status': 'Alias',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'BQ',
        'name': 'America/Kralendijk',
        'status': 'Alias',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'BO',
        'name': 'America/La_Paz',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'PE',
        'name': 'America/Lima',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Los_Angeles',
        'portionOfCountry': 'Pacific',
        'status': 'Canonical',
        'utcOffset': '-08:00',
        'utcDstOffset': '-07:00'
    },
    {
        'name': 'America/Louisville',
        'status': 'Alias',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'SX',
        'name': 'America/Lower_Princes',
        'status': 'Alias',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'BR',
        'name': 'America/Maceio',
        'portionOfCountry': 'Alagoas, Sergipe',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'NI',
        'name': 'America/Managua',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-06:00'
    },
    {
        'countryCode': 'BR',
        'name': 'America/Manaus',
        'portionOfCountry': 'Amazonas (east)',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'MF',
        'name': 'America/Marigot',
        'status': 'Alias',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'MQ',
        'name': 'America/Martinique',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'MX',
        'name': 'America/Matamoros',
        'portionOfCountry': 'Central Time US - Coahuila, Nuevo Leon, Tamaulipas (US border)',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'MX',
        'name': 'America/Mazatlan',
        'portionOfCountry': 'Mountain Time - Baja California Sur, Nayarit, Sinaloa',
        'status': 'Canonical',
        'utcOffset': '-07:00',
        'utcDstOffset': '-06:00'
    },
    {
        'name': 'America/Mendoza',
        'status': 'Alias',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Menominee',
        'portionOfCountry': 'Central - MI (Wisconsin border)',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'MX',
        'name': 'America/Merida',
        'portionOfCountry': 'Central Time - Campeche, Yucatan',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Metlakatla',
        'portionOfCountry': 'Alaska - Annette Island',
        'status': 'Canonical',
        'utcOffset': '-09:00',
        'utcDstOffset': '-08:00'
    },
    {
        'countryCode': 'MX',
        'name': 'America/Mexico_City',
        'portionOfCountry': 'Central Time',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'PM',
        'name': 'America/Miquelon',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-02:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Moncton',
        'portionOfCountry': 'Atlantic - New Brunswick',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'MX',
        'name': 'America/Monterrey',
        'portionOfCountry': 'Central Time - Durango; Coahuila, Nuevo Leon, Tamaulipas (most areas)',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'UY',
        'name': 'America/Montevideo',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'name': 'America/Montreal',
        'status': 'Alias',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'MS',
        'name': 'America/Montserrat',
        'status': 'Alias',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'BS',
        'name': 'America/Nassau',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/New_York',
        'portionOfCountry': 'Eastern (most areas)',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Nipigon',
        'portionOfCountry': 'Eastern - ON, QC (no DST 1967-73)',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Nome',
        'portionOfCountry': 'Alaska (west)',
        'status': 'Canonical',
        'utcOffset': '-09:00',
        'utcDstOffset': '-08:00'
    },
    {
        'countryCode': 'BR',
        'name': 'America/Noronha',
        'portionOfCountry': 'Atlantic islands',
        'status': 'Canonical',
        'utcOffset': '-02:00',
        'utcDstOffset': '-02:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/North_Dakota/Beulah',
        'portionOfCountry': 'Central - ND (Mercer)',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/North_Dakota/Center',
        'portionOfCountry': 'Central - ND (Oliver)',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/North_Dakota/New_Salem',
        'portionOfCountry': 'Central - ND (Morton rural)',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'MX',
        'name': 'America/Ojinaga',
        'portionOfCountry': 'Mountain Time US - Chihuahua (US border)',
        'status': 'Canonical',
        'utcOffset': '-07:00',
        'utcDstOffset': '-06:00'
    },
    {
        'countryCode': 'PA',
        'name': 'America/Panama',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Pangnirtung',
        'portionOfCountry': 'Eastern - NU (Pangnirtung)',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'SR',
        'name': 'America/Paramaribo',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Phoenix',
        'portionOfCountry': 'MST - Arizona (except Navajo)',
        'status': 'Canonical',
        'utcOffset': '-07:00',
        'utcDstOffset': '-07:00'
    },
    {
        'countryCode': 'TT',
        'name': 'America/Port_of_Spain',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'HT',
        'name': 'America/Port-au-Prince',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'name': 'America/Porto_Acre',
        'status': 'Alias',
        'utcOffset': '-05:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'BR',
        'name': 'America/Porto_Velho',
        'portionOfCountry': 'Rondonia',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'PR',
        'name': 'America/Puerto_Rico',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'CL',
        'name': 'America/Punta_Arenas',
        'portionOfCountry': 'Region of Magallanes',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Rainy_River',
        'portionOfCountry': 'Central - ON (Rainy R, Ft Frances)',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Rankin_Inlet',
        'portionOfCountry': 'Central - NU (central)',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'BR',
        'name': 'America/Recife',
        'portionOfCountry': 'Pernambuco',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Regina',
        'portionOfCountry': 'CST - SK (most areas)',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-06:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Resolute',
        'portionOfCountry': 'Central - NU (Resolute)',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'BR',
        'name': 'America/Rio_Branco',
        'portionOfCountry': 'Acre',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-05:00'
    },
    {
        'name': 'America/Rosario',
        'status': 'Alias',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'name': 'America/Santa_Isabel',
        'status': 'Alias',
        'utcOffset': '-08:00',
        'utcDstOffset': '-07:00'
    },
    {
        'countryCode': 'BR',
        'name': 'America/Santarem',
        'portionOfCountry': 'Para (west)',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'CL',
        'name': 'America/Santiago',
        'portionOfCountry': 'Chile (most areas)',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'DO',
        'name': 'America/Santo_Domingo',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'BR',
        'name': 'America/Sao_Paulo',
        'portionOfCountry': 'Brazil (southeast: GO, DF, MG, ES, RJ, SP, PR, SC, RS)',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-02:00'
    },
    {
        'countryCode': 'GL',
        'name': 'America/Scoresbysund',
        'portionOfCountry': 'Scoresbysund/Ittoqqortoormiit',
        'status': 'Canonical',
        'utcOffset': '-01:00',
        'utcDstOffset': '+00:00'
    },
    {
        'name': 'America/Shiprock',
        'status': 'Alias',
        'utcOffset': '-07:00',
        'utcDstOffset': '-06:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Sitka',
        'portionOfCountry': 'Alaska - Sitka area',
        'status': 'Canonical',
        'utcOffset': '-09:00',
        'utcDstOffset': '-08:00'
    },
    {
        'countryCode': 'BL',
        'name': 'America/St_Barthelemy',
        'status': 'Alias',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/St_Johns',
        'portionOfCountry': 'Newfoundland; Labrador (southeast)',
        'status': 'Canonical',
        'utcOffset': '-03:30',
        'utcDstOffset': '-02:30'
    },
    {
        'countryCode': 'KN',
        'name': 'America/St_Kitts',
        'status': 'Alias',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'LC',
        'name': 'America/St_Lucia',
        'status': 'Alias',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'VI',
        'name': 'America/St_Thomas',
        'status': 'Alias',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'VC',
        'name': 'America/St_Vincent',
        'status': 'Alias',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Swift_Current',
        'portionOfCountry': 'CST - SK (midwest)',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-06:00'
    },
    {
        'countryCode': 'HN',
        'name': 'America/Tegucigalpa',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-06:00'
    },
    {
        'countryCode': 'GL',
        'name': 'America/Thule',
        'portionOfCountry': 'Thule/Pituffik',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Thunder_Bay',
        'portionOfCountry': 'Eastern - ON (Thunder Bay)',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'MX',
        'name': 'America/Tijuana',
        'portionOfCountry': 'Pacific Time US - Baja California',
        'status': 'Canonical',
        'utcOffset': '-08:00',
        'utcDstOffset': '-07:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Toronto',
        'portionOfCountry': 'Eastern - ON, QC (most areas)',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'VG',
        'name': 'America/Tortola',
        'status': 'Alias',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Vancouver',
        'portionOfCountry': 'Pacific - BC (most areas)',
        'status': 'Canonical',
        'utcOffset': '-08:00',
        'utcDstOffset': '-07:00'
    },
    {
        'name': 'America/Virgin',
        'status': 'Alias',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Whitehorse',
        'portionOfCountry': 'Pacific - Yukon (south)',
        'status': 'Canonical',
        'utcOffset': '-08:00',
        'utcDstOffset': '-07:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Winnipeg',
        'portionOfCountry': 'Central - ON (west); Manitoba',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'US',
        'name': 'America/Yakutat',
        'portionOfCountry': 'Alaska - Yakutat',
        'status': 'Canonical',
        'utcOffset': '-09:00',
        'utcDstOffset': '-08:00'
    },
    {
        'countryCode': 'CA',
        'name': 'America/Yellowknife',
        'portionOfCountry': 'Mountain - NT (central)',
        'status': 'Canonical',
        'utcOffset': '-07:00',
        'utcDstOffset': '-06:00'
    },
    {
        'countryCode': 'AQ',
        'name': 'Antarctica/Casey',
        'portionOfCountry': 'Casey',
        'status': 'Canonical',
        'utcOffset': '+11:00',
        'utcDstOffset': '+11:00'
    },
    {
        'countryCode': 'AQ',
        'name': 'Antarctica/Davis',
        'portionOfCountry': 'Davis',
        'status': 'Canonical',
        'utcOffset': '+07:00',
        'utcDstOffset': '+07:00'
    },
    {
        'countryCode': 'AQ',
        'name': 'Antarctica/DumontDUrville',
        'portionOfCountry': 'Dumont-d\'Urville',
        'status': 'Canonical',
        'utcOffset': '+10:00',
        'utcDstOffset': '+10:00'
    },
    {
        'countryCode': 'AU',
        'name': 'Antarctica/Macquarie',
        'portionOfCountry': 'Macquarie Island',
        'status': 'Canonical',
        'utcOffset': '+11:00',
        'utcDstOffset': '+11:00'
    },
    {
        'countryCode': 'AQ',
        'name': 'Antarctica/Mawson',
        'portionOfCountry': 'Mawson',
        'status': 'Canonical',
        'utcOffset': '+05:00',
        'utcDstOffset': '+05:00'
    },
    {
        'countryCode': 'AQ',
        'name': 'Antarctica/McMurdo',
        'portionOfCountry': 'New Zealand time - McMurdo, South Pole',
        'status': 'Alias',
        'utcOffset': '+12:00',
        'utcDstOffset': '+13:00'
    },
    {
        'countryCode': 'AQ',
        'name': 'Antarctica/Palmer',
        'portionOfCountry': 'Palmer',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'AQ',
        'name': 'Antarctica/Rothera',
        'portionOfCountry': 'Rothera',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'name': 'Antarctica/South_Pole',
        'status': 'Alias',
        'utcOffset': '+12:00',
        'utcDstOffset': '+13:00'
    },
    {
        'countryCode': 'AQ',
        'name': 'Antarctica/Syowa',
        'portionOfCountry': 'Syowa',
        'status': 'Canonical',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'AQ',
        'name': 'Antarctica/Troll',
        'portionOfCountry': 'Troll',
        'status': 'Invalid',
        'utcOffset': '+00:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'AQ',
        'name': 'Antarctica/Vostok',
        'portionOfCountry': 'Vostok',
        'status': 'Canonical',
        'utcOffset': '+06:00',
        'utcDstOffset': '+06:00'
    },
    {
        'countryCode': 'SJ',
        'name': 'Arctic/Longyearbyen',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'YE',
        'name': 'Asia/Aden',
        'status': 'Alias',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'KZ',
        'name': 'Asia/Almaty',
        'portionOfCountry': 'Kazakhstan (most areas)',
        'status': 'Canonical',
        'utcOffset': '+06:00',
        'utcDstOffset': '+06:00'
    },
    {
        'countryCode': 'JO',
        'name': 'Asia/Amman',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Asia/Anadyr',
        'portionOfCountry': 'MSK+09 - Bering Sea',
        'status': 'Canonical',
        'utcOffset': '+12:00',
        'utcDstOffset': '+12:00'
    },
    {
        'countryCode': 'KZ',
        'name': 'Asia/Aqtau',
        'portionOfCountry': 'Mangghystau/Mankistau',
        'status': 'Canonical',
        'utcOffset': '+05:00',
        'utcDstOffset': '+05:00'
    },
    {
        'countryCode': 'KZ',
        'name': 'Asia/Aqtobe',
        'portionOfCountry': 'Aqtobe/Aktobe',
        'status': 'Canonical',
        'utcOffset': '+05:00',
        'utcDstOffset': '+05:00'
    },
    {
        'countryCode': 'TM',
        'name': 'Asia/Ashgabat',
        'status': 'Canonical',
        'utcOffset': '+05:00',
        'utcDstOffset': '+05:00'
    },
    {
        'name': 'Asia/Ashkhabad',
        'status': 'Alias',
        'utcOffset': '+05:00',
        'utcDstOffset': '+05:00'
    },
    {
        'countryCode': 'KZ',
        'name': 'Asia/Atyrau',
        'portionOfCountry': 'Atyrau/Atirau/Gur\'yev',
        'status': 'Canonical',
        'utcOffset': '+05:00',
        'utcDstOffset': '+05:00'
    },
    {
        'countryCode': 'IQ',
        'name': 'Asia/Baghdad',
        'status': 'Canonical',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'BH',
        'name': 'Asia/Bahrain',
        'status': 'Alias',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'AZ',
        'name': 'Asia/Baku',
        'status': 'Canonical',
        'utcOffset': '+04:00',
        'utcDstOffset': '+04:00'
    },
    {
        'countryCode': 'TH',
        'name': 'Asia/Bangkok',
        'status': 'Canonical',
        'utcOffset': '+07:00',
        'utcDstOffset': '+07:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Asia/Barnaul',
        'portionOfCountry': 'MSK+04 - Altai',
        'status': 'Canonical',
        'utcOffset': '+07:00',
        'utcDstOffset': '+07:00'
    },
    {
        'countryCode': 'LB',
        'name': 'Asia/Beirut',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'KG',
        'name': 'Asia/Bishkek',
        'status': 'Canonical',
        'utcOffset': '+06:00',
        'utcDstOffset': '+06:00'
    },
    {
        'countryCode': 'BN',
        'name': 'Asia/Brunei',
        'status': 'Canonical',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'name': 'Asia/Calcutta',
        'status': 'Alias',
        'utcOffset': '+05:30',
        'utcDstOffset': '+05:30'
    },
    {
        'countryCode': 'RU',
        'name': 'Asia/Chita',
        'portionOfCountry': 'MSK+06 - Zabaykalsky',
        'status': 'Canonical',
        'utcOffset': '+09:00',
        'utcDstOffset': '+09:00'
    },
    {
        'countryCode': 'MN',
        'name': 'Asia/Choibalsan',
        'portionOfCountry': 'Dornod, Sukhbaatar',
        'status': 'Canonical',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'name': 'Asia/Chongqing',
        'status': 'Alias',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'name': 'Asia/Chungking',
        'status': 'Alias',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'countryCode': 'LK',
        'name': 'Asia/Colombo',
        'status': 'Canonical',
        'utcOffset': '+05:30',
        'utcDstOffset': '+05:30'
    },
    {
        'name': 'Asia/Dacca',
        'status': 'Alias',
        'utcOffset': '+06:00',
        'utcDstOffset': '+06:00'
    },
    {
        'countryCode': 'SY',
        'name': 'Asia/Damascus',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'BD',
        'name': 'Asia/Dhaka',
        'status': 'Canonical',
        'utcOffset': '+06:00',
        'utcDstOffset': '+06:00'
    },
    {
        'countryCode': 'TL',
        'name': 'Asia/Dili',
        'status': 'Canonical',
        'utcOffset': '+09:00',
        'utcDstOffset': '+09:00'
    },
    {
        'countryCode': 'AE',
        'name': 'Asia/Dubai',
        'status': 'Canonical',
        'utcOffset': '+04:00',
        'utcDstOffset': '+04:00'
    },
    {
        'countryCode': 'TJ',
        'name': 'Asia/Dushanbe',
        'status': 'Canonical',
        'utcOffset': '+05:00',
        'utcDstOffset': '+05:00'
    },
    {
        'countryCode': 'CY',
        'name': 'Asia/Famagusta',
        'portionOfCountry': 'Northern Cyprus',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'PS',
        'name': 'Asia/Gaza',
        'portionOfCountry': 'Gaza Strip',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'name': 'Asia/Harbin',
        'status': 'Alias',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'countryCode': 'PS',
        'name': 'Asia/Hebron',
        'portionOfCountry': 'West Bank',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'VN',
        'name': 'Asia/Ho_Chi_Minh',
        'status': 'Canonical',
        'utcOffset': '+07:00',
        'utcDstOffset': '+07:00'
    },
    {
        'countryCode': 'HK',
        'name': 'Asia/Hong_Kong',
        'status': 'Canonical',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'countryCode': 'MN',
        'name': 'Asia/Hovd',
        'portionOfCountry': 'Bayan-Olgiy, Govi-Altai, Hovd, Uvs, Zavkhan',
        'status': 'Canonical',
        'utcOffset': '+07:00',
        'utcDstOffset': '+07:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Asia/Irkutsk',
        'portionOfCountry': 'MSK+05 - Irkutsk, Buryatia',
        'status': 'Canonical',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'name': 'Asia/Istanbul',
        'status': 'Alias',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'ID',
        'name': 'Asia/Jakarta',
        'portionOfCountry': 'Java, Sumatra',
        'status': 'Canonical',
        'utcOffset': '+07:00',
        'utcDstOffset': '+07:00'
    },
    {
        'countryCode': 'ID',
        'name': 'Asia/Jayapura',
        'portionOfCountry': 'New Guinea (West Papua / Irian Jaya); Malukus/Moluccas',
        'status': 'Canonical',
        'utcOffset': '+09:00',
        'utcDstOffset': '+09:00'
    },
    {
        'countryCode': 'IL',
        'name': 'Asia/Jerusalem',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'AF',
        'name': 'Asia/Kabul',
        'status': 'Canonical',
        'utcOffset': '+04:30',
        'utcDstOffset': '+04:30'
    },
    {
        'countryCode': 'RU',
        'name': 'Asia/Kamchatka',
        'portionOfCountry': 'MSK+09 - Kamchatka',
        'status': 'Canonical',
        'utcOffset': '+12:00',
        'utcDstOffset': '+12:00'
    },
    {
        'countryCode': 'PK',
        'name': 'Asia/Karachi',
        'status': 'Canonical',
        'utcOffset': '+05:00',
        'utcDstOffset': '+05:00'
    },
    {
        'name': 'Asia/Kashgar',
        'status': 'Alias',
        'utcOffset': '+06:00',
        'utcDstOffset': '+06:00'
    },
    {
        'countryCode': 'NP',
        'name': 'Asia/Kathmandu',
        'status': 'Canonical',
        'utcOffset': '+05:45',
        'utcDstOffset': '+05:45'
    },
    {
        'name': 'Asia/Katmandu',
        'status': 'Alias',
        'utcOffset': '+05:45',
        'utcDstOffset': '+05:45'
    },
    {
        'countryCode': 'RU',
        'name': 'Asia/Khandyga',
        'portionOfCountry': 'MSK+06 - Tomponsky, Ust-Maysky',
        'status': 'Canonical',
        'utcOffset': '+09:00',
        'utcDstOffset': '+09:00'
    },
    {
        'countryCode': 'IN',
        'name': 'Asia/Kolkata',
        'status': 'Canonical',
        'utcOffset': '+05:30',
        'utcDstOffset': '+05:30'
    },
    {
        'countryCode': 'RU',
        'name': 'Asia/Krasnoyarsk',
        'portionOfCountry': 'MSK+04 - Krasnoyarsk area',
        'status': 'Canonical',
        'utcOffset': '+07:00',
        'utcDstOffset': '+07:00'
    },
    {
        'countryCode': 'MY',
        'name': 'Asia/Kuala_Lumpur',
        'portionOfCountry': 'Malaysia (peninsula)',
        'status': 'Canonical',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'countryCode': 'MY',
        'name': 'Asia/Kuching',
        'portionOfCountry': 'Sabah, Sarawak',
        'status': 'Canonical',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'countryCode': 'KW',
        'name': 'Asia/Kuwait',
        'status': 'Alias',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'name': 'Asia/Macao',
        'status': 'Alias',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'countryCode': 'MO',
        'name': 'Asia/Macau',
        'status': 'Canonical',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Asia/Magadan',
        'portionOfCountry': 'MSK+08 - Magadan',
        'status': 'Canonical',
        'utcOffset': '+11:00',
        'utcDstOffset': '+11:00'
    },
    {
        'countryCode': 'ID',
        'name': 'Asia/Makassar',
        'portionOfCountry': 'Borneo (east, south); Sulawesi/Celebes, Bali, Nusa Tengarra; Timor (west)',
        'status': 'Canonical',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'countryCode': 'PH',
        'name': 'Asia/Manila',
        'status': 'Canonical',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'countryCode': 'OM',
        'name': 'Asia/Muscat',
        'status': 'Alias',
        'utcOffset': '+04:00',
        'utcDstOffset': '+04:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Asia/Novokuznetsk',
        'portionOfCountry': 'MSK+04 - Kemerovo',
        'status': 'Canonical',
        'utcOffset': '+07:00',
        'utcDstOffset': '+07:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Asia/Novosibirsk',
        'portionOfCountry': 'MSK+04 - Novosibirsk',
        'status': 'Canonical',
        'utcOffset': '+07:00',
        'utcDstOffset': '+07:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Asia/Omsk',
        'portionOfCountry': 'MSK+03 - Omsk',
        'status': 'Canonical',
        'utcOffset': '+06:00',
        'utcDstOffset': '+06:00'
    },
    {
        'countryCode': 'KZ',
        'name': 'Asia/Oral',
        'portionOfCountry': 'West Kazakhstan',
        'status': 'Canonical',
        'utcOffset': '+05:00',
        'utcDstOffset': '+05:00'
    },
    {
        'countryCode': 'KH',
        'name': 'Asia/Phnom_Penh',
        'status': 'Alias',
        'utcOffset': '+07:00',
        'utcDstOffset': '+07:00'
    },
    {
        'countryCode': 'ID',
        'name': 'Asia/Pontianak',
        'portionOfCountry': 'Borneo (west, central)',
        'status': 'Canonical',
        'utcOffset': '+07:00',
        'utcDstOffset': '+07:00'
    },
    {
        'countryCode': 'KP',
        'name': 'Asia/Pyongyang',
        'status': 'Canonical',
        'utcOffset': '+09:00',
        'utcDstOffset': '+09:00'
    },
    {
        'countryCode': 'QA',
        'name': 'Asia/Qatar',
        'status': 'Canonical',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'KZ',
        'name': 'Asia/Qyzylorda',
        'portionOfCountry': 'Qyzylorda/Kyzylorda/Kzyl-Orda',
        'status': 'Canonical',
        'utcOffset': '+05:00',
        'utcDstOffset': '+05:00'
    },
    {
        'countryCode': 'MM',
        'name': 'Asia/Rangoon',
        'status': 'Alias',
        'utcOffset': '+06:30',
        'utcDstOffset': '+06:30'
    },
    {
        'countryCode': 'SA',
        'name': 'Asia/Riyadh',
        'status': 'Canonical',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'name': 'Asia/Saigon',
        'status': 'Alias',
        'utcOffset': '+07:00',
        'utcDstOffset': '+07:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Asia/Sakhalin',
        'portionOfCountry': 'MSK+08 - Sakhalin Island',
        'status': 'Canonical',
        'utcOffset': '+11:00',
        'utcDstOffset': '+11:00'
    },
    {
        'countryCode': 'UZ',
        'name': 'Asia/Samarkand',
        'portionOfCountry': 'Uzbekistan (west)',
        'status': 'Canonical',
        'utcOffset': '+05:00',
        'utcDstOffset': '+05:00'
    },
    {
        'countryCode': 'KR',
        'name': 'Asia/Seoul',
        'status': 'Canonical',
        'utcOffset': '+09:00',
        'utcDstOffset': '+09:00'
    },
    {
        'countryCode': 'CN',
        'name': 'Asia/Shanghai',
        'portionOfCountry': 'Beijing Time',
        'status': 'Canonical',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'countryCode': 'SG',
        'name': 'Asia/Singapore',
        'status': 'Canonical',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Asia/Srednekolymsk',
        'portionOfCountry': 'MSK+08 - Sakha (E); North Kuril Is',
        'status': 'Canonical',
        'utcOffset': '+11:00',
        'utcDstOffset': '+11:00'
    },
    {
        'countryCode': 'TW',
        'name': 'Asia/Taipei',
        'status': 'Canonical',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'countryCode': 'UZ',
        'name': 'Asia/Tashkent',
        'portionOfCountry': 'Uzbekistan (east)',
        'status': 'Canonical',
        'utcOffset': '+05:00',
        'utcDstOffset': '+05:00'
    },
    {
        'countryCode': 'GE',
        'name': 'Asia/Tbilisi',
        'status': 'Canonical',
        'utcOffset': '+04:00',
        'utcDstOffset': '+04:00'
    },
    {
        'countryCode': 'IR',
        'name': 'Asia/Tehran',
        'status': 'Canonical',
        'utcOffset': '+03:30',
        'utcDstOffset': '+04:30'
    },
    {
        'name': 'Asia/Tel_Aviv',
        'status': 'Alias',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'name': 'Asia/Thimbu',
        'status': 'Alias',
        'utcOffset': '+06:00',
        'utcDstOffset': '+06:00'
    },
    {
        'countryCode': 'BT',
        'name': 'Asia/Thimphu',
        'status': 'Canonical',
        'utcOffset': '+06:00',
        'utcDstOffset': '+06:00'
    },
    {
        'countryCode': 'JP',
        'name': 'Asia/Tokyo',
        'status': 'Canonical',
        'utcOffset': '+09:00',
        'utcDstOffset': '+09:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Asia/Tomsk',
        'portionOfCountry': 'MSK+04 - Tomsk',
        'status': 'Canonical',
        'utcOffset': '+07:00',
        'utcDstOffset': '+07:00'
    },
    {
        'name': 'Asia/Ujung_Pandang',
        'status': 'Alias',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'countryCode': 'MN',
        'name': 'Asia/Ulaanbaatar',
        'portionOfCountry': 'Mongolia (most areas)',
        'status': 'Canonical',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'name': 'Asia/Ulan_Bator',
        'status': 'Alias',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'countryCode': 'CN',
        'name': 'Asia/Urumqi',
        'portionOfCountry': 'Xinjiang Time',
        'status': 'Canonical',
        'utcOffset': '+06:00',
        'utcDstOffset': '+06:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Asia/Ust-Nera',
        'portionOfCountry': 'MSK+07 - Oymyakonsky',
        'status': 'Canonical',
        'utcOffset': '+10:00',
        'utcDstOffset': '+10:00'
    },
    {
        'countryCode': 'LA',
        'name': 'Asia/Vientiane',
        'status': 'Alias',
        'utcOffset': '+07:00',
        'utcDstOffset': '+07:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Asia/Vladivostok',
        'portionOfCountry': 'MSK+07 - Amur River',
        'status': 'Canonical',
        'utcOffset': '+10:00',
        'utcDstOffset': '+10:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Asia/Yakutsk',
        'portionOfCountry': 'MSK+06 - Lena River',
        'status': 'Canonical',
        'utcOffset': '+09:00',
        'utcDstOffset': '+09:00'
    },
    {
        'countryCode': 'MM',
        'name': 'Asia/Yangon',
        'status': 'Canonical',
        'utcOffset': '+06:30',
        'utcDstOffset': '+06:30'
    },
    {
        'countryCode': 'RU',
        'name': 'Asia/Yekaterinburg',
        'portionOfCountry': 'MSK+02 - Urals',
        'status': 'Canonical',
        'utcOffset': '+05:00',
        'utcDstOffset': '+05:00'
    },
    {
        'countryCode': 'AM',
        'name': 'Asia/Yerevan',
        'status': 'Canonical',
        'utcOffset': '+04:00',
        'utcDstOffset': '+04:00'
    },
    {
        'countryCode': 'PT',
        'name': 'Atlantic/Azores',
        'portionOfCountry': 'Azores',
        'status': 'Canonical',
        'utcOffset': '-01:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'BM',
        'name': 'Atlantic/Bermuda',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-03:00'
    },
    {
        'countryCode': 'ES',
        'name': 'Atlantic/Canary',
        'portionOfCountry': 'Canary Islands',
        'status': 'Canonical',
        'utcOffset': '+00:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'CV',
        'name': 'Atlantic/Cape_Verde',
        'status': 'Canonical',
        'utcOffset': '-01:00',
        'utcDstOffset': '-01:00'
    },
    {
        'name': 'Atlantic/Faeroe',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'FO',
        'name': 'Atlantic/Faroe',
        'status': 'Canonical',
        'utcOffset': '+00:00',
        'utcDstOffset': '+01:00'
    },
    {
        'name': 'Atlantic/Jan_Mayen',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'PT',
        'name': 'Atlantic/Madeira',
        'portionOfCountry': 'Madeira Islands',
        'status': 'Canonical',
        'utcOffset': '+00:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'IS',
        'name': 'Atlantic/Reykjavik',
        'status': 'Canonical',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'GS',
        'name': 'Atlantic/South_Georgia',
        'status': 'Canonical',
        'utcOffset': '-02:00',
        'utcDstOffset': '-02:00'
    },
    {
        'countryCode': 'SH',
        'name': 'Atlantic/St_Helena',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'FK',
        'name': 'Atlantic/Stanley',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'name': 'Australia/ACT',
        'status': 'Deprecated',
        'utcOffset': '+10:00',
        'utcDstOffset': '+11:00'
    },
    {
        'countryCode': 'AU',
        'name': 'Australia/Adelaide',
        'portionOfCountry': 'South Australia',
        'status': 'Canonical',
        'utcOffset': '+09:30',
        'utcDstOffset': '+10:30'
    },
    {
        'countryCode': 'AU',
        'name': 'Australia/Brisbane',
        'portionOfCountry': 'Queensland (most areas)',
        'status': 'Canonical',
        'utcOffset': '+10:00',
        'utcDstOffset': '+10:00'
    },
    {
        'countryCode': 'AU',
        'name': 'Australia/Broken_Hill',
        'portionOfCountry': 'New South Wales (Yancowinna)',
        'status': 'Canonical',
        'utcOffset': '+09:30',
        'utcDstOffset': '+10:30'
    },
    {
        'name': 'Australia/Canberra',
        'status': 'Alias',
        'utcOffset': '+10:00',
        'utcDstOffset': '+11:00'
    },
    {
        'countryCode': 'AU',
        'name': 'Australia/Currie',
        'portionOfCountry': 'Tasmania (King Island)',
        'status': 'Canonical',
        'utcOffset': '+10:00',
        'utcDstOffset': '+11:00'
    },
    {
        'countryCode': 'AU',
        'name': 'Australia/Darwin',
        'portionOfCountry': 'Northern Territory',
        'status': 'Canonical',
        'utcOffset': '+09:30',
        'utcDstOffset': '+09:30'
    },
    {
        'countryCode': 'AU',
        'name': 'Australia/Eucla',
        'portionOfCountry': 'Western Australia (Eucla)',
        'status': 'Canonical',
        'utcOffset': '+08:45',
        'utcDstOffset': '+08:45'
    },
    {
        'countryCode': 'AU',
        'name': 'Australia/Hobart',
        'portionOfCountry': 'Tasmania (most areas)',
        'status': 'Canonical',
        'utcOffset': '+10:00',
        'utcDstOffset': '+11:00'
    },
    {
        'name': 'Australia/LHI',
        'status': 'Deprecated',
        'utcOffset': '+10:30',
        'utcDstOffset': '+11:00'
    },
    {
        'countryCode': 'AU',
        'name': 'Australia/Lindeman',
        'portionOfCountry': 'Queensland (Whitsunday Islands)',
        'status': 'Canonical',
        'utcOffset': '+10:00',
        'utcDstOffset': '+10:00'
    },
    {
        'countryCode': 'AU',
        'name': 'Australia/Lord_Howe',
        'portionOfCountry': 'Lord Howe Island',
        'status': 'Canonical',
        'utcOffset': '+10:30',
        'utcDstOffset': '+11:00'
    },
    {
        'countryCode': 'AU',
        'name': 'Australia/Melbourne',
        'portionOfCountry': 'Victoria',
        'status': 'Canonical',
        'utcOffset': '+10:00',
        'utcDstOffset': '+11:00'
    },
    {
        'name': 'Australia/North',
        'status': 'Deprecated',
        'utcOffset': '+09:30',
        'utcDstOffset': '+09:30'
    },
    {
        'name': 'Australia/NSW',
        'status': 'Deprecated',
        'utcOffset': '+10:00',
        'utcDstOffset': '+11:00'
    },
    {
        'countryCode': 'AU',
        'name': 'Australia/Perth',
        'portionOfCountry': 'Western Australia (most areas)',
        'status': 'Canonical',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'name': 'Australia/Queensland',
        'status': 'Deprecated',
        'utcOffset': '+10:00',
        'utcDstOffset': '+10:00'
    },
    {
        'name': 'Australia/South',
        'status': 'Deprecated',
        'utcOffset': '+09:30',
        'utcDstOffset': '+10:30'
    },
    {
        'countryCode': 'AU',
        'name': 'Australia/Sydney',
        'portionOfCountry': 'New South Wales (most areas)',
        'status': 'Canonical',
        'utcOffset': '+10:00',
        'utcDstOffset': '+11:00'
    },
    {
        'name': 'Australia/Tasmania',
        'status': 'Deprecated',
        'utcOffset': '+10:00',
        'utcDstOffset': '+11:00'
    },
    {
        'name': 'Australia/Victoria',
        'status': 'Deprecated',
        'utcOffset': '+10:00',
        'utcDstOffset': '+11:00'
    },
    {
        'name': 'Australia/West',
        'status': 'Deprecated',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'name': 'Australia/Yancowinna',
        'status': 'Alias',
        'utcOffset': '+09:30',
        'utcDstOffset': '+10:30'
    },
    {
        'name': 'Brazil/Acre',
        'status': 'Deprecated',
        'utcOffset': '-05:00',
        'utcDstOffset': '-05:00'
    },
    {
        'name': 'Brazil/DeNoronha',
        'status': 'Deprecated',
        'utcOffset': '-02:00',
        'utcDstOffset': '-02:00'
    },
    {
        'name': 'Brazil/East',
        'status': 'Deprecated',
        'utcOffset': '-03:00',
        'utcDstOffset': '-02:00'
    },
    {
        'name': 'Brazil/West',
        'status': 'Deprecated',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'name': 'Canada/Atlantic',
        'status': 'Deprecated',
        'utcOffset': '-04:00',
        'utcDstOffset': '-03:00'
    },
    {
        'name': 'Canada/Central',
        'status': 'Deprecated',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'name': 'Canada/Eastern',
        'status': 'Deprecated',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'name': 'Canada/Mountain',
        'status': 'Deprecated',
        'utcOffset': '-07:00',
        'utcDstOffset': '-06:00'
    },
    {
        'name': 'Canada/Newfoundland',
        'status': 'Deprecated',
        'utcOffset': '-03:30',
        'utcDstOffset': '-02:30'
    },
    {
        'name': 'Canada/Pacific',
        'status': 'Deprecated',
        'utcOffset': '-08:00',
        'utcDstOffset': '-07:00'
    },
    {
        'name': 'Canada/Saskatchewan',
        'status': 'Deprecated',
        'utcOffset': '-06:00',
        'utcDstOffset': '-06:00'
    },
    {
        'name': 'Canada/Yukon',
        'status': 'Deprecated',
        'utcOffset': '-08:00',
        'utcDstOffset': '-07:00'
    },
    {
        'name': 'CET',
        'status': 'Deprecated',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'name': 'Chile/Continental',
        'status': 'Deprecated',
        'utcOffset': '-04:00',
        'utcDstOffset': '-03:00'
    },
    {
        'name': 'Chile/EasterIsland',
        'status': 'Deprecated',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'name': 'CST6CDT',
        'status': 'Deprecated',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'name': 'Cuba',
        'status': 'Deprecated',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'name': 'EET',
        'status': 'Deprecated',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'name': 'Egypt',
        'status': 'Deprecated',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'name': 'Eire',
        'status': 'Deprecated',
        'utcOffset': '+00:00',
        'utcDstOffset': '+01:00'
    },
    {
        'name': 'EST',
        'status': 'Deprecated',
        'utcOffset': '-05:00',
        'utcDstOffset': '-05:00'
    },
    {
        'name': 'EST5EDT',
        'status': 'Deprecated',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'name': 'Etc/GMT',
        'status': 'Canonical',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'name': 'Etc/GMT+0',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'name': 'Etc/GMT+1',
        'status': 'Canonical',
        'utcOffset': '-01:00',
        'utcDstOffset': '-01:00'
    },
    {
        'name': 'Etc/GMT+10',
        'status': 'Canonical',
        'utcOffset': '-10:00',
        'utcDstOffset': '-10:00'
    },
    {
        'name': 'Etc/GMT+11',
        'status': 'Canonical',
        'utcOffset': '-11:00',
        'utcDstOffset': '-11:00'
    },
    {
        'name': 'Etc/GMT+12',
        'status': 'Canonical',
        'utcOffset': '-12:00',
        'utcDstOffset': '-12:00'
    },
    {
        'name': 'Etc/GMT+2',
        'status': 'Canonical',
        'utcOffset': '-02:00',
        'utcDstOffset': '-02:00'
    },
    {
        'name': 'Etc/GMT+3',
        'status': 'Canonical',
        'utcOffset': '-03:00',
        'utcDstOffset': '-03:00'
    },
    {
        'name': 'Etc/GMT+4',
        'status': 'Canonical',
        'utcOffset': '-04:00',
        'utcDstOffset': '-04:00'
    },
    {
        'name': 'Etc/GMT+5',
        'status': 'Canonical',
        'utcOffset': '-05:00',
        'utcDstOffset': '-05:00'
    },
    {
        'name': 'Etc/GMT+6',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-06:00'
    },
    {
        'name': 'Etc/GMT+7',
        'status': 'Canonical',
        'utcOffset': '-07:00',
        'utcDstOffset': '-07:00'
    },
    {
        'name': 'Etc/GMT+8',
        'status': 'Canonical',
        'utcOffset': '-08:00',
        'utcDstOffset': '-08:00'
    },
    {
        'name': 'Etc/GMT+9',
        'status': 'Canonical',
        'utcOffset': '-09:00',
        'utcDstOffset': '-09:00'
    },
    {
        'name': 'Etc/GMT0',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'name': 'Etc/GMT-0',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'name': 'Etc/GMT-1',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+01:00'
    },
    {
        'name': 'Etc/GMT-10',
        'status': 'Canonical',
        'utcOffset': '+10:00',
        'utcDstOffset': '+10:00'
    },
    {
        'name': 'Etc/GMT-11',
        'status': 'Canonical',
        'utcOffset': '+11:00',
        'utcDstOffset': '+11:00'
    },
    {
        'name': 'Etc/GMT-12',
        'status': 'Canonical',
        'utcOffset': '+12:00',
        'utcDstOffset': '+12:00'
    },
    {
        'name': 'Etc/GMT-13',
        'status': 'Canonical',
        'utcOffset': '+13:00',
        'utcDstOffset': '+13:00'
    },
    {
        'name': 'Etc/GMT-14',
        'status': 'Canonical',
        'utcOffset': '+14:00',
        'utcDstOffset': '+14:00'
    },
    {
        'name': 'Etc/GMT-2',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'name': 'Etc/GMT-3',
        'status': 'Canonical',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'name': 'Etc/GMT-4',
        'status': 'Canonical',
        'utcOffset': '+04:00',
        'utcDstOffset': '+04:00'
    },
    {
        'name': 'Etc/GMT-5',
        'status': 'Canonical',
        'utcOffset': '+05:00',
        'utcDstOffset': '+05:00'
    },
    {
        'name': 'Etc/GMT-6',
        'status': 'Canonical',
        'utcOffset': '+06:00',
        'utcDstOffset': '+06:00'
    },
    {
        'name': 'Etc/GMT-7',
        'status': 'Canonical',
        'utcOffset': '+07:00',
        'utcDstOffset': '+07:00'
    },
    {
        'name': 'Etc/GMT-8',
        'status': 'Canonical',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'name': 'Etc/GMT-9',
        'status': 'Canonical',
        'utcOffset': '+09:00',
        'utcDstOffset': '+09:00'
    },
    {
        'name': 'Etc/Greenwich',
        'status': 'Deprecated',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'name': 'Etc/UCT',
        'status': 'Deprecated',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'name': 'Etc/Universal',
        'status': 'Deprecated',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'name': 'Etc/UTC',
        'status': 'Canonical',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'name': 'Etc/Zulu',
        'status': 'Deprecated',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'NL',
        'name': 'Europe/Amsterdam',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'AD',
        'name': 'Europe/Andorra',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Europe/Astrakhan',
        'portionOfCountry': 'MSK+01 - Astrakhan',
        'status': 'Canonical',
        'utcOffset': '+04:00',
        'utcDstOffset': '+04:00'
    },
    {
        'countryCode': 'GR',
        'name': 'Europe/Athens',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'name': 'Europe/Belfast',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'RS',
        'name': 'Europe/Belgrade',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'DE',
        'name': 'Europe/Berlin',
        'portionOfCountry': 'Germany (most areas)',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'SK',
        'name': 'Europe/Bratislava',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'BE',
        'name': 'Europe/Brussels',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'RO',
        'name': 'Europe/Bucharest',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'HU',
        'name': 'Europe/Budapest',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'DE',
        'name': 'Europe/Busingen',
        'portionOfCountry': 'Büsingen am Hochrhein',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'MD',
        'name': 'Europe/Chisinau',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'DK',
        'name': 'Europe/Copenhagen',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'IE',
        'name': 'Europe/Dublin',
        'status': 'Canonical',
        'utcOffset': '+00:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'GI',
        'name': 'Europe/Gibraltar',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'GG',
        'name': 'Europe/Guernsey',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'FI',
        'name': 'Europe/Helsinki',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'IM',
        'name': 'Europe/Isle_of_Man',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'TR',
        'name': 'Europe/Istanbul',
        'status': 'Canonical',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'JE',
        'name': 'Europe/Jersey',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Europe/Kaliningrad',
        'portionOfCountry': 'MSK-01 - Kaliningrad',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'UA',
        'name': 'Europe/Kiev',
        'portionOfCountry': 'Ukraine (most areas)',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Europe/Kirov',
        'portionOfCountry': 'MSK+00 - Kirov',
        'status': 'Canonical',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'PT',
        'name': 'Europe/Lisbon',
        'portionOfCountry': 'Portugal (mainland)',
        'status': 'Canonical',
        'utcOffset': '+00:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'SI',
        'name': 'Europe/Ljubljana',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'GB',
        'name': 'Europe/London',
        'status': 'Canonical',
        'utcOffset': '+00:00',
        'utcDstOffset': '+01:00'
    },
    {
        'countryCode': 'LU',
        'name': 'Europe/Luxembourg',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'ES',
        'name': 'Europe/Madrid',
        'portionOfCountry': 'Spain (mainland)',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'MT',
        'name': 'Europe/Malta',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'AX',
        'name': 'Europe/Mariehamn',
        'status': 'Alias',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'BY',
        'name': 'Europe/Minsk',
        'status': 'Canonical',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'MC',
        'name': 'Europe/Monaco',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Europe/Moscow',
        'portionOfCountry': 'MSK+00 - Moscow area',
        'status': 'Canonical',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'CY',
        'name': 'Asia/Nicosia',
        'portionOfCountry': 'Cyprus (most areas)',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'NO',
        'name': 'Europe/Oslo',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'FR',
        'name': 'Europe/Paris',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'ME',
        'name': 'Europe/Podgorica',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'CZ',
        'name': 'Europe/Prague',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'LV',
        'name': 'Europe/Riga',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'IT',
        'name': 'Europe/Rome',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Europe/Samara',
        'portionOfCountry': 'MSK+01 - Samara, Udmurtia',
        'status': 'Canonical',
        'utcOffset': '+04:00',
        'utcDstOffset': '+04:00'
    },
    {
        'countryCode': 'SM',
        'name': 'Europe/San_Marino',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'BA',
        'name': 'Europe/Sarajevo',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Europe/Saratov',
        'portionOfCountry': 'MSK+01 - Saratov',
        'status': 'Canonical',
        'utcOffset': '+04:00',
        'utcDstOffset': '+04:00'
    },
    {
        'countryCode': 'UA',
        'name': 'Europe/Simferopol',
        'portionOfCountry': 'Crimea',
        'status': 'Canonical',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'MK',
        'name': 'Europe/Skopje',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'BG',
        'name': 'Europe/Sofia',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'SE',
        'name': 'Europe/Stockholm',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'EE',
        'name': 'Europe/Tallinn',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'AL',
        'name': 'Europe/Tirane',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'name': 'Europe/Tiraspol',
        'status': 'Alias',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Europe/Ulyanovsk',
        'portionOfCountry': 'MSK+01 - Ulyanovsk',
        'status': 'Canonical',
        'utcOffset': '+04:00',
        'utcDstOffset': '+04:00'
    },
    {
        'countryCode': 'UA',
        'name': 'Europe/Uzhgorod',
        'portionOfCountry': 'Ruthenia',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'LI',
        'name': 'Europe/Vaduz',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'VA',
        'name': 'Europe/Vatican',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'AT',
        'name': 'Europe/Vienna',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'LT',
        'name': 'Europe/Vilnius',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'RU',
        'name': 'Europe/Volgograd',
        'portionOfCountry': 'MSK+01 - Volgograd',
        'status': 'Canonical',
        'utcOffset': '+04:00',
        'utcDstOffset': '+04:00'
    },
    {
        'countryCode': 'PL',
        'name': 'Europe/Warsaw',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'HR',
        'name': 'Europe/Zagreb',
        'status': 'Alias',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'countryCode': 'UA',
        'name': 'Europe/Zaporozhye',
        'portionOfCountry': 'Zaporozh\'ye/Zaporizhia; Lugansk/Luhansk (east)',
        'status': 'Canonical',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'CH',
        'name': 'Europe/Zurich',
        'status': 'Canonical',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'name': 'GB',
        'status': 'Deprecated',
        'utcOffset': '+00:00',
        'utcDstOffset': '+01:00'
    },
    {
        'name': 'GB-Eire',
        'status': 'Deprecated',
        'utcOffset': '+00:00',
        'utcDstOffset': '+01:00'
    },
    {
        'name': 'GMT',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'name': 'GMT+0',
        'status': 'Deprecated',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'name': 'GMT0',
        'status': 'Deprecated',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'name': 'GMT-0',
        'status': 'Deprecated',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'name': 'Greenwich',
        'status': 'Deprecated',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'name': 'Hongkong',
        'status': 'Deprecated',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'name': 'HST',
        'status': 'Deprecated',
        'utcOffset': '-10:00',
        'utcDstOffset': '-10:00'
    },
    {
        'name': 'Iceland',
        'status': 'Deprecated',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'countryCode': 'MG',
        'name': 'Indian/Antananarivo',
        'status': 'Alias',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'IO',
        'name': 'Indian/Chagos',
        'status': 'Canonical',
        'utcOffset': '+06:00',
        'utcDstOffset': '+06:00'
    },
    {
        'countryCode': 'CX',
        'name': 'Indian/Christmas',
        'status': 'Canonical',
        'utcOffset': '+07:00',
        'utcDstOffset': '+07:00'
    },
    {
        'countryCode': 'CC',
        'name': 'Indian/Cocos',
        'status': 'Canonical',
        'utcOffset': '+06:30',
        'utcDstOffset': '+06:30'
    },
    {
        'countryCode': 'KM',
        'name': 'Indian/Comoro',
        'status': 'Alias',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'TF',
        'name': 'Indian/Kerguelen',
        'status': 'Canonical',
        'utcOffset': '+05:00',
        'utcDstOffset': '+05:00'
    },
    {
        'countryCode': 'SC',
        'name': 'Indian/Mahe',
        'status': 'Canonical',
        'utcOffset': '+04:00',
        'utcDstOffset': '+04:00'
    },
    {
        'countryCode': 'MV',
        'name': 'Indian/Maldives',
        'status': 'Canonical',
        'utcOffset': '+05:00',
        'utcDstOffset': '+05:00'
    },
    {
        'countryCode': 'MU',
        'name': 'Indian/Mauritius',
        'status': 'Canonical',
        'utcOffset': '+04:00',
        'utcDstOffset': '+04:00'
    },
    {
        'countryCode': 'YT',
        'name': 'Indian/Mayotte',
        'status': 'Alias',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'countryCode': 'RE',
        'name': 'Indian/Reunion',
        'status': 'Canonical',
        'utcOffset': '+04:00',
        'utcDstOffset': '+04:00'
    },
    {
        'name': 'Iran',
        'status': 'Deprecated',
        'utcOffset': '+03:30',
        'utcDstOffset': '+04:30'
    },
    {
        'name': 'Israel',
        'status': 'Deprecated',
        'utcOffset': '+02:00',
        'utcDstOffset': '+03:00'
    },
    {
        'name': 'Jamaica',
        'status': 'Deprecated',
        'utcOffset': '-05:00',
        'utcDstOffset': '-05:00'
    },
    {
        'name': 'Japan',
        'status': 'Deprecated',
        'utcOffset': '+09:00',
        'utcDstOffset': '+09:00'
    },
    {
        'name': 'Kwajalein',
        'status': 'Deprecated',
        'utcOffset': '+12:00',
        'utcDstOffset': '+12:00'
    },
    {
        'name': 'Libya',
        'status': 'Deprecated',
        'utcOffset': '+02:00',
        'utcDstOffset': '+02:00'
    },
    {
        'name': 'MET',
        'status': 'Deprecated',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'name': 'Mexico/BajaNorte',
        'status': 'Deprecated',
        'utcOffset': '-08:00',
        'utcDstOffset': '-07:00'
    },
    {
        'name': 'Mexico/BajaSur',
        'status': 'Deprecated',
        'utcOffset': '-07:00',
        'utcDstOffset': '-06:00'
    },
    {
        'name': 'Mexico/General',
        'status': 'Deprecated',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'name': 'MST',
        'status': 'Deprecated',
        'utcOffset': '-07:00',
        'utcDstOffset': '-07:00'
    },
    {
        'name': 'MST7MDT',
        'status': 'Deprecated',
        'utcOffset': '-07:00',
        'utcDstOffset': '-06:00'
    },
    {
        'name': 'Navajo',
        'status': 'Deprecated',
        'utcOffset': '-07:00',
        'utcDstOffset': '-06:00'
    },
    {
        'name': 'NZ',
        'status': 'Deprecated',
        'utcOffset': '+12:00',
        'utcDstOffset': '+13:00'
    },
    {
        'name': 'NZ-CHAT',
        'status': 'Deprecated',
        'utcOffset': '+12:45',
        'utcDstOffset': '+13:45'
    },
    {
        'countryCode': 'WS',
        'name': 'Pacific/Apia',
        'status': 'Canonical',
        'utcOffset': '+13:00',
        'utcDstOffset': '+14:00'
    },
    {
        'countryCode': 'NZ',
        'name': 'Pacific/Auckland',
        'portionOfCountry': 'New Zealand (most areas)',
        'status': 'Canonical',
        'utcOffset': '+12:00',
        'utcDstOffset': '+13:00'
    },
    {
        'countryCode': 'PG',
        'name': 'Pacific/Bougainville',
        'portionOfCountry': 'Bougainville',
        'status': 'Canonical',
        'utcOffset': '+11:00',
        'utcDstOffset': '+11:00'
    },
    {
        'countryCode': 'NZ',
        'name': 'Pacific/Chatham',
        'portionOfCountry': 'Chatham Islands',
        'status': 'Canonical',
        'utcOffset': '+12:45',
        'utcDstOffset': '+13:45'
    },
    {
        'countryCode': 'FM',
        'name': 'Pacific/Chuuk',
        'portionOfCountry': 'Chuuk/Truk, Yap',
        'status': 'Canonical',
        'utcOffset': '+10:00',
        'utcDstOffset': '+10:00'
    },
    {
        'countryCode': 'CL',
        'name': 'Pacific/Easter',
        'portionOfCountry': 'Easter Island',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'countryCode': 'VU',
        'name': 'Pacific/Efate',
        'status': 'Canonical',
        'utcOffset': '+11:00',
        'utcDstOffset': '+11:00'
    },
    {
        'countryCode': 'KI',
        'name': 'Pacific/Enderbury',
        'portionOfCountry': 'Phoenix Islands',
        'status': 'Canonical',
        'utcOffset': '+13:00',
        'utcDstOffset': '+13:00'
    },
    {
        'countryCode': 'TK',
        'name': 'Pacific/Fakaofo',
        'status': 'Canonical',
        'utcOffset': '+13:00',
        'utcDstOffset': '+13:00'
    },
    {
        'countryCode': 'FJ',
        'name': 'Pacific/Fiji',
        'status': 'Canonical',
        'utcOffset': '+12:00',
        'utcDstOffset': '+13:00'
    },
    {
        'countryCode': 'TV',
        'name': 'Pacific/Funafuti',
        'status': 'Canonical',
        'utcOffset': '+12:00',
        'utcDstOffset': '+12:00'
    },
    {
        'countryCode': 'EC',
        'name': 'Pacific/Galapagos',
        'portionOfCountry': 'Galapagos Islands',
        'status': 'Canonical',
        'utcOffset': '-06:00',
        'utcDstOffset': '-06:00'
    },
    {
        'countryCode': 'PF',
        'name': 'Pacific/Gambier',
        'portionOfCountry': 'Gambier Islands',
        'status': 'Canonical',
        'utcOffset': '-09:00',
        'utcDstOffset': '-09:00'
    },
    {
        'countryCode': 'SB',
        'name': 'Pacific/Guadalcanal',
        'status': 'Canonical',
        'utcOffset': '+11:00',
        'utcDstOffset': '+11:00'
    },
    {
        'countryCode': 'GU',
        'name': 'Pacific/Guam',
        'status': 'Canonical',
        'utcOffset': '+10:00',
        'utcDstOffset': '+10:00'
    },
    {
        'countryCode': 'US',
        'name': 'Pacific/Honolulu',
        'portionOfCountry': 'Hawaii',
        'status': 'Canonical',
        'utcOffset': '-10:00',
        'utcDstOffset': '-10:00'
    },
    {
        'name': 'Pacific/Johnston',
        'status': 'Alias',
        'utcOffset': '-10:00',
        'utcDstOffset': '-10:00'
    },
    {
        'countryCode': 'KI',
        'name': 'Pacific/Kiritimati',
        'portionOfCountry': 'Line Islands',
        'status': 'Canonical',
        'utcOffset': '+14:00',
        'utcDstOffset': '+14:00'
    },
    {
        'countryCode': 'FM',
        'name': 'Pacific/Kosrae',
        'portionOfCountry': 'Kosrae',
        'status': 'Canonical',
        'utcOffset': '+11:00',
        'utcDstOffset': '+11:00'
    },
    {
        'countryCode': 'MH',
        'name': 'Pacific/Kwajalein',
        'portionOfCountry': 'Kwajalein',
        'status': 'Canonical',
        'utcOffset': '+12:00',
        'utcDstOffset': '+12:00'
    },
    {
        'countryCode': 'MH',
        'name': 'Pacific/Majuro',
        'portionOfCountry': 'Marshall Islands (most areas)',
        'status': 'Canonical',
        'utcOffset': '+12:00',
        'utcDstOffset': '+12:00'
    },
    {
        'countryCode': 'PF',
        'name': 'Pacific/Marquesas',
        'portionOfCountry': 'Marquesas Islands',
        'status': 'Canonical',
        'utcOffset': '-09:30',
        'utcDstOffset': '-09:30'
    },
    {
        'countryCode': 'UM',
        'name': 'Pacific/Midway',
        'portionOfCountry': 'Midway Islands',
        'status': 'Alias',
        'utcOffset': '-11:00',
        'utcDstOffset': '-11:00'
    },
    {
        'countryCode': 'NR',
        'name': 'Pacific/Nauru',
        'status': 'Canonical',
        'utcOffset': '+12:00',
        'utcDstOffset': '+12:00'
    },
    {
        'countryCode': 'NU',
        'name': 'Pacific/Niue',
        'status': 'Canonical',
        'utcOffset': '-11:00',
        'utcDstOffset': '-11:00'
    },
    {
        'countryCode': 'NF',
        'name': 'Pacific/Norfolk',
        'status': 'Canonical',
        'utcOffset': '+11:00',
        'utcDstOffset': '+11:00'
    },
    {
        'countryCode': 'NC',
        'name': 'Pacific/Noumea',
        'status': 'Canonical',
        'utcOffset': '+11:00',
        'utcDstOffset': '+11:00'
    },
    {
        'countryCode': 'AS',
        'name': 'Pacific/Pago_Pago',
        'status': 'Canonical',
        'utcOffset': '-11:00',
        'utcDstOffset': '-11:00'
    },
    {
        'countryCode': 'PW',
        'name': 'Pacific/Palau',
        'status': 'Canonical',
        'utcOffset': '+09:00',
        'utcDstOffset': '+09:00'
    },
    {
        'countryCode': 'PN',
        'name': 'Pacific/Pitcairn',
        'status': 'Canonical',
        'utcOffset': '-08:00',
        'utcDstOffset': '-08:00'
    },
    {
        'countryCode': 'FM',
        'name': 'Pacific/Pohnpei',
        'portionOfCountry': 'Pohnpei/Ponape',
        'status': 'Canonical',
        'utcOffset': '+11:00',
        'utcDstOffset': '+11:00'
    },
    {
        'name': 'Pacific/Ponape',
        'status': 'Alias',
        'utcOffset': '+11:00',
        'utcDstOffset': '+11:00'
    },
    {
        'countryCode': 'PG',
        'name': 'Pacific/Port_Moresby',
        'portionOfCountry': 'Papua New Guinea (most areas)',
        'status': 'Canonical',
        'utcOffset': '+10:00',
        'utcDstOffset': '+10:00'
    },
    {
        'countryCode': 'CK',
        'name': 'Pacific/Rarotonga',
        'status': 'Canonical',
        'utcOffset': '-10:00',
        'utcDstOffset': '-10:00'
    },
    {
        'countryCode': 'MP',
        'name': 'Pacific/Saipan',
        'status': 'Alias',
        'utcOffset': '+10:00',
        'utcDstOffset': '+10:00'
    },
    {
        'name': 'Pacific/Samoa',
        'status': 'Alias',
        'utcOffset': '-11:00',
        'utcDstOffset': '-11:00'
    },
    {
        'countryCode': 'PF',
        'name': 'Pacific/Tahiti',
        'portionOfCountry': 'Society Islands',
        'status': 'Canonical',
        'utcOffset': '-10:00',
        'utcDstOffset': '-10:00'
    },
    {
        'countryCode': 'KI',
        'name': 'Pacific/Tarawa',
        'portionOfCountry': 'Gilbert Islands',
        'status': 'Canonical',
        'utcOffset': '+12:00',
        'utcDstOffset': '+12:00'
    },
    {
        'countryCode': 'TO',
        'name': 'Pacific/Tongatapu',
        'status': 'Canonical',
        'utcOffset': '+13:00',
        'utcDstOffset': '+14:00'
    },
    {
        'name': 'Pacific/Truk',
        'status': 'Alias',
        'utcOffset': '+10:00',
        'utcDstOffset': '+10:00'
    },
    {
        'countryCode': 'UM',
        'name': 'Pacific/Wake',
        'portionOfCountry': 'Wake Island',
        'status': 'Canonical',
        'utcOffset': '+12:00',
        'utcDstOffset': '+12:00'
    },
    {
        'countryCode': 'WF',
        'name': 'Pacific/Wallis',
        'status': 'Canonical',
        'utcOffset': '+12:00',
        'utcDstOffset': '+12:00'
    },
    {
        'name': 'Pacific/Yap',
        'status': 'Alias',
        'utcOffset': '+10:00',
        'utcDstOffset': '+10:00'
    },
    {
        'name': 'Poland',
        'status': 'Deprecated',
        'utcOffset': '+01:00',
        'utcDstOffset': '+02:00'
    },
    {
        'name': 'Portugal',
        'status': 'Deprecated',
        'utcOffset': '+00:00',
        'utcDstOffset': '+01:00'
    },
    {
        'name': 'PRC',
        'status': 'Deprecated',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'name': 'PST8PDT',
        'status': 'Deprecated',
        'utcOffset': '-08:00',
        'utcDstOffset': '-07:00'
    },
    {
        'name': 'ROC',
        'status': 'Deprecated',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'name': 'ROK',
        'status': 'Deprecated',
        'utcOffset': '+09:00',
        'utcDstOffset': '+09:00'
    },
    {
        'name': 'Singapore',
        'status': 'Deprecated',
        'utcOffset': '+08:00',
        'utcDstOffset': '+08:00'
    },
    {
        'name': 'Turkey',
        'status': 'Deprecated',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'name': 'UCT',
        'status': 'Deprecated',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'name': 'Universal',
        'status': 'Deprecated',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'name': 'US/Alaska',
        'status': 'Deprecated',
        'utcOffset': '-09:00',
        'utcDstOffset': '-08:00'
    },
    {
        'name': 'US/Aleutian',
        'status': 'Deprecated',
        'utcOffset': '-10:00',
        'utcDstOffset': '-09:00'
    },
    {
        'name': 'US/Arizona',
        'status': 'Deprecated',
        'utcOffset': '-07:00',
        'utcDstOffset': '-07:00'
    },
    {
        'name': 'US/Central',
        'status': 'Deprecated',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'name': 'US/Eastern',
        'status': 'Deprecated',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'name': 'US/East-Indiana',
        'status': 'Deprecated',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'name': 'US/Hawaii',
        'status': 'Deprecated',
        'utcOffset': '-10:00',
        'utcDstOffset': '-10:00'
    },
    {
        'name': 'US/Indiana-Starke',
        'status': 'Deprecated',
        'utcOffset': '-06:00',
        'utcDstOffset': '-05:00'
    },
    {
        'name': 'US/Michigan',
        'status': 'Deprecated',
        'utcOffset': '-05:00',
        'utcDstOffset': '-04:00'
    },
    {
        'name': 'US/Mountain',
        'status': 'Deprecated',
        'utcOffset': '-07:00',
        'utcDstOffset': '-06:00'
    },
    {
        'name': 'US/Pacific',
        'status': 'Deprecated',
        'utcOffset': '-08:00',
        'utcDstOffset': '-07:00'
    },
    {
        'name': 'US/Pacific-New',
        'status': 'Deprecated',
        'utcOffset': '-08:00',
        'utcDstOffset': '-07:00'
    },
    {
        'name': 'US/Samoa',
        'status': 'Deprecated',
        'utcOffset': '-11:00',
        'utcDstOffset': '-11:00'
    },
    {
        'name': 'UTC',
        'status': 'Alias',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    },
    {
        'name': 'WET',
        'status': 'Deprecated',
        'utcOffset': '+00:00',
        'utcDstOffset': '+01:00'
    },
    {
        'name': 'W-SU',
        'status': 'Deprecated',
        'utcOffset': '+03:00',
        'utcDstOffset': '+03:00'
    },
    {
        'name': 'Zulu',
        'status': 'Deprecated',
        'utcOffset': '+00:00',
        'utcDstOffset': '+00:00'
    }
];

AINA_FullTzList.forEach(x => {
    x.label = `${x.name} (${x.utcOffset})`;
    const indexOfColon = x.utcOffset.indexOf(':');
    const hours = Number(x.utcOffset.substr(0, indexOfColon)) * 60;
    const minutes = Number(x.utcOffset.substring(indexOfColon + 1, x.utcOffset.length));
    x.utcOffsetValue = hours + minutes;
});

/** Only Canonical/Recomended list of timezones from AINA standard. From https://en.wikipedia.org/wiki/List_of_tz_database_time_zones */
export const AINA_CanonicalTzList: TimeZoneInfo[] = AINA_FullTzList
    .filter(x => x.status === TimeZoneStatus.Canonical)
    .sort((a, b) => a.utcOffsetValue - b.utcOffsetValue);

export const TimeZoneGmtZero = 'Etc/GMT';
