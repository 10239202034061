// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { PublicPart } from 'ngx-shared';

import { Tooltip } from '@/_models';

@Injectable({ providedIn: 'root' })
export class TooltipService {
    constructor(private http: HttpClient) { }

    get(key: string): Observable<Tooltip[]> {
        return this.http.get<Tooltip[]>(`${environment.portalWebApi}/Tooltips/${key}`);
    }
}

/* eslint-disable */
export class FakeTooltipService implements PublicPart<TooltipService> {

    get(key: string): Observable<Tooltip[]> {
        throw new Error('Method not implemented.');
    }
}

export const fakeTooltipServiceProvider = { provide: TooltipService, useClass: FakeTooltipService };
/* eslint-enable */
