// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

export enum Routes {
    default = '',
    serverError = 'server-error',  

    loginFailed = 'login-failed',
    postLogout = 'logout',
    
    home = 'home',
    alerts = 'alerts',
    orderManagement = 'order',
    monitor = 'monitor',
    monitorSection = 'monitor/section',
    kpi = 'kpi',
    mnemonics = 'mnemonics',
    rules = 'rules',
    transferManagement = 'transfer-management',
    merlinManagement = 'merlin-management',
    admin = 'admin',
    adminSystemConfig = 'admin/sys-config',
    adminUsers = 'admin/users',
    adminPickLists = 'admin/pick-lists',
    adminConnections = 'admin/targets',
    adminAppliances = 'admin/appliances',
    editingMonitoring = 'editing-monitoring',
    // DAP
    dapTemplateManagement = 'dap/dap-template-management',
    dapDocumentOverview = 'dap/document-overview',
    dapStatusOverview = 'dap/status-overview',
    dapDocumentDetails = 'dap/dap-document-details',
    dapDocumentStatus = 'dap/document-status',
    dapLicenseManagement = 'dap/dap-license-management'
}
