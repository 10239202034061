// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

export enum CurveLagType {
    None = 0,
    SensorOffset = 1,
    ReturnDepth = 2
}
export class MnemonicCatalog {
    /** integer value */
    public id: number;

    /** integer value; FK key for PicklistItem */
    public serviceProviderId: number;

    /** integer value; FK key for PicklistItem */
    public serviceId: number;

    public tool: string;

    public toolGroup: string;

    public vendorMnemonic: string;

    public globalMnemonic: string;

    public curveLabel: string;

    public isCommon: boolean;

    public uom: string;

    /** nullable double value */
    public minimumValue: number;

    /** nullable double value */
    public maximumValue: number;

    /** consists of number value and UoM */
    public dataPointFrequency: string;

    /** consists of number value and UoM */
    public receiveFrequency: string;

    /** consists of number value and UoM */
    public toolUpdatePeriod: string;

    /** integer value; FK key for PicklistItem */
    public vendorLogNameTypeId: number;

    public vendorLogName: string;

    /** integer value; FK key for PicklistItem */
    public curveIndexTypeId: number;

    public kpiCurve: boolean;

    public lagType: CurveLagType;

    /** integer value */
    public decimals: number;

    public memoryMnemonic: string;

    public memoryLogName: string;

    /** integer value; FK key for PicklistItem */
    public memoryLogNameTypeId: number;

    public globalLogName: string;

    /** integer value; FK key for PicklistItem */
    public globalLogNameTypeId: number;

    public tags: string;
    public tagSource: string;
    public tagPriority: string;
    public comments: string;
    public curveGroups: string;
}
