//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { Observable } from 'rxjs';

// The PNG is the widely supported format. The embedded GIF is not showing in Outlook.
export function resizeImage(file: File, height: number, exportType = 'image/png'): Observable<any> {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    return new Observable(observer => {
        reader.onload = ev => {
            const img = new Image();
            img.src = (ev.target as any).result;
            (img.onload = () => {
                const elem = document.createElement('canvas');
                const scaleFactor = height / img.height;
                elem.width = img.width * scaleFactor;
                elem.height = height;
                const ctx = <CanvasRenderingContext2D>elem.getContext('2d');
                ctx.imageSmoothingEnabled = true;
                ctx.imageSmoothingQuality = 'high';
                ctx.drawImage(img, 0, 0, img.width * scaleFactor, height );
                ctx.canvas.toBlob(
                blob => {
                    observer.next(
                        new File([blob], file.name, {
                            type: exportType,
                            lastModified: Date.now(),
                        }),
                    );
                    observer.complete();
                },
                exportType);
            }),
            (reader.onerror = error => observer.error(error));
        };
    });
}
