// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS America LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//      http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { ValidatorFn, Validators } from "@angular/forms";
import { DapFieldVM, DapTableRowValue } from "../vm";
import { disableControl, enableControl, getPropertyName } from "@/_helpers";
import { RefValidation } from "../eval/ref-validation";
import { DapDocumentContentField } from "@/_models/dap-document-details";
import { PickListItem, PickListType } from "@/_models";

export class DapFieldHelper {
    public static updateEnabled(vm: DapFieldVM) {
        if (vm.isAlwaysReadonly || vm.hasReadonlyParent) {
            disableControl(vm.form.get(DapFieldHelper.getFieldFormControlName(vm.templateContent.format)));
        } else if (vm.dispositionResult != null) {
            if (vm.dispositionResult.mutable) {
                enableControl(vm.form.get(DapFieldHelper.getFieldFormControlName(vm.templateContent.format)));
            } else {
                disableControl(vm.form.get(DapFieldHelper.getFieldFormControlName(vm.templateContent.format)));
            }
        }
    }

    public static updateValidators(vm: DapFieldVM, sectionIndex: number, dapIndex: number, rowValue: DapTableRowValue,
        picklistMap: Map<PickListType, PickListItem[]>) {
        
        const newValidators = DapFieldHelper.getValidators(vm, sectionIndex, dapIndex, rowValue, picklistMap);
        const formControl = vm.form.get(DapFieldHelper.getFieldFormControlName(vm.templateContent.format));
        formControl.setValidators(newValidators);
        formControl.updateValueAndValidity({emitEvent: false});
    }

    private static getValidators(vm: DapFieldVM, sectionIndex: number, dapIndex: number, rowValue: DapTableRowValue,
        picklistMap: Map<PickListType, PickListItem[]>): ValidatorFn[] {
        
        const result: ValidatorFn[] = [];
        if (vm.dispositionResult && !vm.dispositionResult.nullable) {
            result.push(Validators.required);
        }

        if (vm.templateContent.fieldValidation) {
            result.push(...DapFieldHelper.getValidatorsFromFunctions(vm, sectionIndex, dapIndex, rowValue, picklistMap));
        }

        return result;
    }

    private static getValidatorsFromFunctions(vm: DapFieldVM, sectionIndex: number, dapIndex: number, rowValue: DapTableRowValue,
        picklistMap: Map<PickListType, PickListItem[]>): ValidatorFn[] {

        const result: ValidatorFn[] = [];
        vm.templateContent.fieldValidation?.forEach(validatorFunc => {
            result.push(...RefValidation.getFieldValidatorFuncs(vm, validatorFunc, vm.docVM, sectionIndex, dapIndex, rowValue, picklistMap))
        });

        return result;
    }

    private static getFieldFormControlName(format: string) {
        switch (format) {
            case 'string': return getPropertyName<DapDocumentContentField>(x => x.str);
            case 'text': return getPropertyName<DapDocumentContentField>(x => x.text);
            case 'integer': return getPropertyName<DapDocumentContentField>(x => x.int);
            case 'float': return getPropertyName<DapDocumentContentField>(x => x.float);
            case 'datetime': return getPropertyName<DapDocumentContentField>(x => x.datetime);
            case 'enum': return getPropertyName<DapDocumentContentField>(x => x.str);
            case 'measure': return getPropertyName<DapDocumentContentField>(x => x.measure);
            case 'boolean': return getPropertyName<DapDocumentContentField>(x => x.bool);
            default: return getPropertyName<DapDocumentContentField>(x => x.str);
        }
    }
}
