//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

/**
 * Produces bitwise or of all numbers in a collection
 * @param {T[]} collection
 * @returns {T} Returns the bitwise or of all items
 */
 export function bitwiseOr(collection: number[]): number {
    if (collection && Array.isArray(collection)) {
        let result = 0;
        for (let i = 0; i < collection.length; i++) {
            // tslint:disable-next-line: no-bitwise
            result |= collection[i];
        }
        return result;
    }

    return null;
}
