// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { PublicPart } from 'ngx-shared';

import { User, SimpleUser } from '@/_models';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getCurrent(): Observable<User> {
        return this.http.get<User>(`${environment.portalWebApi}/Users/current`);
    }

    getAll(): Observable<User[]> {
        return this.http.get<User[]>(`${environment.portalWebApi}/Users`);
    }

    getAllSimple(): Observable<SimpleUser[]> {
        return this.http.get<User[]>(`${environment.portalWebApi}/Users/list`);
    }

    update(user: User): Observable<User> {
        return this.http.post<User>(`${environment.portalWebApi}/Users/update`, user);
    }

    create(user: User): Observable<User> {
        return this.http.post<User>(`${environment.portalWebApi}/Users/create`, user);
    }

    delete(userId: number): Observable<void> {
        return this.http.delete<void>(`${environment.portalWebApi}/Users/${userId}`);
    }

    disable(userId: number): Observable<User> {
        return this.http.get<User>(`${environment.portalWebApi}/Users/disable/${userId}`);
    }

    enable(userId: number): Observable<User> {
        return this.http.get<User>(`${environment.portalWebApi}/Users/enable/${userId}`);
    }
}

/* eslint-disable */
export class FakeUserService implements PublicPart<UserService> {
    getCurrent(): Observable<User> {
        throw new Error('Method not implemented.');
    }

    getAll(): Observable<User[]> {
        throw new Error('Method not implemented.');
    }

    getAllSimple(): Observable<User[]> {
        throw new Error('Method not implemented.');
    }

    update(user: User): Observable<User> {
        throw new Error('Method not implemented.');
    }

    create(user: User): Observable<User> {
        throw new Error('Method not implemented.');
    }

    delete(userId: number): Observable<void> {
        throw new Error('Method not implemented.');
    }

    disable(userId: number): Observable<User> {
        throw new Error('Method not implemented.');
    }

    enable(userId: number): Observable<User> {
        throw new Error('Method not implemented.');
    }
}

export const fakeUserServiceProvider = { provide: UserService, useClass: FakeUserService };
/* eslint-enable */
