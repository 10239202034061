// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { DapDocumentBase, DapDocumentFilterData } from '@/_models/dap-document';
import { DapDocumentFilter } from '@/_models/dap-document/dap-document-filter';
import { Action } from '@ngrx/store';

export class FetchDapStatusFilterDataAction implements Action {
    public type = '[DapStatusManagement] Fetch DapDocument filter data action';
}
export class FetchDapStatusFilterDataOkAction implements Action {
    public type = '[DapStatusManagement] Fetch DapDocument filter data Ok action';
}
export class FetchDapStatusFilterDataFailedAction implements Action {
    public type = '[DapStatusManagement] Fetch DapDocument filter data Failed action';
    constructor(public readonly error: Error | string | any) { }
}
export class LoadDapStatusFilterDataAction implements Action {
    readonly type = '[DapStatusManagement] InState Load DapDocument filter data action';

    constructor(public readonly filterData: DapDocumentFilterData) { }
}

export class FetchDapDocumentsAction implements Action {
    public type = '[DapStatusManagement] Fetch DapDocuments action';

    constructor(public readonly filter: DapDocumentFilter) { }
}
export class FetchDapDocumentsOkAction implements Action {
    public type = '[DapStatusManagement] Fetch DapDocuments Ok action';
}
export class FetchDapDocumentsFailedAction implements Action {
    public type = '[DapStatusManagement] Fetch DapDocuments Failed action';
    constructor(public readonly error: Error | string | any) { }
}
export class LoadDapDocumentsAction implements Action {
    readonly type = '[DapStatusManagement] InState Load DapDocuments action';

    constructor(public readonly documents: DapDocumentBase[]) { }
}

export class MarkDapDocumentsAsDiagramLoadingAction implements Action {
    readonly type = '[DapStatusManagement] InState Mark DapDocuments as diagram loading action';

    constructor(public readonly documents: DapDocumentBase[]) { }
}
export class FetchDapDocumentScoreDiagramsAction implements Action {
    public type = '[DapStatusManagement] Fetch DapDocuments score diagrams action';

    constructor(public readonly documents: DapDocumentBase[]) { }
}
export class FetchDapDocumentScoreDiagramsOkAction implements Action {
    public type = '[DapStatusManagement] Fetch DapDocuments score diagrams Ok action';
}
export class FetchDapDocumentScoreDiagramsFailedAction implements Action {
    public type = '[DapStatusManagement] Fetch DapDocuments score diagrams Failed action';
    constructor(public readonly error: Error | string | any) { }
}


export class ToggleDapDocumentFavouriteAction implements Action {
    readonly type = '[DapStatusManagement] Toggle the favourite status of a DAP document';

    constructor(public readonly dapHeaderId: number) { }
}
export class ToggleDapDocumentFavouriteOkAction implements Action {
    public type = '[DapStatusManagement] Toggle the favourite status of a DAP document Ok action';
}
export class ToggleDapDocumentFavouriteFailedAction implements Action {
    public type = '[DapStatusManagement] Toggle the favourite status of a DAP document Failed action';
    constructor(public readonly error: Error | string | any) { }
}
export class ToggleDapDocumentFavouriteInStoreAction implements Action {
    readonly type = '[DapStatusManagement] InState toggle favourite status of a document action';

    constructor(public readonly dapHeaderId: number, public readonly newState: boolean) { }
}

