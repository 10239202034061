//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { ActionType } from './of-action';

export const NGRX_ACTIONS_META = '__ngrx__actions__';

export interface StoreMetadata {
  initialState?: any;
  actions: ActionsMeta;
  effects: ActionsMeta;
}

export interface ActionMeta {
  action: ActionType;
  fn: string;
  type: string;
}

export type ActionsMeta = {
  [type: string]: ActionMeta;
};

export function ensureStoreMetadata(target: any): StoreMetadata {
  // see https://github.com/angular/angular/blob/master/packages/core/src/util/decorators.ts#L60
  if (!target.hasOwnProperty(NGRX_ACTIONS_META)) {
    const defaultMetadata: StoreMetadata = { actions: {}, effects: {} };
    Object.defineProperty(target, NGRX_ACTIONS_META, { value: defaultMetadata });
  }
  return target[NGRX_ACTIONS_META];
}