// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Action } from '@ngrx/store';
import { DapApiLicense, DapApiLicenseInfo, DapApiProfile } from '@/_models/dap-api-license';

export class LoadLicensesAction implements Action {
    public type = '[License] Load licenses action';
    constructor(public readonly licenses: DapApiLicense[]) { }
}

export class FetchLicensesAction implements Action {
    public type = '[License] Fetch licenses action';
}

export class FetchOkLicensesAction implements Action {
    public type = '[License] Fetch OK licenses action';
}

export class FetchFailedLicensesAction implements Action {
    public type = '[License] Fetch Cancel licenses action';
    constructor(public readonly error: Error | string | any) { }
}

export class CreateLicenseInStateAction implements Action {
    public type = '[License] Create license in state action';
    constructor(public readonly license: DapApiLicense) { }
}

export class CreateLicenseOnServerAction implements Action {
    public type = '[License] Create license on server action';
    constructor(public readonly license: DapApiLicense) { }
}

export class CreateLicenseAction implements Action {
    public type = '[License] Create license action';
    constructor(public readonly license: DapApiLicense) { }
}

export class CreateOkLicenseAction implements Action {
    public type = '[License] Create OK license action';
}

export class CreateFailedLicenseAction implements Action {
    public type = '[License] Create Cancel license action';
    constructor(public readonly error: Error | string | any) { }
}

export class DeleteLicenseAction implements Action {
    public type = '[License] Delete license id action';
    constructor(public readonly licenseId: number) { }
}

export class DeleteOkLicenseAction implements Action {
    public type = '[License] Delete OK license id action';
}

export class DeleteFailedLicenseAction implements Action {
    public type = '[License] Delete Failed license id action';
    constructor(public readonly error: Error | string | any) { }
}

export class DeleteLicenseFromStateAction implements Action {
    public type = '[License] Delete license id from state action';
    constructor(public readonly licenseId: number) { }
}

export class DeleteLicenseOnServerAction implements Action {
    public type = '[License] Delete license id on server action';
    constructor(public readonly licenseId: number) { }
}

export class UpdateLicenseInStateAction implements Action {
    public type = '[License] Update license in state action';
    constructor(public readonly license: DapApiLicense) { }
}

export class UpdateLicenseOnServerAction implements Action {
    public type = '[License] Update license on server action';
    constructor(public readonly license: DapApiLicense) { }
}

export class UpdateLicenseAction implements Action {
    public type = '[License] Update license action';
    constructor(public readonly license: DapApiLicense) { }
}

export class UpdateOkLicenseAction implements Action {
    public type = '[License] Update OK license action';
}

export class UpdateFailedLicenseAction implements Action {
    public type = '[License] Update Cancel license action';
    constructor(public readonly error: Error | string | any) { }
}

export class LoadProfilesAction implements Action {
    public type = '[Profile] Load profiles action';
    constructor(public readonly profiles: DapApiProfile[]) { }
}

export class FetchProfilesAction implements Action {
    public type = '[Profile] Fetch profiles action';
}

export class FetchOkProfilesAction implements Action {
    public type = '[Profile] Fetch OK profiles action';
}

export class FetchFailedProfilesAction implements Action {
    public type = '[Profile] Fetch Cancel profiles action';
    constructor(public readonly error: Error | string | any) { }
}

export class LoadLicenseInfoAction implements Action {
    public type = '[License Key] Load license key action';
    constructor(public readonly licenseInfo: DapApiLicenseInfo) { }
}

export class FetchLicenseInfoAction implements Action {
    public type = '[License Key] Fetch license key action';
    constructor(public readonly licenseKey: string) { }
}

export class FetchOkLicenseInfoAction implements Action {
    public type = '[License Key] Fetch OK license key action';
}

export class FetchFailedLicenseInfoAction implements Action {
    public type = '[License Key] Fetch Cancel license key action';
    constructor(public readonly error: Error | string | any) { }
}
