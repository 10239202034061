// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { environment } from 'environments/environment';
import { PublicPart } from 'ngx-shared';

import { DapApiLicense, DapApiProfile, DapApiLicenseInfo } from '@/_models/dap-api-license';
import { map } from 'rxjs/operators';
import { formatJustDateInUtc } from '@/_helpers/utc-date-formatter';

@Injectable({ providedIn: 'root' })
export class DapLicenseService {
    constructor(private http: HttpClient) { }

    getAll(): Observable<DapApiLicense[]> {
        return this.http.get<DapApiLicense[]>(`${environment.dapApi}/licenses`).pipe(
            map(x => {
                x.forEach(l => {
                    if (l.autoRevokeDate) {
                        l.autoRevokeDate = new Date(l.autoRevokeDate);
                    }
                });
                return x;
            })
        );
    }

    update(license: DapApiLicense): Observable<DapApiLicense> {
        const copy = {...license};
        if (copy?.autoRevokeDate) {
            copy.autoRevokeDate = new Date(formatJustDateInUtc(copy.autoRevokeDate));
        }
        return this.http.post<DapApiLicense>(`${environment.dapApi}/licenses/update`, copy);
    }

    create(license: DapApiLicense): Observable<DapApiLicense> {
        const copy = {...license};
        if (copy?.autoRevokeDate) {
            copy.autoRevokeDate = new Date(formatJustDateInUtc(copy.autoRevokeDate));
        }
        return this.http.post<DapApiLicense>(`${environment.dapApi}/licenses/create`, copy);
    }

    delete(licenseId: number): Observable<void> {
        return this.http.delete<void>(`${environment.dapApi}/licenses/${licenseId}`);
    }

    getProfiles(): Observable<DapApiProfile[]> {
        return this.http.get<DapApiProfile[]>(`${environment.dapApi}/licenses/profiles`);
    }

    getLicenseInfo(licenseKey: string): Observable<DapApiLicenseInfo> {
        const httpOptions = {
            headers: new HttpHeaders({ 'Content-Type': 'application/json' })
        };

        return this.http.post<DapApiLicenseInfo>(`${environment.dapApi}/licenses/info`, JSON.stringify(licenseKey), httpOptions);
    }
}

/* eslint-disable */
export class FakeLicenseService implements PublicPart<DapLicenseService> {
    getAll(): Observable<DapApiLicense[]> {
        throw new Error('Method not implemented.');
    }

    update(license: DapApiLicense): Observable<DapApiLicense> {
        throw new Error('Method not implemented.');
    }

    create(license: DapApiLicense): Observable<DapApiLicense> {
        throw new Error('Method not implemented.');
    }

    delete(licenseId: number): Observable<void> {
        throw new Error('Method not implemented.');
    }
    getProfiles(): Observable<DapApiProfile[]> {
        throw new Error('Method not implemented.');
    }
    getLicenseInfo(licenseKey: string): Observable<DapApiLicenseInfo> {
        throw new Error('Method not implemented.');
    }
}
export const fakeLicenseServiceProvider = { provide: DapLicenseService, useClass: FakeLicenseService };
/* eslint-enable */
