//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { ParameterType } from '../rule-parameter-types';
import { RuleParameterCategory } from '../rule-parameter-category';

export class RuleInstanceParameterWithOverrides {
    constructor(
        public orderId: number,
        public ruleInstanceId: number,
        public ruleInstanceParameterId: number,
        public ruleParameterDefinitionId: number,
        public subjectType: string,
        public subjectName: string,
        public monitor: string,
        public name: string,
        public type: ParameterType,
        public category: RuleParameterCategory,
        public uom: string,
        public defaultValue: number,
        public defaultValueString: string,
        public defaultValueBool: boolean,

        public activeOverrideValue: number,
        public activeOverrideValueString: string,
        public activeOverrideValueBool: boolean,

        public orderOverrideValue: number,
        public orderOverrideValueString: string,
        public orderOverrideValueBool: boolean,

        public globalOverrideValue: number,
        public globalOverrideValueString: string,
        public globalOverrideValueBool: boolean,

        public targetOverrides: RuleParameterTargetOverride[],

        public availableValues: string[]
    ) {}
}

export class RuleParameterTargetOverride {
    constructor(
        public targetId: number,

        public targetOverrideValue: number,
        public targetOverrideValueString: string,
        public targetOverrideValueBool: boolean,
    ) {}
}
