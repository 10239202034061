// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

export function readFile(file: File, timeoutInMs = 20000): Promise<string> {
    return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = function (e: ProgressEvent) {
            const resultFileLink = (e.target as FileReader).result as string;
            resolve(resultFileLink);
        };
        reader.readAsDataURL(file);

        setTimeout(() => {
            reject('It took too long to read file');
        }, timeoutInMs);
    });
}

export function getFileExtension(filename: string): string {
    return filename.split('.').pop();
}
