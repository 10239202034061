// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { catchError, switchMap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { NgxAlertService, getMessageFromError } from 'ngx-shared';
import { of } from 'rxjs';
import { DapDocumentStatusService } from '@/_services/dap-document-status.service';
import {
    FetchDapDocumentContentAction, FetchDapDocumentContentOkAction, FetchDapDocumentContentFailedAction,
    LoadDapDocumentContentAction, ReactivateDocumentAction, ReactivateDocumentOkAction, ReactivateDocumentFailedAction,
    AbandonDocumentAction, AbandonDocumentOkAction, AbandonDocumentFailedAction,
    CompleteDocumentAction, CompleteDocumentOkAction, CompleteDocumentFailedAction,
    ReactivateSectionAction, ReactivateSectionOkAction, ReactivateSectionFailedAction,
    CompleteSectionAction, CompleteSectionOkAction, CompleteSectionFailedAction, AbandonSectionAction,
    AbandonSectionOkAction, AbandonSectionFailedAction, ReactivateDapAction, ReactivateDapOkAction,
    ReactivateDapFailedAction, CompleteDapAction, CompleteDapOkAction, CompleteDapFailedAction,
    AbandonDapAction, AbandonDapOkAction, AbandonDapFailedAction,
    SaveScoresAction, SaveScoresOkAction, SaveScoresFailedAction,
    GetHistoricalAverageAction, LoadHistoricalAverageInStoreAction, GetHistoricalAverageOkAction, GetHistoricalAverageFailedAction,
    RequestFilterFieldsUpdateAction
} from './dap-document-status.actions';

@Injectable()
export class DapDocumentStatusEffects {

    
    public onFetchDapDocumentContent$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchDapDocumentContentAction),
        switchMap(x => this.dapDocumentStatusService.getDocumentStatusData(x.id, x.linkTemplate).pipe(
            switchMap(content => [
                new LoadDapDocumentContentAction(content),
                new FetchDapDocumentContentOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchDapDocumentContent ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchDapDocumentContentFailedAction(error));
            })
        ))
    ));

    
    public onReactivateDocument$ = createEffect(() => this.actions$.pipe(
        ofAction(ReactivateDocumentAction),
        switchMap(x => this.dapDocumentStatusService.reactivateDocument(x.id).pipe(
            switchMap(content => [new LoadDapDocumentContentAction(content), new ReactivateDocumentOkAction()]),
            catchError(error => {
                this.logger.error('Error in ReactivateDocumentAction ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new ReactivateDocumentFailedAction(error));
            })
        ))
    ));

    
    public onCompleteDocument$ = createEffect(() => this.actions$.pipe(
        ofAction(CompleteDocumentAction),
        switchMap(x => this.dapDocumentStatusService.completeDocument(x.id).pipe(
            switchMap(content => [new LoadDapDocumentContentAction(content), new CompleteDocumentOkAction()]),
            catchError(error => {
                this.logger.error('Error in CompleteDocumentAction ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new CompleteDocumentFailedAction(error));
            })
        ))
    ));

    
    public onAbandonDocument$ = createEffect(() => this.actions$.pipe(
        ofAction(AbandonDocumentAction),
        switchMap(x => this.dapDocumentStatusService.abandonDocument(x.id).pipe(
            switchMap(content => [new LoadDapDocumentContentAction(content), new AbandonDocumentOkAction()]),
            catchError(error => {
                this.logger.error('Error in AbandonDocumentAction ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new AbandonDocumentFailedAction(error));
            })
        ))
    ));

    
    public onReactivateSection$ = createEffect(() => this.actions$.pipe(
        ofAction(ReactivateSectionAction),
        switchMap(x => this.dapDocumentStatusService.reactivateSection(x.id).pipe(
            switchMap(content => [new LoadDapDocumentContentAction(content), new ReactivateSectionOkAction()]),
            catchError(error => {
                this.logger.error('Error in ReactivateDocumentAction ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new ReactivateSectionFailedAction(error));
            })
        ))
    ));

    
    public onCompleteSection$ = createEffect(() => this.actions$.pipe(
        ofAction(CompleteSectionAction),
        switchMap(x => this.dapDocumentStatusService.completeSection(x.id).pipe(
            switchMap(content => [
                new LoadDapDocumentContentAction(content),
                new RequestFilterFieldsUpdateAction(),
                new CompleteSectionOkAction()
            ]),
            catchError(error => {
                this.logger.error('Error in CompleteDocumentAction ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new CompleteSectionFailedAction(error));
            })
        ))
    ));

    
    public onAbandonSection$ = createEffect(() => this.actions$.pipe(
        ofAction(AbandonSectionAction),
        switchMap(x => this.dapDocumentStatusService.abandonSection(x.id).pipe(
            switchMap(content => [new LoadDapDocumentContentAction(content), new AbandonSectionOkAction()]),
            catchError(error => {
                this.logger.error('Error in AbandonDocumentAction ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new AbandonSectionFailedAction(error));
            })
        ))
    ));

    
    public onReactivateDap$ = createEffect(() => this.actions$.pipe(
        ofAction(ReactivateDapAction),
        switchMap(x => this.dapDocumentStatusService.reactivateDap(x.id).pipe(
            switchMap(content => [new LoadDapDocumentContentAction(content), new ReactivateDapOkAction()]),
            catchError(error => {
                this.logger.error('Error in ReactivateDocumentAction ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new ReactivateDapFailedAction(error));
            })
        ))
    ));

    
    public onCompleteDap$ = createEffect(() => this.actions$.pipe(
        ofAction(CompleteDapAction),
        switchMap(x => this.dapDocumentStatusService.completeDap(x.id).pipe(
            switchMap(content => [new LoadDapDocumentContentAction(content), new CompleteDapOkAction()]),
            catchError(error => {
                this.logger.error('Error in CompleteDocumentAction ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new CompleteDapFailedAction(error));
            })
        ))
    ));

    
    public onAbandonDap$ = createEffect(() => this.actions$.pipe(
        ofAction(AbandonDapAction),
        switchMap(x => this.dapDocumentStatusService.abandonDap(x.id).pipe(
            switchMap(content => [new LoadDapDocumentContentAction(content), new AbandonDapOkAction()]),
            catchError(error => {
                this.logger.error('Error in AbandonDocumentAction ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new AbandonDapFailedAction(error));
            })
        ))
    ));

    
    public onSaveScores$ = createEffect(() => this.actions$.pipe(
        ofAction(SaveScoresAction),
        switchMap(x => this.dapDocumentStatusService.saveScores(x.scores, x.linkTemplate).pipe(
            switchMap(content => [new LoadDapDocumentContentAction(content), new SaveScoresOkAction()]),
            catchError(error => {
                this.logger.error('Error in SaveScores ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new SaveScoresFailedAction(error));
            })
        ))
    ));

    
    public onGetHistoricalAverage$ = createEffect(() => this.actions$.pipe(
        ofAction(GetHistoricalAverageAction),
        switchMap(x => this.dapDocumentStatusService.getHistoricalAverage(x.documentId, x.filter).pipe(
            switchMap(content => [new LoadHistoricalAverageInStoreAction(content), new GetHistoricalAverageOkAction()]),
            catchError(error => {
                this.logger.error('Error in SaveScores ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new GetHistoricalAverageFailedAction(error));
            })
        ))
    ));


    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly dapDocumentStatusService: DapDocumentStatusService
    ) { }
}
