// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Action } from '@ngrx/store';
import { MnemonicCatalog } from '@/_models';

export class LoadMnemonicCatalogAction implements Action {
    public type = '[Mnemonic] Load MnemonicCatalog action';

    constructor(public readonly mnemonics: MnemonicCatalog[]) { }
}

//#region Fetch MnemonicCatalog

export class FetchMnemonicCatalogAction implements Action {
    public type = '[Mnemonic] Fetch MnemonicCatalog action';
    constructor(public readonly serviceProviderId?: number) { }
}

export class FetchOkMnemonicCatalogAction implements Action {
    public type = '[Mnemonic] Fetch OK MnemonicCatalog action';
}

export class FetchFailedMnemonicCatalogAction implements Action {
    public type = '[Mnemonic] Fetch Cancel MnemonicCatalog action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion Fetch MnemonicCatalog
