<div class="d-flex">
    <ng-select
        class="col-5 form-control"
        [items]="numberChoices"
        [clearable]="false"
        [(ngModel)]="numberChoice"
        [disabled]="disabled"
        (change)="onNumberChanged()"
        >
    </ng-select>
    <ng-select *ngIf="numberChoice === 1"
        class="col-7 form-control"
        [items]="intervalChoices"
        [clearable]="false"
        [(ngModel)]="intervalChoice"
        bindLabel="textSingle"
        [disabled]="disabled"
        (change)="onIntervalChanged()"
        >
    </ng-select>
    <ng-select *ngIf="numberChoice !== 1"
        class="col-7 form-control"
        [items]="intervalChoices"
        [clearable]="false"
        [(ngModel)]="intervalChoice"
        bindLabel="textPlural"
        [disabled]="disabled"
        (change)="onIntervalChanged()"
        >
    </ng-select>
</div>
