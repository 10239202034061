// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

export class SystemConfiguration {
    public logoImage: string;
    public smallLogoImage: string;
    public contactInfoEmail: string;
    public operatorContactEmail: string;
    public operatorId?: number;
    public userDocumentationUrl: string;
    public forgottenPasswordSubject: string;
    public forgottenPasswordTemplate: string;
    public requestAccessSubject: string;
    public requestAccessTemplate: string;
    public disabledUserSubject: string;
    public disabledUserTemplate: string;

    public smtpHost: string;
    public smtpPort: number;
    public smtpTimeout: number;
    public smtpUsername: string;
    public smtpPassword: string;
}
