<ngx-header
  [fixed]="true"
  [navbarBrandFull]="{ src: 'assets/pds-witsml-studio-logo.svg', width: 130, height: 50, alt: 'PDS Group Logo' }"
  [sidebarToggler]="isLogged ? 'lg' : false"
  [mobileSidebarToggler]="isLogged ? 'lg' : false"
  [asideMenuToggler]="false"
  [mobileAsideMenuToggler]="false"
>
  <ul id="page-header" class="nav navbar-nav ml-auto flex-nowrap">
    <li class="nav-item px-3 text-nowrap">
      <span class="app-title-centered">Dr.Q</span>
    </li>
  </ul>

  <div class="ml-auto">
    <app-loading-indicator class="mr-3" [intensity]="9"></app-loading-indicator>
  </div>
  <div class="ml-2">
    <app-user-dropdown></app-user-dropdown>
  </div>

</ngx-header>

<div class="app-body">
  <!-- side navigation menu -->
  <ngx-sidebar *ngIf="isLogged" [fixed]="true" [display]="'lg'" class="sidebar">
    <ngx-sidebar-nav [navItems]="navItems$ | async"></ngx-sidebar-nav>
  </ngx-sidebar>

  <!-- content/main body -->
  <main class="main h-100 p-0"><!-- new server warning -->
    <alert *ngIf="serverVersionService.hasCodeVersionChangedSinceLastLoad"
      class="code-has-changed-alert"
      [type]="'warning'"
      [dismissible]="false">
      <b>WARNING:</b> The application version has changed. Please <b>refresh</b> the page to get the new version.
      <b>Continuing work without refresh may result in errors or inoperable features.</b>
    </alert>
    <div class="container-fluid h-100 m-0 p-3">
      <ngx-alert [filterByType]="isProduction ? ['info', 'warning', 'danger'] : null"></ngx-alert>      
      <router-outlet></router-outlet>
    </div>
  </main>

  <ngx-aside [fixed]="true" [display]="false"></ngx-aside>
</div>

<ngx-footer [fixed]="false">
  <span><a href="https://pds.group" target="_blank">PDS Group</a> &copy; 2019</span>
  <span class="ml-auto">Powered by <a href="https://witsml.pds.technology" target="_blank">PDS WITSMLstudio</a></span>
</ngx-footer>
