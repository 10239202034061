//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------
import { ensureStoreMetadata } from './internals';
import { ActionType } from './of-action';

export function Action(...actionsKlasses: ActionType[]) {
  return function(target: any, name: string, descriptor: TypedPropertyDescriptor<any>) {
    const meta = ensureStoreMetadata(target.constructor);

    for (const klass of actionsKlasses) {
      const inst = new klass();
      const type = inst.type;

      if (meta.actions[type]) {
        throw new Error(
          `@Action for '${type}' is defined multiple times in functions '${meta.actions[type].fn}' and '${name}'`
        );
      }

      meta.actions[type] = {
        action: klass,
        fn: name,
        type
      };
    }
  };
}