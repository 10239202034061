// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { CommonModule } from '@angular/common';
import { NgModule} from '@angular/core';
import { RouterModule } from '@angular/router';
import { LayoutModule } from '../shared/layout';

// App Sidebar Component
import { NgxSidebarFooterComponent } from './ngx-sidebar-footer.component';
import { NgxSidebarFormComponent } from './ngx-sidebar-form.component';
import { NgxSidebarHeaderComponent } from './ngx-sidebar-header.component';
import { NgxSidebarMinimizerComponent } from './ngx-sidebar-minimizer.component';
import { NgxSidebarComponent } from './ngx-sidebar.component';
import {
  NgxSidebarNavComponent,
  AppSidebarNavDropdownComponent,
  AppSidebarNavItemComponent,
  AppSidebarNavLinkComponent,
  AppSidebarNavTitleComponent,
  NavDropdownDirective,
  NavDropdownToggleDirective,
  LinkAttributesDirective
} from './ngx-sidebar-nav.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    LayoutModule
  ],
  exports: [
    NgxSidebarFooterComponent,
    NgxSidebarFormComponent,
    NgxSidebarHeaderComponent,
    NgxSidebarMinimizerComponent,
    NgxSidebarComponent,
    NgxSidebarNavComponent,
    AppSidebarNavDropdownComponent,
    AppSidebarNavItemComponent,
    AppSidebarNavLinkComponent,
    AppSidebarNavTitleComponent,
    NavDropdownDirective,
    NavDropdownToggleDirective,
    LinkAttributesDirective,
    LayoutModule
  ],
  declarations: [
    NgxSidebarFooterComponent,
    NgxSidebarFormComponent,
    NgxSidebarHeaderComponent,
    NgxSidebarMinimizerComponent,
    NgxSidebarMinimizerComponent,
    NgxSidebarComponent,
    NgxSidebarNavComponent,
    AppSidebarNavDropdownComponent,
    AppSidebarNavItemComponent,
    AppSidebarNavLinkComponent,
    AppSidebarNavTitleComponent,
    NavDropdownDirective,
    NavDropdownToggleDirective,
    LinkAttributesDirective
  ]
})
export class NgxSidebarModule { }
