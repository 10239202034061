// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

/**
 * When an initial id/key is not defined or is not a valid id/key in a collection return the first(if available) element's key
 * @param selectedId the initial selection key
 * @param arr the selectedId will be validated against this array
 * @param setSelectedFunc function to update (if required)the selectedId/key
 * @param setOnEmptyKey whether to call setSelectedFunc if there is not a valid key to be selected
 */
export function selectFirstElementIfNotSelected<T extends { id: number }>(
    selectedId: number,
    arr: T[],
    setSelectedFunc: (x: number) => void,
    setOnEmptyKey = false
) {
    if (!arr) { arr = []; }
    if (arr.every(y => y.id !== selectedId)) {
        const newSelectedId = arr[0] && arr[0].id;
        if (newSelectedId != null || setOnEmptyKey) {
            setSelectedFunc(newSelectedId);
        }
    }
}
