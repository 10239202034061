// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { DapDocumentDetailsService } from '@/_services';
import { getMessageFromError, NgxAlertService } from '@/_shared';
import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { FetchEditingMonitoringUpdateSessionAction } from '../editing-monitoring';
import {
    AddInStoreDapDocumentContactAction,
    ApproveDapDocumentAction, ApproveDapDocumentFailedAction, ApproveDapDocumentOkAction,
    ClearDapDocumentApprovalAction, ClearDapDocumentApprovalFailedAction, ClearDapDocumentApprovalOkAction,
    CreateDapDocumentContactAction, CreateDapDocumentContactFailedAction, CreateDapDocumentContactOkAction,
    DeleteDapDocumentContactAction, DeleteDapDocumentContactFailedAction, DeleteDapDocumentContactOkAction,
    DeleteInStoreDapDocumentContactAction,
    FetchAdGroupsAction, FetchAdGroupsFailedAction, FetchAdGroupsOkAction,
    FetchDapDocumentContactsAction, FetchDapDocumentContactsFailedAction, FetchDapDocumentContactsOkAction,
    FetchDapDocumentContentAction, FetchDapDocumentContentFailedAction, FetchDapDocumentContentOkAction,
    FetchDapDocumentDataAction, FetchDapDocumentDataFailedAction, FetchDapDocumentDataOkAction,
    FetchDapTemplateContentAction, FetchDapTemplateContentFailedAction, FetchDapTemplateContentOkAction,
    FetchPicklistItemsAction, FetchPicklistItemsFailedAction, FetchPicklistItemsOkAction,
    LoadAdGroupsAction, LoadDapDocumentApprovalsAction, LoadDapDocumentBusinessStateAction, LoadDapDocumentContactsAction,
    LoadDapDocumentContentAction, LoadDapDocumentStatusAction, LoadDapTemplateContentAction, LoadPicklistItemsAction,
    SaveDapDocumentAction, SaveDapDocumentBusinessStatusAction, SaveDapDocumentBusinessStatusFailedAction,
    SaveDapDocumentBusinessStatusOkAction, SaveDapDocumentFailedAction, SaveDapDocumentOkAction,
    UpdateStatusAction, UpdateStatusFailedAction, UpdateStatusOkAction,
    UpdateDapDocumentContactAction, UpdateDapDocumentContactFailedAction, UpdateDapDocumentContactOkAction,
    UpdateInStoreDapDocumentContactAction,
    FetchScoringModelDefaultsAction,
    LoadScoringModelDefaultsAction,
    FetchScoringModelDefaultsOkAction,
    FetchScoringModelDefaultsFailedAction,
    LoadActiveDocChangeDataAction,
    FetchDapDocumentChangesAction,
    LoadDapDocumentChangesAction,
    FetchDapDocumentChangesOkAction,
    FetchDapDocumentChangesFailedAction,
} from './dap-document-details.actions';

@Injectable()
export class DapDocumentDetailsEffects {

    
    public onFetchDapTemplateContent$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchDapTemplateContentAction),
        switchMap(x => this.dapDocumentDetailsService.getTemplateContent(x.id).pipe(
            switchMap(content => [new LoadDapTemplateContentAction(content), new FetchDapTemplateContentOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchDapTemplateContent ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchDapTemplateContentFailedAction(error));
            })
        ))
    ));

    
    public onFetchDapDocumentContent$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchDapDocumentContentAction),
        switchMap(x => this.dapDocumentDetailsService.getDocumentContent(x.id).pipe(
            switchMap(data => [
                new LoadDapDocumentStatusAction(data.status),
                new LoadDapDocumentBusinessStateAction(data.businessState),
                new LoadDapDocumentContentAction(data.content),
                new LoadActiveDocChangeDataAction(data.activeDocChangeData),
                new FetchDapDocumentContentOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchDapDocumentContent ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchDapDocumentContentFailedAction(error));
            })
        ))
    ));

    
    public onSaveDapDocument$ = createEffect(() => this.actions$.pipe(
        ofAction(SaveDapDocumentAction),
        switchMap(x => this.dapDocumentDetailsService.saveDocument(x.id, x.documentDetails).pipe(
            switchMap(data => [
                new LoadDapDocumentContentAction(data.content),
                new LoadActiveDocChangeDataAction(data.activeDocChangeData),
                new SaveDapDocumentOkAction(),
                new FetchEditingMonitoringUpdateSessionAction(x.monitoringSession)]),
            catchError(error => {
                this.logger.error('Error in SaveDapDocument ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new SaveDapDocumentFailedAction(error));
            })
        ))
    ));

    
    public onUpdateStatus$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdateStatusAction),
        switchMap(x => this.dapDocumentDetailsService.updateStatus(x.documentId, x.newStatus).pipe(
            switchMap(statusResult => [
                new LoadDapDocumentStatusAction(statusResult.item1),
                new LoadDapDocumentBusinessStateAction(statusResult.item2),
                new UpdateStatusOkAction()]),
            catchError(error => {
                this.logger.error('Error in UpdateStatus ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new UpdateStatusFailedAction(error));
            })
        ))
    ));

    
    public onSaveDapDocumentBusinessState$ = createEffect(() => this.actions$.pipe(
        ofAction(SaveDapDocumentBusinessStatusAction),
        switchMap(x => this.dapDocumentDetailsService.saveDocumentBusinessState(x.documentId, x.businessState).pipe(
            switchMap(state => [
                new LoadDapDocumentBusinessStateAction(state),
                new SaveDapDocumentBusinessStatusOkAction()]),
            catchError(error => {
                this.logger.error('Error in SaveDapDocumentBusinessStatus ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new SaveDapDocumentBusinessStatusFailedAction(error));
            })
        ))
    ));

    
    public onFetchDapDocumentData$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchDapDocumentDataAction),
        switchMap(x => this.dapDocumentDetailsService.getDocumentData(x.id).pipe(
            switchMap(data => [
                new LoadDapDocumentApprovalsAction(data.approvals),
                new FetchDapDocumentDataOkAction()
            ]),
            catchError(error => {
                this.logger.error('Error in FetchDapDocumentApprovals ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchDapDocumentDataFailedAction(error));
            })
        ))
    ));

    
    public onApproveDapDocument$ = createEffect(() => this.actions$.pipe(
        ofAction(ApproveDapDocumentAction),
        switchMap(x => this.dapDocumentDetailsService.approveDocument(x.id, x.approval).pipe(
            switchMap(data => [
                new LoadDapDocumentStatusAction(data.status),
                new LoadDapDocumentApprovalsAction(data.approvals),
                new ApproveDapDocumentOkAction()]),
            catchError(error => {
                this.logger.error('Error in ApproveDapDocument ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new ApproveDapDocumentFailedAction(error));
            })
        ))
    ));

    
    public onClearDapDocumentApproval$ = createEffect(() => this.actions$.pipe(
        ofAction(ClearDapDocumentApprovalAction),
        switchMap(x => this.dapDocumentDetailsService.clearDocumentApproval(x.id).pipe(
            switchMap(data => [
                new LoadDapDocumentStatusAction(data.status),
                new LoadDapDocumentApprovalsAction(data.approvals),
                new ClearDapDocumentApprovalOkAction()]),
            catchError(error => {
                this.logger.error('Error in ClearDapDocumentApproval ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new ClearDapDocumentApprovalFailedAction(error));
            })
        ))
    ));

    
    public onFetchDapDocumentChanges$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchDapDocumentChangesAction),
        switchMap(x => this.dapDocumentDetailsService.getDocumentChanges(x.id).pipe(
            switchMap(documentChanges => [new LoadDapDocumentChangesAction(documentChanges), new FetchDapDocumentChangesOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchDapDocumentChanges ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchDapDocumentChangesFailedAction(error));
            })
        ))
    ));

    
    public onFetchDapDocumentContacts$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchDapDocumentContactsAction),
        switchMap(x => this.dapDocumentDetailsService.getDocumentContacts(x.id).pipe(
            switchMap(contacts => [new LoadDapDocumentContactsAction(contacts), new FetchDapDocumentContactsOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchDapDocumentContacts ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchDapDocumentContactsFailedAction(error));
            })
        ))
    ));

    
    public onCreateDapDocumentContact$ = createEffect(() => this.actions$.pipe(
        ofAction(CreateDapDocumentContactAction),
        switchMap(x => this.dapDocumentDetailsService.createDocumentContact(x.contact).pipe(
            switchMap(id => [
                new AddInStoreDapDocumentContactAction(id),
                new CreateDapDocumentContactOkAction()]),
            catchError(error => {
                this.logger.error('Error in CreateDapDocumentContactAction ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new CreateDapDocumentContactFailedAction(error));
            })
        ))
    ));

    
    public onUpdateDapDocumentContact$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdateDapDocumentContactAction),
        switchMap(x => this.dapDocumentDetailsService.updateDocumentContact(x.contact).pipe(
            switchMap(id => [
                new UpdateInStoreDapDocumentContactAction(id),
                new UpdateDapDocumentContactOkAction()]),
            catchError(error => {
                this.logger.error('Error in UpdateDapDocumentContactAction ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new UpdateDapDocumentContactFailedAction(error));
            })
        ))
    ));

    
    public onDeleteDapDocumentContact$ = createEffect(() => this.actions$.pipe(
        ofAction(DeleteDapDocumentContactAction),
        switchMap(x => this.dapDocumentDetailsService.deleteDocumentContact(x.id).pipe(
            switchMap(id => [
                new DeleteInStoreDapDocumentContactAction(id),
                new DeleteDapDocumentContactOkAction()]),
            catchError(error => {
                this.logger.error('Error in DeleteDapDocumentContactAction ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new DeleteDapDocumentContactFailedAction(error));
            })
        ))
    ));

    
    public onFetchPicklistItems$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchPicklistItemsAction),
        switchMap(() => this.dapDocumentDetailsService.getPicklistItems().pipe(
            switchMap(items => [new LoadPicklistItemsAction(items), new FetchPicklistItemsOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchPicklistItems ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchPicklistItemsFailedAction(error));
            })
        ))
    ));

    public onFetchAdGroups$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchAdGroupsAction),
        switchMap(() => this.dapDocumentDetailsService.getAdGroups().pipe(
            switchMap(groups => [new LoadAdGroupsAction(groups), new FetchAdGroupsOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchAdGroups ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchAdGroupsFailedAction(error));
            })
        ))
    ));

    public onFetchScoringModelDefaults$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchScoringModelDefaultsAction),
        switchMap((x) => this.dapDocumentDetailsService.getScoringModelDefaults(x.id).pipe(
            switchMap(defaults => [new LoadScoringModelDefaultsAction(defaults), new FetchScoringModelDefaultsOkAction()]),
            catchError(error => {
                this.logger.error('Error in FetchScoringModelDefaults ', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchScoringModelDefaultsFailedAction(error));
            })
        ))
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly dapDocumentDetailsService: DapDocumentDetailsService
    ) { }
}
