// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Action } from '@ngrx/store';
import { Tooltip } from '@/_models';

export class LoadTooltipsAction implements Action {
  public type = '[Tooltip] Load tooltips action';
  constructor(public readonly tooltips: Tooltip[]) {}
}

export class FetchTooltipsAction implements Action {
  public type = '[Tooltip] Fetch tooltips action';
  constructor(public readonly key: string) {}
}

export class FetchOkTooltipsAction implements Action {
  public type = '[Tooltip] Fetch OK tooltips action';
}

export class FetchFailedTooltipsAction implements Action {
  public type = '[Tooltip] Fetch Failed tooltips action';
  constructor(public readonly error: Error | string | any) {}
}
