// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { NGXLogger } from 'ngx-logger';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { NgxAlertService, getMessageFromError } from 'ngx-shared';
import { RoleService } from '@/_services';
import {
    LoadRolesAction, FetchRolesAction, FetchFailedRolesAction, FetchOkRolesAction,
} from './role.actions';

@Injectable()
export class RoleEffects {

    
    public onFetch$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchRolesAction),
        switchMap(() => this.roleService.getAll().pipe(
            switchMap(x => [new LoadRolesAction(x), new FetchOkRolesAction()]),
            catchError(error => {
                this.logger.error('Error while fetch of roles', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedRolesAction(error));
            })
        ))
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly roleService: RoleService
    ) { }
}
