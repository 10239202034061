//  -----------------------------------------------------------------------
//  PDS DRQe
//
//  Copyright 2019 PDS Americas LLC
//
//  Licensed under the PDS Open Source WITSML Product License Agreement (the
//  "License"); you may not use this file except in compliance with the License.
//  You may obtain a copy of the License at
//
//      http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//  Unless required by applicable law or agreed to in writing, software
//  distributed under the License is distributed on an "AS IS" BASIS,
//  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//  See the License for the specific language governing permissions and
//  limitations under the License.
//  -----------------------------------------------------------------------

import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { ofAction } from '../ngrx-actions/of-action';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, switchMap, debounceTime, map, mergeMap } from 'rxjs/operators';

import { NgxAlertService, getMessageFromError } from 'ngx-shared';
import { NGXLogger } from 'ngx-logger';
import { MonitorService, OrderService, IndexedDataService } from '@/_services';
import {
    LoadOrderOverviewsAction,
    FetchOrderOverviewsAction, FetchFailedOrderOverviewsAction, FetchOkOrderOverviewsAction,
    LoadInStateOverviewStatusesAction,
    FetchOverviewStatusesAction, FetchFailedOverviewStatusesAction, FetchOkOverviewStatusesAction,
    LoadInStateSectionMonitorInfoAction,
    FetchSectionMonitorInfoAction, FetchOkSectionMonitorInfoAction, FetchFailedSectionMonitorInfoAction,
    LoadInStateSectionMonitorStatusAction,
    FetchSectionMonitorStatusAction, FetchOkSectionMonitorStatusAction, FetchFailedSectionMonitorStatusAction,
    LoadInStateSectionTrendDataAction,
    FetchSectionTrendDataAction, FetchOkSectionTrendDataAction, FetchFailedSectionTrendDataAction,
    LoadInStateSectionInstanceDataAction,
    FetchSectionInstanceDataAction, FetchOkSectionInstanceDataAction, FetchFailedSectionInstanceDataAction,
    FetchSectionIssuesAction, FetchFailedSectionIssueAction, FetchOkSectionIssueAction,
    LoadInStateSectionIssuesAction, ClearInStateSectionIssuesAction,
    UpdateInStateRuleInstanceStateAction,
    UpdateRuleInstanceStateAction, UpdateOkRuleInstanceStateAction, UpdateFailedRuleInstanceStateAction,
    LoadInStateRuleInstanceLastCheckedDataAction, FetchRuleInstanceLastCheckedDataAction,
    FetchOkRuleInstanceLastCheckedDataAction, FetchFailedRuleInstanceLastCheckedDataAction, FetchRuleInstanceProblemTimeAction,
    LoadInStateRuleInstanceProblemTimeAction,
    FetchOkRuleInstanceProblemTimeAction,
    FetchFailedRuleInstanceProblemTimeAction,
    FetchMonitorStatusAfterDelay,
    StartStopOrderOverviewsAction,
    UpdateOrderOverviewsAction,
    StartStopFailedOrderOverviewsAction,
    StartStopOkOrderOverviewsAction,
    UpdateRuleInstanceAlertStateAction, UpdateOkRuleInstanceAlertStateAction, UpdateFailedRuleInstanceAlertStateAction,
    UpdateInStateRuleInstanceAlertStateAction,
    FetchMonitorMyRigsAction, LoadInStateMonitorMyRigsAction, FetchOkMonitorMyRigsAction, FetchFailedMonitorMyRigsAction,
    FetchHDepDecimatedLogAction,
    FetchHDepDecimatedLogOkAction,
    FetchHDepDecimatedLogFailedAction,
    LoadHDepDecimatedLogInStateAction,
    FetchBDepDecimatedLogAction,
    LoadBDepDecimatedLogInStateAction,
    FetchBDepDecimatedLogOkAction,
    FetchBDepDecimatedLogFailedAction,
    FetchMatchingIssuesAction, LoadInStateMatchingIssuesAction, FetchOkMatchingIssuesAction, FetchFailedMatchingIssuesAction,
    ClearInStateMatchingIssuesAction,
    SetRuleSuppressionAction,
    SetRuleSuppressionOkAction,
    SetRuleSuppressionFailedAction,
    RequestDataRefreshAction,
    SetProblemTimeSuppressionAction,
    SetProblemTimeSuppressionOkAction,
    SetProblemTimeSuppressionFailedAction,
    GetPlottableLogsAction,
    GetPlottableLogsOkAction,
    GetPlottableLogsFailedAction,
    SavePlottableLogsInStateAction,
    GetAdditionalDecimatedCurveAction,
    GetAdditionalDecimatedCurveOkAction,
    GetAdditionalDecimatedCurveFailedAction,
    SaveAdditionalDecimatedCurveInStateAction,
    GetAdditionalDecimatedCurvesAction,
    SaveAdditionalDecimatedCurvesInStateAction,
    GetAdditionalDecimatedCurvesOkAction,
    GetAdditionalDecimatedCurvesFailedAction,
    GetDrqKpiScoresAction,
    GetDrqKpiScoresOkAction,
    GetDrqKpiScoresFailedAction,
    SaveDrqKpiScoresInStateAction,
    SaveDapKpiScoresInStateAction,
    GetDapKpiScoresOkAction,
    GetDapKpiScoresFailedAction,
    GetDapKpiScoresAction,
    GetDrqKpiTrendAction,
    GetDrqKpiTrendOkAction,
    GetDrqKpiTrendFailedAction,
    SaveDrqKpiTrendInStateAction,
    GetDapKpiTrendAction,
    SaveDapKpiTrendInStateAction,
    GetDapKpiTrendOkAction,
    GetDapKpiTrendFailedAction,
} from './monitor.actions';
import { MonitorTag, OverviewStatuses } from '@/_models';
import { KpiScoresService } from '@/_services/kpi-scores.service';

@Injectable()
export class MonitorEffects {
    public static delayAfterRuleInstanceStateUpdate = 2000;

    
    public onFetch$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchOrderOverviewsAction),
        switchMap(() => this.monitorService.getOverviewOrderSections().pipe(
            switchMap(x => [new LoadOrderOverviewsAction(x), new FetchOkOrderOverviewsAction()]),
            catchError(error => {
                this.logger.error('Error while fetch of order overviews', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedOrderOverviewsAction(error));
            })
        ))
    ));

    
    public onStartStop$ = createEffect(() => this.actions$.pipe(
        ofAction(StartStopOrderOverviewsAction),
        switchMap((x) => this.orderService.updateSectionStatusWithState(x.sectionId, x.newState, x.oldState).pipe(
            switchMap((y) => [new UpdateOrderOverviewsAction(y.sectionId, y.newState), new StartStopOkOrderOverviewsAction()]),
            catchError(error => {
                this.logger.error('Error while fetch of order overviews', error);
                this.alertService.error(getMessageFromError(error));
                return of(new StartStopFailedOrderOverviewsAction(error));
            })
        ))
    ));

    
    public onServerFetchOverviewStatuses$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchOverviewStatusesAction),
        switchMap((a) => this.getAllOverviewStatuses(a.sectionIds).pipe(
            switchMap(x => [new LoadInStateOverviewStatusesAction(Array.isArray(x) ? x.filter(s => s != null) : []),
            new FetchOkOverviewStatusesAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch OverviewStatuses', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedOverviewStatusesAction(error));
            })
        ))
    ));

    private getAllOverviewStatuses(sectionIds: number[]): Observable<OverviewStatuses[]> {
        const observableBatch: Observable<OverviewStatuses>[] = [];
        sectionIds.forEach(sectionId =>
            observableBatch.push(
                this.monitorService.getOverviewStatuses(sectionId).pipe(
                    catchError(error => {
                        this.logger.error(`Could not fetch status for section ${sectionId}`, error);
                        this.alertService.error(getMessageFromError(error));
                        return of(undefined);
                    })
                )
            )
        );
        return forkJoin(observableBatch);
    }

    
    public onServerFetchSectionMonitorInfo$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchSectionMonitorInfoAction),
        switchMap(a => this.monitorService.getSectionMonitorInfo(a.sectionId).pipe(
            switchMap(x => [new LoadInStateSectionMonitorInfoAction(x), new FetchOkSectionMonitorInfoAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch SectionMonitorInfo', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedSectionMonitorInfoAction(error));
            })
        ))
    ));

    
    public onServerFetchSectionMonitorStatus$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchSectionMonitorStatusAction),
        switchMap(a => this.getSectionMonitorStatuses(a.sectionId))
    ));

    
    public onServerFetchSectionTrendData$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchSectionTrendDataAction),
        switchMap(a => this.monitorService.getSectionMonitorTrendData(a.sectionId, a.earliestTime, a.latestTime, a.categoryIds, a.targetId
        ).pipe(
            switchMap(x => [new LoadInStateSectionTrendDataAction(x), new FetchOkSectionTrendDataAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch SectionTrendData', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedSectionTrendDataAction(error));
            })
        ))
    ));

    
    public onServerFetchSectionInstanceData$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchSectionInstanceDataAction),
        switchMap(a => this.monitorService.getSectionMonitorInstanceData(a.sectionId, a.targetId,
            a.categoryService, a.categoryType, a.categorySubject, a.subjectType, a.subjectId, a.monitorId
        ).pipe(
            switchMap(x => [
                new LoadInStateSectionInstanceDataAction({ instances: x, sectionId: a.sectionId, categoryId: a.categoryId }),
                new FetchOkSectionInstanceDataAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch SectionInstanceData', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedSectionInstanceDataAction(error));
            })
        ))
    ));

    
    public onServerFetchSectionIssues$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchSectionIssuesAction),
        switchMap(a => this.monitorService.getSectionMonitorIssues(a.ruleInstanceIds).pipe(
            switchMap(x => [new LoadInStateSectionIssuesAction(x), new FetchOkSectionIssueAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch SectionIssues', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedSectionIssueAction(error));
            })
        ))
    ));

    
    public onClearStateIssues$ = createEffect(() => this.actions$.pipe(
        ofAction(ClearInStateSectionIssuesAction),
        map(() => new LoadInStateSectionIssuesAction(null))
    ));

    
    public onServerUpdateRuleInstanceState$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdateRuleInstanceStateAction),
        switchMap(a => this.monitorService.updateRuleInstanceState(a.sectionId, a.ids, a.isEnabled).pipe(
            switchMap(() => [
                new UpdateInStateRuleInstanceStateAction(a.ids, a.isEnabled),
                new UpdateOkRuleInstanceStateAction(),
                new FetchMonitorStatusAfterDelay(a.sectionId)
            ]),
            catchError(error => {
                this.logger.error('Could not Update RuleInstanceState', error);
                this.alertService.error(getMessageFromError(error));
                return of(new UpdateFailedRuleInstanceStateAction(error));
            })
        ))
    ));

    public onServerUpdateRuleInstanceAlertState$ = createEffect(() => this.actions$.pipe(
        ofAction(UpdateRuleInstanceAlertStateAction),
        switchMap(a => this.monitorService.updateRuleInstanceAlertState(a.sectionId, a.ids, a.isEnabled).pipe(
            switchMap(() => [
                new UpdateInStateRuleInstanceAlertStateAction(a.ids, a.isEnabled),
                new UpdateOkRuleInstanceAlertStateAction(),
                new FetchMonitorStatusAfterDelay(a.sectionId)
            ]),
            catchError(error => {
                this.logger.error('Could not Update RuleInstanceAlertState', error);
                this.alertService.error(getMessageFromError(error));
                return of(new UpdateFailedRuleInstanceAlertStateAction(error));
            })
        ))
    ));
    
    public onAfterServerUpdateRuleInstanceState$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchMonitorStatusAfterDelay),
        debounceTime(MonitorEffects.delayAfterRuleInstanceStateUpdate),
        switchMap(a => this.getSectionMonitorStatuses(a.sectionId))
    ));

    
    public onServerFetchRuleInstanceLastCheckedData$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchRuleInstanceLastCheckedDataAction),
        switchMap(a => this.monitorService.getRuleInstanceLastCheckedData(a.sectionId, a.ruleInstanceIds).pipe(
            switchMap(x => [new LoadInStateRuleInstanceLastCheckedDataAction(x), new FetchOkRuleInstanceLastCheckedDataAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch RuleInstanceLastCheckedData', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedRuleInstanceLastCheckedDataAction(error));
            })
        ))
    ));

    
    public onServerFetchRuleInstanceProblemTime$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchRuleInstanceProblemTimeAction),
        switchMap(a => this.monitorService.getRuleInstanceProblemTime(a.ruleInstanceIds).pipe(
            switchMap(x => [new LoadInStateRuleInstanceProblemTimeAction(x), new FetchOkRuleInstanceProblemTimeAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch RuleInstance ProblemTime', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedRuleInstanceProblemTimeAction(error));
            })
        ))
    ));
    
    public onFetchMyRigs$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchMonitorMyRigsAction),
        switchMap(() => this.monitorService.getMyRigs().pipe(
            switchMap(x => [new LoadInStateMonitorMyRigsAction(x), new FetchOkMonitorMyRigsAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch My Rigs', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedMonitorMyRigsAction(error));
            })
        ))
    ));

    public onFetchHDepDecimatedLog$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchHDepDecimatedLogAction),
        switchMap((a) => this.indexedDataService.getDecimatedLog(a.sectionId, MonitorTag.HDep, a.periodInSeconds, a.maxPoints, a.timeZone).pipe(
            switchMap(x => [new LoadHDepDecimatedLogInStateAction(x), new FetchHDepDecimatedLogOkAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch HDep Decimated log', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchHDepDecimatedLogFailedAction(error));
            })
        ))
    ));

    public onFetchMatchingIssues$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchMatchingIssuesAction),
        switchMap(a => this.monitorService.getMatchingIssues(a.filter).pipe(
            switchMap(x => [new LoadInStateMatchingIssuesAction(x), new FetchOkMatchingIssuesAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch MatchingIssues', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedMatchingIssuesAction(error));
            })
        ))
    ));

    public onFetchBDepDecimatedLog$ = createEffect(() => this.actions$.pipe(
        ofAction(FetchBDepDecimatedLogAction),
        switchMap((a) => this.indexedDataService.getDecimatedLog(a.sectionId, MonitorTag.BDep, a.periodInSeconds, a.maxPoints, a.timeZone).pipe(
            switchMap(x => [new LoadBDepDecimatedLogInStateAction(x), new FetchBDepDecimatedLogOkAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch BDep Decimated log', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchBDepDecimatedLogFailedAction(error));
            })
        ))
    ));

    public onClearStateMatchingIssues$ = createEffect(() => this.actions$.pipe(
        ofAction(ClearInStateMatchingIssuesAction),
        map(() => new LoadInStateMatchingIssuesAction(null))
    ));

    public onSetRuleSuppression$ = createEffect(() => this.actions$.pipe(
        ofAction(SetRuleSuppressionAction),
        switchMap((a) => this.monitorService.setRuleSuppression(a.ruleInstanceId, a.info, a.sectionId).pipe(
            switchMap(() => [new SetRuleSuppressionOkAction(), new RequestDataRefreshAction(new Date())]),
            catchError(error => {
                this.logger.error('Could not set rule suppression', error);
                this.alertService.error(getMessageFromError(error));
                return of(new SetRuleSuppressionFailedAction(error));
            })
        ))
    ));

    public onSetProblemTimeSuppression$ = createEffect(() => this.actions$.pipe(
        ofAction(SetProblemTimeSuppressionAction),
        switchMap((a) => this.monitorService.setProblemTimeSuppression(a.ruleInstanceId, a.info, a.sectionId).pipe(
            switchMap(() => [
                new SetProblemTimeSuppressionOkAction(),
                new RequestDataRefreshAction(new Date()),
                new FetchSectionIssuesAction(a.ruleInstanceIdsToReload)
            ]),
            catchError(error => {
                this.logger.error('Could not set problem time suppression', error);
                this.alertService.error(getMessageFromError(error));
                return of(new SetProblemTimeSuppressionFailedAction(error));
            })
        ))
    ));

    public onGetPlottableLogs$ = createEffect(() => this.actions$.pipe(
        ofAction(GetPlottableLogsAction),
        switchMap((a) => this.indexedDataService.getPlottableLogs(a.sectionId).pipe(
            switchMap((a) => [new GetPlottableLogsOkAction(), new SavePlottableLogsInStateAction(a)]),
            catchError(error => {
                this.logger.error('Could not get plottable logs', error);
                this.alertService.error(getMessageFromError(error));
                return of(new GetPlottableLogsFailedAction(error));
            })
        ))
    ));

    public getAdditionalDecimatedCurve$ = createEffect(() => this.actions$.pipe(
        ofAction(GetAdditionalDecimatedCurveAction),
        mergeMap((a) => this.indexedDataService.getDecimatedLog(a.sectionId, a.tag, a.periodInSeconds, a.maxPoints, a.timeZone).pipe(
            switchMap(x => [new SaveAdditionalDecimatedCurveInStateAction(x), new GetAdditionalDecimatedCurveOkAction()]),
            catchError(error => {
                this.logger.error('Could not get additional decimated curve', error);
                this.alertService.error(getMessageFromError(error));
                return of(new GetAdditionalDecimatedCurveFailedAction(error));
            })
        ))
    ));

    public getAdditionalDecimatedCurves$ = createEffect(() => this.actions$.pipe(
        ofAction(GetAdditionalDecimatedCurvesAction),
        mergeMap((a) => this.indexedDataService.getDecimatedLogs(a.sectionId, a.tags, a.periodInSeconds, a.maxPoints, a.timeZone).pipe(
            switchMap(x => [new SaveAdditionalDecimatedCurvesInStateAction(x), new GetAdditionalDecimatedCurvesOkAction()]),
            catchError(error => {
                this.logger.error('Could not get additional decimated curves', error);
                this.alertService.error(getMessageFromError(error));
                return of(new GetAdditionalDecimatedCurvesFailedAction(error));
            })
        ))
    ));

    public getDrqKpiScores$ = createEffect(() => this.actions$.pipe(
        ofAction(GetDrqKpiScoresAction),
        mergeMap((a) => this.kpiScoresService.getDrqScoresTree(a.sectionId).pipe(
            switchMap(x => [new SaveDrqKpiScoresInStateAction(x), new GetDrqKpiScoresOkAction()]),
            catchError(error => {
                this.logger.error('Could not get drq kpi scores tree', error);
                this.alertService.error(getMessageFromError(error));
                return of(new GetDrqKpiScoresFailedAction(error));
            })
        ))
    ));

    public getDapKpiScores$ = createEffect(() => this.actions$.pipe(
        ofAction(GetDapKpiScoresAction),
        mergeMap((a) => this.kpiScoresService.getDapScoresTree(a.sectionId).pipe(
            switchMap(x => [new SaveDapKpiScoresInStateAction(x), new GetDapKpiScoresOkAction()]),
            catchError(error => {
                this.logger.error('Could not get Dap kpi scores tree', error);
                this.alertService.error(getMessageFromError(error));
                return of(new GetDapKpiScoresFailedAction(error));
            })
        ))
    ));
    
    public getDrqKpiTrend$ = createEffect(() => this.actions$.pipe(
        ofAction(GetDrqKpiTrendAction),
        mergeMap((a) => this.kpiScoresService.getDrqScoreTrends(a.request).pipe(
            switchMap(x => [new SaveDrqKpiTrendInStateAction(x), new GetDrqKpiTrendOkAction()]),
            catchError(error => {
                this.logger.error('Could not get Drq kpi trends', error);
                this.alertService.error(getMessageFromError(error));
                return of(new GetDrqKpiTrendFailedAction(error));
            })
        ))
    ));
    
    public getDapKpiTrend$ = createEffect(() => this.actions$.pipe(
        ofAction(GetDapKpiTrendAction),
        mergeMap((a) => this.kpiScoresService.getDapScoreTrends(a.request).pipe(
            switchMap(x => [new SaveDapKpiTrendInStateAction(x), new GetDapKpiTrendOkAction()]),
            catchError(error => {
                this.logger.error('Could not get Drq kpi trends', error);
                this.alertService.error(getMessageFromError(error));
                return of(new GetDapKpiTrendFailedAction(error));
            })
        ))
    ));

    constructor(
        private readonly actions$: Actions,
        private readonly alertService: NgxAlertService,
        private readonly logger: NGXLogger,
        private readonly monitorService: MonitorService,
        private readonly orderService: OrderService,
        private readonly indexedDataService: IndexedDataService,
        private readonly kpiScoresService: KpiScoresService,
    ) { }

    private getSectionMonitorStatuses(sectionId: number) {
        return this.monitorService.getSectionMonitorStatuses(sectionId).pipe(
            switchMap(x => [new LoadInStateSectionMonitorStatusAction(x), new FetchOkSectionMonitorStatusAction()]),
            catchError(error => {
                this.logger.error('Could not Fetch SectionMonitorStatus', error);
                this.alertService.error(getMessageFromError(error));
                return of(new FetchFailedSectionMonitorStatusAction(error));
            })
        );
    }
}
