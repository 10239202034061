// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

export enum DapDocumentStatus {
    Draft = 0,
    Open = 1,
    Approval = 2,
    Approved = 3,
    Active = 4,
    Completed = 5,
    Abandoned = 6,
    ReadyForApproval = 7,
}

export enum DapSectionStatus {
    Design = 0,
    Active = 1,
    Completed = 2,
    Abandoned = 3,
}

export enum DapDapStatus {
    Design = 0,
    Active = 1,
    Scored = 2,
    Completed = 3,
    Abandoned = 4,
}
