// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { environment } from '../../environments/environment';

import { DapKpiScore, DapKpiScoreTrendRequest, DrqKpiScore, DrqKpiScoreTrendRequest, KpiScoreTrendCollection } from '@/_models';
import { PublicPart } from '@/_shared';

@Injectable({ providedIn: 'root' })
export class KpiScoresService {

    constructor(private http: HttpClient) { }

    getDrqScoresTree(sectionId: number): Observable<DrqKpiScore> {
        return this.http.get<DrqKpiScore>(`${environment.statusAggregatorApi}/kpiscore/drq/${sectionId}`, {
            headers: {
                SectionId: sectionId.toString()
            }
        });
    }
    
    getDapScoresTree(sectionId: number): Observable<DapKpiScore> {
        return this.http.get<DapKpiScore>(`${environment.statusAggregatorApi}/kpiscore/dap/${sectionId}`, {
            headers: {
                SectionId: sectionId.toString()
            }
        });
    }

    getDrqScoreTrends(request: DrqKpiScoreTrendRequest): Observable<KpiScoreTrendCollection> {
        return this.http.post<KpiScoreTrendCollection>(`${environment.statusAggregatorApi}/kpiscore/drq/trend`, request, {
            headers: {
                SectionId: request.sectionId.toString()
            }
        }).pipe(
            map(trends => {
                if (trends) {
                    trends.trends.forEach(t => {
                        t.trend.forEach(p => {
                            p.eventDate = new Date(p.eventDate);
                        });
                    });
                    trends.timestamp = new Date();
                }
                return trends;
            })
        );
    }

    getDapScoreTrends(request: DapKpiScoreTrendRequest): Observable<KpiScoreTrendCollection> {
        return this.http.post<KpiScoreTrendCollection>(`${environment.statusAggregatorApi}/kpiscore/dap/trend`, request, {
            headers: {
                SectionId: request.sectionId.toString()
            }
        }).pipe(
            map(trends => {
                if (trends) {
                    trends.trends.forEach(t => {
                        t.trend.forEach(p => {
                            p.eventDate = new Date(p.eventDate);
                        });
                    });
                    trends.timestamp = new Date();
                }
                return trends;
            })
        );
    }
}

/* eslint-disable */
export class FakeKpiScoresService implements PublicPart<KpiScoresService> {
    getDrqScoresTree(sectionId: number): Observable<DrqKpiScore> {
        throw new Error("Method not implemented.");
    }
    getDapScoresTree(sectionId: number): Observable<DapKpiScore> {
        throw new Error('Method not implemented.');
    }
    getDrqScoreTrends(request: DrqKpiScoreTrendRequest): Observable<KpiScoreTrendCollection> {
        throw new Error('Method not implemented.');
    }
    getDapScoreTrends(request: DapKpiScoreTrendRequest): Observable<KpiScoreTrendCollection> {
        throw new Error('Method not implemented.');
    }
}