//   -----------------------------------------------------------------------
//   PDS DRQe
//
//   Copyright 2019 PDS Americas LLC
//
//   Licensed under the PDS Open Source WITSML Product License Agreement (the
//   "License"); you may not use this file except in compliance with the License.
//   You may obtain a copy of the License at
//
//       http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//   Unless required by applicable law or agreed to in writing, software
//   distributed under the License is distributed on an "AS IS" BASIS,
//   WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//   See the License for the specific language governing permissions and
//   limitations under the License.
//   -----------------------------------------------------------------------

import { formatDistanceStrict } from 'date-fns';

/***
 * Defines a single duration in words in a format for the application.
 * @param {Date} startTime
 * @param {Date} endTime
 * @returns {string} Returns the duration in words, using strict units.
 * |    Distance between dates   |  Result               |
 * |    less then 1 s            |  less then a second   |
 * |    0 ... 59 secs            |  [0..59] seconds      |
 * |    1 ... 59 mins	         |  [1..59] minutes      |
 * |    1 ... 23 hrs	         |  [1..23] hours        |
 * |    1 ... 29 days	         |  [1..29] days         |
 * |    1 ... 11 months	         |  [1..11] months       |
 * |    1 ... N years	         |  [1..N] years         |
 */
export function formatDuration(startTime: Date, endTime: Date): string {
    if (!startTime) {
        return null;
    }

    if (!endTime) {
        return null;
    }

    // dates could be as string
    return formatDurationFromSeconds((new Date(endTime).getTime() - new Date(startTime).getTime()) / 1000);
}

/***
 * Defines a single duration in words in a format for the application.
 * @param {number} duration Duration in seconds
 * @returns {string} Returns the duration in words, using strict units.
 * |    Distance between dates   |  Result               |
 * |    less then 1 s            |  less then a second   |
 * |    0 ... 59 secs            |  [0..59] seconds      |
 * |    1 ... 59 mins	         |  [1..59] minutes      |
 * |    1 ... 23 hrs	         |  [1..23] hours        |
 * |    1 ... 29 days	         |  [1..29] days         |
 * |    1 ... 11 months	         |  [1..11] months       |
 * |    1 ... N years	         |  [1..N] years         |
 */
export function formatDurationFromSeconds(duration: number): string {
    if (duration == null) {
        return null;
    }

    let result = null;

    // is duration below 1 second
    if (duration < 1) {
        result = 'less than a second';
    } else {
        result = formatDistanceStrict(0, duration * 1000);
    }

    return result;
}
