// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Action } from '@ngrx/store';
import { ServerConnection, SoapConnection, SimpleConnection } from '@/_models';
import { ConnectionTestStatus } from './server-connection.state';

export class LoadInStateServerConnectionsAction implements Action {
    readonly type = '[ServerConnection] InState Load ServerConnection action';

    constructor(public readonly serverConnections: ServerConnection[]) { }
}

export class SetSelectedServerConnectionAction implements Action {
    public type = '[ServerConnection] Set Selected ServerConnection action';
    constructor(public readonly serverConnectionId: number) { }
}

export class ResetServerConnectionInStateAction implements Action {
    public type = '[ServerConnection] Reset ServerConnection in state action';
    constructor(public readonly serverConnectionId: number) { }
}

//#region fetch

export class FetchServerConnectionsAction implements Action {
    public type = '[ServerConnection] Fetch ServerConnections action';
}
export class FetchOkServerConnectionsAction implements Action {
    public type = '[ServerConnection] Fetch OK ServerConnections action';
}
export class FetchFailedServerConnectionsAction implements Action {
    public type = '[ServerConnection] Fetch Cancel ServerConnections action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion fetch

//#region add

export class AddServerConnectionAction implements Action {
    readonly type = '[ServerConnection] Add ServerConnection action';

    constructor(public readonly serverConnection: ServerConnection) { }
}

export class CancelAddServerConnectionAction implements Action {
    readonly type = '[ServerConnection] Cancel Add ServerConnection action';

    constructor(public readonly serverConnectionId: number) { }
}

//#endregion add

//#region create

export class CreateInStateServerConnectionAction implements Action {
    readonly type = '[ServerConnection] Create InState ServerConnection action';

    constructor(public readonly serverConnection: ServerConnection) { }
}

export class CreateServerConnectionAction implements Action {
    public type = '[ServerConnection] Create ServerConnection action';
    constructor(public readonly serverConnection: ServerConnection) { }
}

export class CreateOkServerConnectionAction implements Action {
    public type = '[ServerConnection] Create OK ServerConnection action';
}

export class CreateFailedServerConnectionAction implements Action {
    public type = '[ServerConnection] Create Cancel ServerConnection action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion create

//#region update

export class UpdateInStateServerConnectionAction implements Action {
    readonly type = '[ServerConnection] Update InState ServerConnection action';

    constructor(public readonly serverConnection: ServerConnection) { }
}

export class UpdateServerConnectionAction implements Action {
    public type = '[ServerConnection] Update ServerConnection action';

    constructor(public readonly serverConnection: ServerConnection) { }
}

export class UpdateOkServerConnectionAction implements Action {
    public type = '[ServerConnection] Update OK ServerConnection action';
}

export class UpdateFailedServerConnectionAction implements Action {
    public type = '[ServerConnection] Update Cancel ServerConnection action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion update

//#region delete

export class DeleteInStateServerConnectionAction implements Action {
    readonly type = '[ServerConnection] Delete InState ServerConnection action';

    constructor(public readonly serverConnectionId: number) { }
}

export class DeleteServerConnectionAction implements Action {
    public type = '[ServerConnection] Delete ServerConnection action';

    constructor(public readonly serverConnectionId: number) { }
}

export class DeleteOkServerConnectionAction implements Action {
    public type = '[ServerConnection] Delete OK ServerConnection action';
}

export class DeleteFailedServerConnectionAction implements Action {
    public type = '[ServerConnection] Delete Cancel ServerConnection action';
    constructor(public readonly error: Error | string | any) { }
}

//#endregion delete

export class SetSoapTestStatusAction implements Action {
    public type = '[ServerConnection] Set SOAP test status action';
    constructor(public readonly status: ConnectionTestStatus, public readonly errorMessage?: string) { }
}

//#region async Test Soap

export class TestSoapAction implements Action {
    public type = '[ServerConnection] Test Soap action';
    constructor(public readonly data: SoapConnection) { }
}
export class TestOkSoapAction implements Action {
    public type = '[ServerConnection] Test OK Soap action';
}
export class TestFailedSoapAction implements Action {
    public type = '[ServerConnection] Test Cancel Soap action';
    constructor(public readonly error: Error | string | any) {}
}

//#endregion async Test Soap


export class LoadInStateSimpleConnectionAction implements Action {
    readonly type = '[ServerConnection] InState Load SimpleConnection action';

    constructor(public readonly connections: SimpleConnection[]) { }
}

//#region async Fetch SimpleConnection

export class FetchSimpleConnectionAction implements Action {
    public type = '[ServerConnection] Fetch SimpleConnection action';
}
export class FetchOkSimpleConnectionAction implements Action {
    public type = '[ServerConnection] Fetch OK SimpleConnection action';
}
export class FetchFailedSimpleConnectionAction implements Action {
    public type = '[ServerConnection] Fetch Cancel SimpleConnection action';
    constructor(public readonly error: Error | string | any) {}
}

//#endregion async Fetch SimpleConnection
