// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { Store, Action } from '../ngrx-actions';
import { LoadSystemConfigurationAction } from './admin.actions';
import { AdminState } from './admin.state';

@Store({} as AdminState)
export class AdminStore {

    @Action(LoadSystemConfigurationAction)
    public loadSystemConfig(state: AdminState, { data }: LoadSystemConfigurationAction) {
        return {
            ...state,
            contactInfoEmail: data && data.contactInfoEmail,
            userDocumentationUrl: data && data.userDocumentationUrl,
            logoImage: data && data.logoImage,
            smallLogoImage: data && data.smallLogoImage,
            forgottenPasswordSubject: data && data.forgottenPasswordSubject,
            forgottenPasswordTemplate: data && data.forgottenPasswordTemplate,
            requestAccessSubject: data && data.requestAccessSubject,
            requestAccessTemplate: data && data.requestAccessTemplate,
            disabledUserSubject: data && data.disabledUserSubject,
            disabledUserTemplate: data && data.disabledUserTemplate,
            operatorId: data && data.operatorId,
            operatorContactEmail: data && data.operatorContactEmail,
            smtpHost: data && data.smtpHost,
            smtpPort: data && data.smtpPort,
            smtpTimeout: data && data.smtpTimeout,
            smtpUsername: data && data.smtpUsername
        } as AdminState;
    }
}
