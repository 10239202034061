// -----------------------------------------------------------------------
// PDS DRQe
//
// Copyright 2019 PDS Americas LLC
//
// Licensed under the PDS Open Source WITSML Product License Agreement (the
// "License"); you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an 'AS IS' BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
// -----------------------------------------------------------------------

import { EditingMonitoringSession } from '@/_models/editing-monitoring-session';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})

export class EditingMonitoringService {

    constructor(private http: HttpClient) { }

    createSession(monitoringKey: string)
        : Observable<EditingMonitoringSession> {
            return this.http.get<EditingMonitoringSession>
                (`${environment.portalWebApi}/EditingMonitoring/create/DapDocument/${monitoringKey}`);
    }

    updateSession(monitoringSession: EditingMonitoringSession)
        : Observable<EditingMonitoringSession> {
            return this.http.post<EditingMonitoringSession>
                (`${environment.portalWebApi}/EditingMonitoring/update/DapDocument`, monitoringSession);
    }

    refreshSession(monitoringSession: EditingMonitoringSession)
        : Observable<EditingMonitoringSession[]> {
            return this.http.post<EditingMonitoringSession[]>
                (`${environment.portalWebApi}/EditingMonitoring/refresh/DapDocument`, monitoringSession);
    }

    startView(monitoringSession: EditingMonitoringSession) {
            return this.http.post<EditingMonitoringSession[]>
                (`${environment.portalWebApi}/EditingMonitoring/startView/DapDocument`, monitoringSession);
    }

    startEdit(monitoringSession: EditingMonitoringSession) {
            return this.http.post<EditingMonitoringSession[]>
                (`${environment.portalWebApi}/EditingMonitoring/startEdit/DapDocument`, monitoringSession);
    }

    endSession(sessionId: string) {
            return this.http.get<EditingMonitoringSession[]>
                (`${environment.portalWebApi}/EditingMonitoring/DapDocument/${sessionId}/end`);
    }
}
