//  -----------------------------------------------------------------------
//  PDS DRQe
//
//  Copyright 2019 PDS Americas LLC
//
//  Licensed under the PDS Open Source WITSML Product License Agreement (the
//  "License"); you may not use this file except in compliance with the License.
//  You may obtain a copy of the License at
//
//      http://www.pds.group/WITSMLstudio/OpenSource/ProductLicenseAgreement
//
//  Unless required by applicable law or agreed to in writing, software
//  distributed under the License is distributed on an "AS IS" BASIS,
//  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
//  See the License for the specific language governing permissions and
//  limitations under the License.
//  -----------------------------------------------------------------------

import { GridSize, IAgColumnDefinition } from "@/_ag-grid/ag-grid.component";
import { StateTable } from "@/_models";
import { Component, Input, OnInit } from "@angular/core";
import { GridOptions } from "ag-grid-community";

@Component({
    selector: 'app-state-details-table',
    templateUrl: './state-details-table.component.html'
})
export class StateDetailsTableComponent implements OnInit {
    @Input() public stateTable: StateTable;
    @Input() public isOnScreen: boolean;
    
    public readonly gridSize = GridSize.Small;
    public gridOptions = getGridOptions();
    public columnDefs: IAgColumnDefinition;
    public rowData: { [key: string]: string; }[] = [];
    
    ngOnInit(): void {
        this.columnDefs = this.getColumnDefs();
        this.stateTable.rows.map(tableRow => {
            const rowDataValue: { [key: string]: string; } = {};
            tableRow.forEach((tableRowValue, index) => {
                if (index < this.stateTable.cols.length) {
                    rowDataValue[this.stateTable.cols[index].name] = tableRowValue;
                }
            });
            this.rowData.push(rowDataValue);
        });
    }

    private getColumnDefs() {
        const columnDefs: IAgColumnDefinition = [];

        // dynamic columns
        columnDefs.push(...this.stateTable.cols
            .map(tableCol => {
                return {
                    headerName: tableCol.name, field: tableCol.name,
                };
            }) as IAgColumnDefinition
        );

        return columnDefs;
    }
}

function getGridOptions() {
    return {
        defaultColDef: {
            headerClass: 'pl-1 pr-2',
            cellClass: 'pl-1 pr-2',
            filter: false,
            sortable: false,
            resizable: true
        },
        enableBrowserTooltips: true,
        frameworkComponents: {
        },
    } as GridOptions;
}
