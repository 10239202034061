<alert *ngIf="alertVisible" class="custom-alert" [type]="type" [dismissible]="true" [dismissOnTimeout]="dismissOnTimeout" (onClosed)="alertVisible=false">
    {{message}}
    <div *ngIf="errorDetails">
        <h4>An error has occured!</h4>
        <p>Please contact Dr.Q support with the following reference: <strong>{{errorDetails.errorRef}}</strong></p>
        <div *ngIf="errorDetails.devErrorDetails">
            <div class="row">
                <label class="col-2">Message:</label>
                <label class="col-10">{{errorDetails.devErrorDetails.message}}</label>
            </div>
            <div class="row">
                <label class="col-2">Path:</label>
                <label class="col-10">{{errorDetails.devErrorDetails.path}}</label>
            </div>
            <div class="row">
                <label class="col-2">Stack trace:</label>
                <label class="col-10">{{errorDetails.devErrorDetails.stackTrace}}</label>
            </div>
        </div>
    </div>
</alert>
